
import { useTheme } from "@mui/material";
import * as React from 'react';
import {useState, useEffect} from 'react';

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { multiFilter } from "../../../data/mui_styles"

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";

import MultiSelectFilter from "./MultiSelectFilter";
import Stack from '@mui/material/Stack';
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colors from "../../../data/colors";

const colorsObj = {
  title: "Цвят",
  options: colors
};

type ColorFilterProps = {
  setColor: (type: any) => void;
  filters: any;
  isMobile: any;
};

export const DogColorSearchDropdown: React.FC<ColorFilterProps> = ({ setColor, filters, isMobile }) => {
  const theme = useTheme();

  const primaryColor = theme.palette.primary.light;
  const [values, setValues] = React.useState<string[]>([]);

  const customStyles = {
    width: !isMobile ? 250 : 300
  }

  const options = colorsObj.options.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  }); 
  const title = colorsObj.title || '';

  const newTheme = (theme: any) => createTheme({
      ...theme,
      components: !isMobile === true ? multiFilter.web : multiFilter.mobile  
  });

  useEffect(() => {
    if(filters.color == '')
      setValues([]);
  }, [filters.color]);

  return (
    <Stack sx={{ width: customStyles.width || 250 }}>
      <ThemeProvider theme={newTheme}>
        <Autocomplete
          multiple
          filterSelectedOptions
          limitTags={1}
          {...autocompleteOptions}
          id="size-small-outlined-multi"
          size="small"
          options={options}
          value={values}
          getOptionLabel={(option: any) => {
            if(filters.color == '') {
              return ''
            }
            else
              return option.title
          }}
          sx={!isMobile ? getAutocompleteStyles("#52af77") : getAutocompleteStylesMobile("#52af77")}
          onChange={(e, v) => {
            if(v.length > 0){
              let c = '';
              for(let i = 0; i < v.length; i ++) {
                c += v[i].title + ','
              }
              setColor(c);
              setValues(v);
            }
            else {
              setColor('');
            }
          }}
          renderInput={(params) => (
            <TextField {...params} 
              label={title}
              placeholder={title}
              sx={{backgroundColor: "#fff"}}
            />
          )}

          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <>
                {option.hex == "#fff" ? (
                  <span className="color-indicator" style={{ backgroundColor: option.hex, border: "1px solid #000", margin: "0"}} ></span>   
                ): (
                  <span className="color-indicator" style={{ backgroundColor: option.hex, margin: "0"}} ></span>   
                )}
              </>
                <div style={{marginLeft:"5px"}}>{option.title}</div>
            </Box>
          )} 
        />
      </ThemeProvider>
    </Stack>
  );
};
