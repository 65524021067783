import { useState, useEffect } from "react";
import ProfileHeader from "./ProfileHeader";
import ProfileSummary from "./ProfileSummary";
import DogProfile from "./DogProfile";
import { Navbar } from "../../../navbar/Navbar";
import "./userProfile.css";
import Modal from "../../modal/Modal";
import { Footer } from "../../../landing/footer/Footer";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export const UserProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  const lightColor = "rgba(255,255,255,0.7)";
  const darkColor = "rgba(0,0,0,0.8)"; 
  
  const mode = useSelector((state: any) => state.theme.mode);

  const handleModal = function() {
    setShowModal(true);
  }

  return (
    <div className="user-profile-cont">
      {/* <ProfileHeader></ProfileHeader> */}

      <div style={{ 
        backgroundImage: "linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1))",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "fixed",
        top: "0px",
        right: "0px",
        zIndex: "100"}}
      >
        <Navbar />
      </div>

      <Modal 
        showModal={ showModal } 
        setShowModal={ setShowModal } 
        pets={{}} setPets={{}} 
        content="editProfile" 
        isMobile={isMobile}
      />
            
      <div className={`${mode === "dark" ? "main-dark" : "main-light"}`}>
        <div className="content">
          <ProfileSummary handleModal={ handleModal } mode={mode}></ProfileSummary>
          <DogProfile isMobile={isMobile} mode={mode}></DogProfile>
        </div>
      </div>

      <Footer theme={mode}/>
    </div>
  );
};
