import * as React from 'react';
import { useRef, useState } from 'react';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { MdOutlineAddAPhoto } from "react-icons/md";

export default function FileUpload( { styles, image, setImage } : any) {
    const inputRef = useRef<any>(null);
    //const [image, setImage] = useState("");

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];

        setImage(file);
    }

    return (
        <div onClick={handleImageClick}>
        
            <MdOutlineAddAPhoto style={styles} className="image-upload-icon" />
            
            <input  type="file" 
                    ref={inputRef} 
                    onChange={handleImageChange} 
                    style={{ display: "none"}} >
            </input>
        </div>
    );
}