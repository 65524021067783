import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { MdEditCalendar } from "react-icons/md";


import "./moduleSummaryPopUp.css";
import { FaDog, FaHandHolding } from 'react-icons/fa';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { IoPaw } from 'react-icons/io5';
import { Button } from '@mui/material';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';

const  ModuleSummaryPopUp = ({ showInfoPopUp, setShowInfoPopUp,  module }: any) => {
    const backdrop = {
        visible: { opacity: 1},
        hidden: { opacity: 0},
    }
    
    const modal = {
        hidden: {
            y: "-100vh",
            opacity: 0
        },
        visible: {
            y: "15vh",
            opacity: 1,
            transition: {
                delay: 0.5
            }
        }
    }

    const handleClosePopUp = function() {
        setShowInfoPopUp(false);
    }

    const openModule = () => {
        const origin = window.location.origin;
        const newURL = origin + "/" + module.name;
        window.location.href = newURL;
    }

    return (
        <AnimatePresence mode='wait'>
            { showInfoPopUp && (
               <motion.div className="backdrop" style={{zIndex: "300", height: "100vh"}} variants={ backdrop } initial="hidden" animate="visible" exit="hidden">
                    <motion.div className="modal" variants={ modal }>
                        
                        <div className="module-popup-container">
                            <div className="close-icon-container">
                                <CloseOutlinedIcon 
                                    sx={{ fontSize: "2.8rem", 
                                        cursor: "pointer", 
                                        color: "rgba(0, 0, 0, 0.8)",
                                        borderRadius: "50%", 
                                        padding: "3px", 
                                        transition: "0.3s all", 
                                        zIndex: "320",
                                        "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                    }}
                                    onClick={handleClosePopUp} 
                                />
                            </div>
                            <div className="module-popup-content">
                                <div className="">
                                    <div className="image-title-container">
                                        {module.name === "adoption" ? (
                                            <div className="module-summary-popup-image-container" 
                                                style={{ 
                                                display: "flex", 
                                                flexDirection: "column", 
                                                justifyContent: "center", 
                                                alignItems: "center",
                                            }}>
                                                {/* <HouseOutlinedIcon style={{fontSize: "4rem", color: "#55c57a"}}/> */}
                                                <IoPaw style={{fontSize: "2rem", color: "rgba(40, 180, 133, 0.7)", marginBottom: "-1rem"}}/>
                                                <FaHandHolding style={{fontSize: "2.5rem", color: "rgba(40, 180, 133, 0.7)"}}/>
                                            </div>
                                        ): (
                                            <div className="module-summary-popup-image-container">
                                                <FaDog style={{fontSize:"2rem", color: "rgba(40, 180, 133, 0.7)", marginRight: "0.4rem"}}/>
                                                <FavoriteBorderOutlinedIcon 
                                                    sx={{ 
                                                        position: "block", 
                                                        // fontSize: !isMobile ? "1.7rem" : "1.5rem",
                                                        margin: "0 -0.6rem",
                                                        marginTop: "1rem",
                                                        transform: "scale(0.4)",
                                                        color: "#B90F22",
                                                        animation: "heart 1.5s ease-in-out infinite"
                                                    }}/>
                                                <FaDog style={{fontSize:"2rem", color: "rgba(40, 180, 133, 0.7)", transform: "rotateY(180deg)", marginLeft: "0.4rem"}}/>
                                            </div> 
                                        )}
                                        <p className="module-name big">{module.title}</p>
                                    </div>
                                    
                                    <p className="module-summary-text">{module.summary}</p>
                                    
                                    <div className='open-module-btn-container'>
                                        <Button
                                            className="open-module-btn"
                                            variant="contained"
                                            color="success"
                                            onClick={() => {openModule()}}
                                        >
                                            ВИЖ
                                            <LoginOutlinedIcon className="open-module-btn-icon" sx={{ fontSize: "2rem", ml: "0.5vw", mb: "0.3vw"}} />
                                        </Button>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default ModuleSummaryPopUp;
 

