import React from "react";
import { useTheme } from "@mui/material";
import { props } from "cypress/types/bluebird";
import { SingleSelectFilter } from "./SingleSelectFilter";

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";

import { autocompleteOptions } from "./autoCompleteHelper";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import { Box } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";
import { singleFilter } from "../../../data/mui_styles"

type PetTypeFilterProps = {
  setPetType: (type: string) => void;
  filters: any;
  isMobile: any;
};

export const PetTypeFilter: React.FC<PetTypeFilterProps> = ({setPetType, filters, isMobile
}) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.light;

    const petTypes = {
        title: "Животно",
        options: [
            { title: "Куче", type: "dog" },
            { title: "Котка", type: "cat" },
        ]
    };

    const options = petTypes.options;
    const title = petTypes.title;

    const newTheme = (theme: any) => createTheme({
        ...theme,
        components: !isMobile === true ? singleFilter.web : singleFilter.mobile 
    });

    return (
    <Stack sx={{ width: !isMobile ? 170 : 145 }}>
        <ThemeProvider theme={newTheme}>
            <Autocomplete
                limitTags={2}
                {...autocompleteOptions}
                id="size-small-outlined-multi"
                size="small"
                options={options}
                getOptionLabel={(option: any) => {
                    if(filters.petType == '')
                        return ''
                    else
                        return option.title
                }}
                sx={!isMobile ? getAutocompleteStyles("rgb(95, 209, 171)") : getAutocompleteStylesMobile("rgb(95, 209, 171)")}
                onChange={(e, v) => {
                    if(v){
                      if(v.type) {
                        setPetType(v.type);
                      }
                    }
                    else {
                      setPetType('');
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        value={filters.type} 
                        label={title}
                        placeholder={title}
                        sx={{backgroundColor: "#fff"}}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {params.inputProps.value !== '' ? (
                                        <>
                                          {params.inputProps.value === "Куче" ? (
                                              <InputAdornment position="start">
                                                  <PiDog style={{ fontSize: !isMobile ? "2.2rem" : "1.5rem", marginRight: !isMobile ? "0" : "-0.5rem" }}/>
                                              </InputAdornment>
                                          ): (<InputAdornment position="start">
                                                  <PiCat style={{ fontSize: !isMobile ? "2.2rem" : "1.5rem", marginRight: !isMobile ? "0" : "-0.5rem"}}/>
                                              </InputAdornment>
                                          )} 
                                        </>
                                    ): (<></>)}
                                       
                                </>
                            ),
                        }}
                    />
                )} 

                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <>
                        {option.title === "Куче" ? (
                            <PiDog style={{ color: "rgb(95, 209, 171)", fontSize: !isMobile ? "2rem" : "1.5rem" }} />
                        ) : (
                            <PiCat style={{ color: "rgb(95, 209, 171)", fontSize: !isMobile ? "2rem" : "1.5rem" }} />
                        )}
                    </>
                      <div style={{marginLeft:"5px"}}>{option.title}</div>
                  </Box>
                )} 
            />
        </ThemeProvider>
    </Stack>
    );
};
