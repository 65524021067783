import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";

import cities from "../../../data/cities";
import { useTheme } from "@mui/material";
import MultiSelectFilter from "./MultiSelectFilter";

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { multiFilter } from "../../../data/mui_styles"

type TownSearchBarProps = {
  selectedDistrictId: string | null;
  setTown: (town: string)=> void;
  filters: any;
  isMobile: any;
};

export const TownSearchDropdown: React.FC<TownSearchBarProps> = ({
  selectedDistrictId, setTown, filters, isMobile
}) => {
  const [values, setValues] = React.useState<string[]>([]);

  let citiesArray = cities.options;

  let filteredCities = selectedDistrictId != '' ? citiesArray.filter(
        (city) => selectedDistrictId?.split(',').indexOf(city.regionId) != -1
        
      ) || []: [];

  const citiesObj = {
    title: "Град",
    options: filteredCities
  }

  const options = citiesObj.options.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });
  const title = citiesObj.title || '';

  const newTheme = (theme: any) => createTheme({
      ...theme,
      components: !isMobile === true ? multiFilter.web : multiFilter.mobile    
  });

  useEffect(() => {
    if(filters.city == '')
      setValues([]);
  }, [filters.city]);
   
  const customStyles = {
    width: !isMobile ? 250 : 300
  }

  return (
    // <MultiSelectFilter filterOptions={citiesObj} styles={customStyles}/>

    <Stack sx={{ width: customStyles.width || 250 }}>
      <ThemeProvider theme={newTheme}>
          <Autocomplete
              multiple
              limitTags={1}
              {...autocompleteOptions}
              id="size-small-outlined-multi"
              size="small"
              options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
              value={values}
              getOptionLabel={(option: any) => option.title}
              sx={!isMobile ? getAutocompleteStyles("rgb(95, 209, 171)") : getAutocompleteStylesMobile("rgb(95, 209, 171)")}
              onChange={(e, v) => {
                  if(setTown) {
                    if(v.length > 0){
                      let towns = '';
                      for(let i = 0; i < v.length; i ++) {
                        towns += v[i].title + ',';
                      }
                      setTown(towns);
                      setValues(v);
                    }
                    else {
                      setTown('');
                    }
                  }
              }}
              
              renderInput={(params) => (
                  <TextField {...params} 
                      label={title}
                      placeholder={title}
                      onChange={(target) => {
                          // setValue(event.target.value);
                      }}
                      sx={{backgroundColor: "#fff"}}/>
                  )}
          />
      </ThemeProvider>
    </Stack>
  );
};
