import React, { useRef, useState } from "react";
import "./createPetProfile.css";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import { lightGreen } from "@mui/material/colors";

import BasicDatePicker from "./BasicDatePicker";
import MultiSelect from "./MultiSelect";
import ComboBox from "./ComboBox";
import FileUpload from "../common/profile/user/FileUpload";
import PetImagesModal from "./PetImagesModal";
import { SimpleStepper } from "../common/register/stepper/SimpleStepper";

import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import cat_animated from "../../images/cat_2.gif";

// ICONS
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { Autocomplete, Box, createTheme, Popper, ThemeProvider, useMediaQuery } from "@mui/material";

// DATA
import breeds from "../../data/breeds";
import colors from "../../data/colors";
import districts from "../../data/districts";
import cities from "../../data/cities";

import { autocompleteOptions, getSingleAutocompleteStyles, getTextFieldStyles } from "../humpme/filters/autoCompleteHelper";
import Toggle from "../common/profile/Toggle";
import Sortable from "../common/profile/user/Sortable";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

import { closestCorners, DndContext } from "@dnd-kit/core";
import { horizontalListSortingStrategy, SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import InfoPopUp from "../common/home/InfoPopUp";
import ConfirmPopUp from "../common/profile/user/ConfirmPopUp";
import { useSelector } from "react-redux";

export const CreatePetProfile = ({ showModal, setShowModal, pets, setPets, selectedPet }: any) => {
  const inputRef = useRef<any>(null);

  const mode = useSelector((state: any) => state.theme.mode);
  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  const [showImagesModal, setShowImagesModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [toggleAction, setToggleAction] = useState('');

  const [selectedDistrict, setSelectedDistrict] = useState(selectedPet != undefined ? selectedPet.region.districtId : '');
  const [profileImage, setProfileImage] = useState(selectedPet != undefined ? selectedPet.image : "")
  const [imageSlots, setImageSlots] = useState([
    {id: 1},
    {id: 2},
    {id: 3},
    {id: 4},
    {id: 5},
    {id: 6},
    {id: 7},
    {id: 8},
  ]);

  const [images, setImages] = useState([
    {
        id: 1,
        source: selectedPet != undefined ? require('../../assets/' + selectedPet.image) : ""
    },
    {
        id: 2,
        source: ''
    },
    {
        id: 3,
        source: ''
    },
    {
        id: 4,
        source: ''
    },
    {
        id: 5,
        source: ''
    },
    {
        id: 6,
        source: ''
    },
    {
      id: 7,
      source: ''
    },
    {
      id: 8,
      source: ''
  }])

  const steps = [
      'Лични данни',
      'Лични данни 2',
      'Снимки'
  ];

  const handleActiveStep = (direction: string) => {
      if(direction == "forward") {
          if(activeStep == 2) return;

          const newStep = activeStep + 1;
          setActiveStep(newStep);
      }
      else if (direction == "back") {
          if(activeStep == 0) return;

          const newStep = activeStep - 1;
          setActiveStep(newStep);
      }
  };

  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const [module, setModule] = useState({label: "", name: ""});

  const handleInfoPopUp = function(module: any) {
    setModule(module);
    setShowInfoPopUp(true);
  }

  const [formData, setFormData] = useState({
    dogName: selectedPet !== undefined ? selectedPet.name : "",
    dogBreed: selectedPet !== undefined ? selectedPet.breed : { title: "", type: "" },
    petType: selectedPet !== undefined ? selectedPet.type : "cat",
    dogWeight: selectedPet !== undefined ? selectedPet.weight : "",
    dogHeight: selectedPet !== undefined ? selectedPet.height : "",
    city: selectedPet !== undefined ? selectedPet.city : {firstLetter: '', cityId: '', title: '', regionId: ''},
    region:selectedPet !== undefined ? selectedPet.region : {title: "", districId: "", firstLetter: ""},
    dogSex: selectedPet !== undefined ? selectedPet.sex : "",
    dogAge: "",
    dogColor: selectedPet !== undefined ? selectedPet.color : [],
    dogBirthDate: "",
    profileImage: selectedPet !== undefined ? require('../../assets/' + selectedPet.image) : "",
    gallery: selectedPet !== undefined ? selectedPet.galerry: [],
    documentImage: selectedPet !== undefined ? selectedPet.document_image : "",
    vaccinated: selectedPet !== undefined ? selectedPet.vaccinated : false,
    originalBreed: selectedPet !== undefined ? selectedPet.original_breed : false,
    chip: selectedPet !== undefined ? selectedPet.chip : false,
    castrated: selectedPet !== undefined ? selectedPet.castrated : false,
    partnerFinder: selectedPet !== undefined ? selectedPet.partner_finder : false,
    adoption: selectedPet !== undefined ? selectedPet.adoption : false
  });

  const [profileType, setProfileType] = useState("cat");

  const [vaccinated, setVaccinated] = useState(false);

  const [dogImage, setDogImage] = useState("");
  const [documentImage, setDocumentImage] = useState("");

  const Save = () => {

    const newPet = {
      name: formData.dogName,
      breed: formData.dogBreed,
      region: formData.region,
      city: formData.city,
      sex: formData.dogSex,
      color: formData.dogColor,
      weight: formData.dogWeight,
      height: formData.dogHeight,
      birthday: "",
      vaccinated: formData.vaccinated,
      original_breed: formData.originalBreed,
      chip: formData.chip,
      castrated: formData.castrated,
      partner_finder: formData.partnerFinder,
      adoption: formData.adoption,
      image: profileImage.name,
      gallery: '',
      document_image: documentImage
    }
    
    if(selectedPet === undefined){
      setPets([...pets, newPet]);
    }
    else {
      const nextPets = pets.map((pet: any) => {
        if (pet.uuid !== selectedPet.uuid) {
          // No change
          return pet;
        } else {
          return newPet;
        }
      });
      // Re-render with the new array
      setPets(nextPets);
    }
    setShowModal(false);
  };

  const containsOnlyNumbers = (str: string) => {
    if(str == '.' || str == ',')
      return true;
    else {
      return /^\d+$/.test(str);
    }
  };

  const containsOnlyLetters = (str: string) => {
    return /^[a-zA-Z-]+$/.test(str);
  };


  const onKeyDownWeightHandler = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (formData.dogWeight == '' && (e.key == "0" || e.key == "." || e.key == ",")) {
      e.preventDefault();
    } 
    
    if (!containsOnlyNumbers(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const onKeyDownHeightHandler = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (formData.dogHeight == '' && e.key == "0") {
      e.preventDefault();
    } 
    
    if (!containsOnlyNumbers(e.key) && e.key !== "Backspace" ) {
      e.preventDefault();
    }
  };

  const onKeyDownHandlerLetters = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    /* if (!containsOnlyLetters(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    } */
  };

  const breed_upload_styles = {
    fontSize: "5.4vw", 
    color: mode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0,0,0,0.2)", 
    cursor: "pointer",
    "&:hover": { color: "#65C466" }
  }

  const checkFormFields = () => {
    return formData.dogBreed === '' || formData.dogHeight === '' || formData.dogWeight === '' || formData.dogSex === '' || formData.dogName === '' || formData.dogColor == '' || dogImage === '' || documentImage == '';
  }

  const handleTypeChange = () => {

    if(formData.petType == 'dog') {
      setFormData((data) => ({
        ...data,
        ["petType"]: "cat",
      }));
    }
    else if (formData.petType == 'cat') {
      setFormData((data) => ({
        ...data,
        ["petType"]: "dog",
      }));
    }
  }

  const getSlotPosition = (id: number) => {
    return imageSlots.findIndex(slot => slot.id === id);
  }

const handleDragEnd = (event: any) => {
  
    const {active, over} = event;
    if(active.id == 1 || over.id == 1) return;
    if (active.id === over.id) return;

    setImageSlots(imageSlots => {
        const originalPos = getSlotPosition(active.id);
        const newPos = getSlotPosition(over.id);

        return arrayMove(imageSlots, originalPos, newPos);
    })
}

const handleImageClick = () => {
  if(inputRef.current !== null) {
      inputRef.current.click();
  }
}

const handleDocumentChange = (event: any) => {
  const file = event.target.files[0];

  setDocumentImage(file);
}

  // CUSTOM THEMES
  const textFieldTheme = createTheme({
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: 'red',
            margin: '5px auto'
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#333333" : "#fff",
            borderRadius: "4px",

          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: isMobile ? "1.2rem" : "1.3vw",
            backgroundColor: mode === "dark" ? "#333333" : "#fff",
            "&.Mui-focused": {
              "root": {
                "borderColor": "#28b485 !important"
              }
            }
          },
          input: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: isMobile ? "1.2rem" : "1.3vw",
            backgroundColor: mode === "dark" ? "#333333" : "#fff",
            color:mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
          },
          notchedOutline : {
            borderColor: mode === "dark" ? "rgba(255, 255, 255, 0.33)" : "rgba(0, 0, 0, 0.23)",
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Adys Regular, sans-serif",
            color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)",
            fontSize: isMobile ? "3rem" : "1.3vw",
          },
          shrink: {
            fontFamily: "Adys Bold, sans-serif",
            fontSize: isMobile ? "3rem" : "1.3vw",
            color: "#28b485"
          }
        }
      }
    }
  })

  const multiSelectTheme = (theme: any) => createTheme({
    ...theme,
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#333333" : "#fff",
            borderRadius: "4px"
          }
        }
      },
      MuiAutocomplete: {
          styleOverrides: {
              root: {
                  borderRadius: "4px",
                  backgroundColor: mode === "dark" ? "#333333" : "#fff",
              },
              tag: {
                  fontSize: "1vw"
              }, 
              option: {
                  fontFamily: "Adys Regular",
                  fontWeight: "400",
                  color:mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
              },
              inputRoot: {
                backgroundColor: mode === "dark" ? "#333333" : "#fff",
              },
              inputFocused: {
                  borderColor: "rgb(95, 209, 171)"
              },
              input: {
                  fontFamily: "Adys Regular",
                  fontWeight: "400",
                  fontSize: isMobile ? "1.6rem" : "1.3vw",
                  color:mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                  backgroundColor: mode === "dark" ? "#333333" : "#fff",
                  borderColor: mode === "dark" ? "rgba(255, 255, 255, 0.7) !important" : "#28b485 !important",
              },
              listbox: {
                backgroundColor: mode === "dark" ? "#333333" : "#fff",
              },
              paper: {
                backgroundColor: mode === "dark" ? "#333333" : "#fff",
              },
              popper: {
                backgroundColor: mode === "dark" ? "#333333" : "#fff",
              }
          }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: isMobile ? "1.6rem" : "1.3vw",
            backgroundColor: mode === "dark" ? "#4C4C4C" : "#fff",
            borderColor: mode === "dark" ? "rgba(255, 255, 255, 0.7) !important" : "#28b485 !important",
          },
          input: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: isMobile ? "1.6rem" : "1.3vw",
            backgroundColor: mode === "dark" ? "#4C4C4C" : "#fff",
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: isMobile ? "1.6rem" : "1.3vw",
            color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.6)",
          },
          shrink: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: isMobile ? "1.6rem" : "1.3vw",
          }
        }
      }
    }   
  });

  //------------------------------------------------------

  const CustomPopper = (props: any) => {
    const modifiers = [
      {
        name: 'flip',
        options: {
          fallbackPlacements: []
        },
      },
    ]

    return (
      <Popper
        {...props}
        modifiers={modifiers}
        popperOptions={{
          placement: 'bottom',
        }}
      />
    )
  }

   // OPTIONS
   let filteredBreeds = formData.petType != '' ? breeds.filter(
    (breed) => breed.type == formData.petType
    
  ) || []: [];

  const breedOptions = filteredBreeds.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const colorOptions = colors.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const sexOptions = [
    { title: "Мъжки"},
    { title: "Женски"},
  ];

  const districtOptions = districts.options.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  let citiesArray = cities.options;

  let filteredCities = selectedDistrict != '' ? citiesArray.filter(
        (city) => selectedDistrict == city.regionId
        
      ) || []: [];

  const cityObj = filteredCities.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const checkFloating = (event: React.ChangeEvent<HTMLInputElement>) =>  {
    var regex = /^[+-]?([0-9]{1,})[.,]([0-9]{1,})$/;

    return regex.test(event.target.value);
  }

  //----------------------------------------------------------------------------

  return (
    <>
        <div className="pet-info-section-container">
            
            {/* MODALS */}
            <PetImagesModal showModal={showImagesModal} setShowModal={setShowImagesModal}></PetImagesModal>
            <ConfirmPopUp showInfoPopUp={showInfoPopUp} setShowInfoPopUp={setShowInfoPopUp} module={{label: module.label, name: module.name }} action={toggleAction} setChecked={setFormData}/>

            <div 
              className={`form-card ${mode === "dark" ? "background_dark" : "background_light"}`} 
              style={{ 
                width: isMobile ? "90%" : "50%", 
                maxWidth: isMobile ? "90%" : "72vw"  
              }}
            >
                <div 
                  className="close-profile-card" 
                  style={{ 
                    width: "fix-content !important",
                    right: isMobile ? "5%" : "25%"
                  }}
                >
                  <CloseOutlinedIcon 
                    sx={{ 
                      fontSize: isMobile ? "2.4rem" : "2.2vw", 
                      cursor: "pointer", 
                      borderRadius: "50%", 
                      padding: "3px", 
                      transition: "0.3s all", 
                      "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                    }}
                    onClick={() => {setShowModal(false)}} 
                  />
                </div>
                    
                <div className="form-card-title">
                  {(selectedPet === '' || selectedPet === undefined || selectedPet === null) ? (
                    <p style={{ 
                      color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                      fontSize: isMobile ? "1.3rem" : "1.5vw"
                  }}>
                    Създайте профил на своя любимец
                  </p>
                  ): (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1vw" }}>
                      <ModeEditOutlinedIcon style={{ fontSize: isMobile ? "1.3rem" : "2.2vw", marginBottom: "0.5vw", color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)" }}/>
                      <p 
                        style={{ 
                          color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                          fontSize: isMobile ? "1.3rem" : "1.5vw"
                        }} 
                      >
                        Редактирайте профила на своя любимец
                      </p>
                    </div>
                  )} 
                    
                </div>

                {/* STEPPER */}
                <div style={{ marginTop: isMobile ? "-1rem" : "6rem", marginBottom: "1rem" }}>
                    <SimpleStepper steps={steps} activeStep={activeStep}></SimpleStepper>
                </div>

                {activeStep == 0 && (
                  <section>
                    {/* PROFILE TYPE SELECTOR */}
                    <div style={{ margin: isMobile ? "-2rem 0" : "-6rem 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                      {formData.petType === "dog" ? (
                            <PiDog
                              className="profile-type-icon" 
                              style={{ 
                                color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                                fontSize: isMobile ? "4rem" : "6rem"
                              }} 
                            />
                        ): (
                            // <img src={cat_animated} style={{ width: "28vw", height: "28vw", transform: "scale(0.8)", position: "fixed", top: "-2vw", right: "35.7%", marginBottom: "2vw"}}/>
                            <PiCat 
                              className="profile-type-icon" 
                              style={{ 
                                color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                                fontSize: isMobile ? "4rem" : "6rem"
                              }}
                            />
                        )}

                        <div className="arrows-container" style={{marginBottom: isMobile ? "-3rem" : "0"}}>
                          <ArrowCircleLeftOutlinedIcon 
                            onClick={ handleTypeChange } 
                            sx={{ 
                              fontSize: isMobile ? "1.9rem" : '2vw', 
                              cursor: 'pointer', 
                              zIndex: "10", 
                              color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                              "&:hover": { color: "rgba(40, 180, 133, 1)" }
                            }} 
                          />

                          <p 
                            className="arrow_text" 
                            style={{ 
                              color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)",
                              fontSize: isMobile ? "1.5rem" : "1.3vw" 
                            }}
                          >
                            { formData.petType =="dog" ? "Кучешки": "Котешки" }
                          </p>
                          
                          <ArrowCircleRightOutlinedIcon 
                            onClick={ handleTypeChange } 
                            sx={{ 
                              fontSize: isMobile ? "1.9rem" : '2vw',
                              cursor: 'pointer', 
                              zIndex: "10",
                              color: mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.8)", 
                              "&:hover": { color: "rgba(40, 180, 133, 1)" }
                            }} 
                          />
                        </div>
                    </div>

                    <form onSubmit={ () => {}}>
                      {/* PICTURE -- PROFILE TYPE -- NAME -- BREED -- COLOR */}
                        <div className="dog-main-info_cont">
                          <div className="name-breed_container">
                            {/* NAME */}
                            <ThemeProvider theme={textFieldTheme}>
                              <TextField
                                id="username"
                                label="Име"
                                name="dogName"
                                value={formData.dogName}
                                variant="outlined"
                                color="success"
                                sx={getTextFieldStyles("#28b485")}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setFormData((data) => ({
                                    ...data,
                                    [event.target.name]: event.target.value,
                                    }));
                                }}
                                onKeyDown={onKeyDownHandlerLetters}
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="end">
                                        {formData.dogName.length >= 3 && (
                                        <CheckCircleIcon color="success" sx={{ color: "#28b485", fontSize: "2rem" }}/>
                                        )}
                                    </InputAdornment>
                                    ),
                                }}
                              />
                            </ThemeProvider>
                              
                            {/* BREED & COLOR  */}
                            <div className="breed-color_cont">
                              {/* BREED */}
                              <ThemeProvider theme={multiSelectTheme}>
                                <Autocomplete
                                    limitTags={1}
                                    {...autocompleteOptions}
                                    id="size-medium-outlined-multi"
                                    size="medium"
                                    value={formData.dogBreed}
                                    options={breedOptions.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    defaultValue={formData.dogBreed}
                                    getOptionLabel={(option: any) => {
                                      if(formData.dogBreed == undefined)
                                        return ''
                                      else
                                        return option.title
                                    }}
                                    onChange={(e, v) => {
                                      console.log(v)
                                      if(v){
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogBreed"]: v,
                                        })); 
                                      }
                                      else {
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogBreed"]: {title: "", type: ""},
                                        }));
                                      }
                                    }}
                                    // sx={getSingleAutocompleteStyles("rgba(40, 180, 133, 0.7)")}
                                    sx={getSingleAutocompleteStyles("#28b485")}
                                    
                                    renderInput={(params) => (
                                        <TextField {...params} 
                                            label={"Порода"}
                                            placeholder={"Порода"}
                                            value={formData.dogBreed}
                                            onChange={(target) => {
                                                // setValue(event.target.value);
                                            }}
                                            sx={{backgroundColor: "#fff"}}/>
                                        )}
                                        PopperComponent={(props) => <CustomPopper {...props} />}
                                  />
                              </ThemeProvider>

                              {/* COLOR */}
                              <ThemeProvider theme={multiSelectTheme}>
                                  <Autocomplete
                                    multiple
                                    limitTags={2}
                                    {...autocompleteOptions}
                                    id="size-small-outlined-multi"
                                    size="medium"
                                    color="success"
                                    options={colorOptions}
                                    getOptionLabel={(option: any) => {
                                      if(formData.dogColor == undefined)
                                        return '';
                                      else
                                        return option.title;
                                    }}
                                    value={formData.dogColor}
                                    defaultValue={formData.dogColor}
                                    onChange={(e, v) => {
                                      if(v){
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogColor"]: v,
                                        })); 
                                      }
                                      else {
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogColor"]: [{firstLetter: '', title: '', hex: ''}],
                                        }));
                                      }
                                    }}
                                    sx={getSingleAutocompleteStyles("#28b485")}
                                  
                                    renderInput={(params) => (
                                      <TextField {...params} 
                                        label={"Цвят"}
                                        placeholder={"Цвят"}
                                        sx={{backgroundColor: "#fff"}}
                                        value={formData.dogColor}
                                        onChange={(target) => {
                                            // setValue(event.target.value);
                                        }}
                                      />
                                    )}

                                    renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                        <>
                                          {option.hex == "#fff" ? (
                                            <span className="color-indicator" style={{ backgroundColor: option.hex, border: "1px solid #000", margin: "0"}} ></span>   
                                          ): (
                                            <span className="color-indicator" style={{ backgroundColor: option.hex, margin: "0"}} ></span>   
                                          )}
                                        </>
                                          <div style={{marginLeft:"5px"}}>{option.title}</div>
                                      </Box>
                                    )} 
                                  />
                              </ThemeProvider>
                            </div>  
                          </div>
                        </div>

                        {/* WEIGHT && HEIGHT */}
                        <div className="dog-details_cont">
                            <FormControl  sx={{ width: "50%"}}>
                              <ThemeProvider theme={textFieldTheme}>
                                  <TextField
                                    error={formData.dogWeight.length >= 5 ? true : false}
                                    className="weightInput"
                                    type="text"
                                    color="success"
                                    name="dogWeight"
                                    variant="outlined"
                                    label="Килограми"
                                    value={formData.dogWeight}
                                    sx={getTextFieldStyles("#28b485")}
                              
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {(formData.dogWeight.length <= 4) &&
                                            Number(formData.dogWeight) !== 0 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#28b485", fontSize: "2rem" }}/>
                                            )}
                                        </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                        }));
                                    }}
                                    onKeyDown={onKeyDownWeightHandler}
                                  />
                              </ThemeProvider>
                            </FormControl>

                            <FormControl  sx={{ width: "50%"}}>
                              <ThemeProvider theme={textFieldTheme}>
                                  <TextField
                                    error={formData.dogHeight.length >= 4 ? true : false}
                                    className="weightInput"
                                    type="text"
                                    color="success"
                                    name="dogHeight"
                                    variant="outlined"
                                    label="Височина"
                                    value={formData.dogHeight}
                                    sx={getTextFieldStyles("#28b485")}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {formData.dogHeight.length <= 3 &&
                                            Number(formData.dogHeight) !== 0 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#28b485", fontSize: "2rem" }}/>
                                            )}
                                        </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                        }));
                                    }}
                                    onKeyDown={onKeyDownHeightHandler}
                                  />
                              </ThemeProvider>
                            </FormControl>
                        </div>

                        {/* SEX && BIRTHDAY*/}
                        <div className="dog-details_cont">
                          <ThemeProvider theme={multiSelectTheme}>
                              <Autocomplete
                                  {...autocompleteOptions}
                                  id="size-medium-outlined-multi"
                                  size="medium"
                                  color="success"
                                  options={sexOptions}
                                  value={{
                                    title: formData.dogSex
                                  }}
                                  defaultValue={formData.dogSex}
                                    getOptionLabel={(option: any) => {
                                        return option.title
                                    }}
                                    onChange={(e, v) => {
                                      if(v){
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogSex"]: v.title,
                                        })); 
                                      }
                                      else {
                                        setFormData((data) => ({
                                          ...data,
                                          ["dogSex"]: "",
                                        }));
                                      }
                                    }}
                                  sx={getSingleAutocompleteStyles("#28b485")}
                                  renderInput={(params) => (
                                    <TextField {...params} 
                                      label={"Пол"}
                                      placeholder={"Пол"} 
                                      sx={{backgroundColor: "#fff"}}
                                      value={formData.dogSex}
                                      onChange={(target) => {
                                          // setValue(event.target.value);
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                          <>
                                            {params.inputProps.value !== '' ? (
                                              <>
                                                {(formData.dogSex === "Мъжки" ||params.inputProps.value === "Мъжки") ? (
                                                    <InputAdornment position="start">
                                                        <MaleIcon sx={{ fontSize: "2.2rem" }}/>
                                                    </InputAdornment>
                                                ): (<InputAdornment position="start">
                                                        <FemaleIcon sx={{ fontSize: "2.2rem" }}/>
                                                    </InputAdornment>
                                                )}
                                              </>
                                            ): (<></>)}
                                          </>
                                        ),
                                      }}
                                    />
                                  )} 
                  
                                  renderOption={(props, option) => (
                                      <Box component="li" {...props}>
                                          {option.title === "Мъжки" ? (
                                              <MaleIcon sx={{ color: "#52af77", fontSize: "2rem" }} />
                                          ) : (
                                              <FemaleIcon sx={{ color: "#52af77", fontSize: "2rem" }} />
                                          )}
                                          <div style={{marginLeft:"5px"}}>{option.title}</div>
                                      </Box>
                                  )} 
                              />
                          </ThemeProvider>

                          <BasicDatePicker ></BasicDatePicker>
                        </div>
                    </form>
                  </section>
                )}

                {activeStep == 1 && (
                  <section style={{ marginBottom: "2vw" }}>

                    {/* DISTRICT -- CITY */}  
                    <div className="city-region-cont">

                      {/* DISTRICT */}
                      <ThemeProvider theme={multiSelectTheme}>
                        <Autocomplete
                          limitTags={1}
                          {...autocompleteOptions}
                          id="size-medium-outlined-multi"
                          size="medium"
                          value={formData.region}
                          options={districtOptions.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                          sx={getSingleAutocompleteStyles("#28b485")}
                          getOptionLabel={(option: any) => {
                            if(formData.region == undefined)
                              return ''
                            else
                              return option.title
                          }}
                          onChange={(e, v) => {
                            console.log(v)
                            if(v){
                              setSelectedDistrict(v.districtId)
                              setFormData((data) => ({
                                ...data,
                                ["region"]: v,
                              })); 
                            }
                            else {
                              setSelectedDistrict('')
                              setFormData((data) => ({
                                ...data,
                                ["region"]: {title: "", districtId: "", firstLetter: ""},
                              }));
                            }
                          }}
                          
                          renderInput={(params) => (
                            <TextField {...params} 
                              label={"Област"}
                              placeholder={"Област"}
                              sx={{backgroundColor: "#fff"}}
                            />
                          )}
                        />
                      </ThemeProvider>

                      {/* CITY */}
                      <ThemeProvider theme={multiSelectTheme}>
                          <Autocomplete
                              limitTags={1}
                              {...autocompleteOptions}
                              id="size-medium-outlined-multi"
                              size="medium"
                              value={formData.city}
                              options={cityObj.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                              getOptionLabel={(option: any) => {
                                if(formData.city == undefined)
                                  return ''
                                else
                                  return option.title
                              }}
                              sx={getSingleAutocompleteStyles("#28b485")} 
                              
                              onChange={(e, v) => {
                                if(v){
                                  setFormData((data) => ({
                                    ...data,
                                    ["city"]: v,
                                  })); 
                                }
                                else {
                                  setFormData((data) => ({
                                    ...data,
                                    ["city"]: {firstLetter: '', cityId: '', title: '', regionId: ''},
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} 
                                    label={"Град"}
                                    placeholder={"Град"}
                                    onChange={(target) => {
                                        // setValue(event.target.value);
                                    }}
                                    sx={{backgroundColor: "#fff"}}/>
                                )}
                          />
                      </ThemeProvider>
                    </div>

                    {/* DOCUMENT -- CHECKBOXES */}
                    <div className="pet-breed-checkboxes_cont">
                        {(selectedPet != undefined && selectedPet.document_image) ? (
                            <div onClick={handleImageClick} style={{zIndex: "100", position: "relative", display: "flex", width: "100%", height: "100%"}}>
                                <img  style={{ width: "100%", height: "15vw", borderRadius: "25px"}} 
                                  src={selectedPet != undefined ? require('../../assets/' + selectedPet.document_image) : ""}>
                                </img>

                                <div className="uploader">
                                  <input type="file" ref={inputRef}  onChange={handleDocumentChange} className="edit-image" style={{height: "35%"}} />
                                  
                                  <div style={{position:"absolute", bottom: "0.7vw", left: "44%", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-1vw", marginLeft: "-0.4vw"}}>
                                    <CameraAltOutlinedIcon sx={{fontSize: "2.5vw", color: "rgba(255,255,255, 0.7)"}}></CameraAltOutlinedIcon>
                                    <p style={{ fontFamily: "Adys Regular", color: "rgba(255,255,255, 0.7)", fontSize: "1.8vw" }}>Смени</p>
                                  </div>

                              </div>
                            </div>
                            ): (
                            <div className="pet-originalBreed_cont" 
                              style={{ 
                                backgroundColor: mode === "dark" ? "#333333" : "#fff",
                                boxShadow: `0 0 5px inset ${mode === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0,0,0,0.5)"}` }}>
                                <p style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.7)" }}>Добави снимка на документ за родословие</p>
                                <div>
                                  <FileUpload styles={ breed_upload_styles } src={selectedPet != undefined ? require('../../assets/' + selectedPet.document_image) : ""} setImage={ setDocumentImage }></FileUpload>
                                </div>
                            </div>   
                        )} 
                    </div>

                    {/* CHECKBOXES */}
                    <div className="checkboxes-container">
                      <div className="pet-checkboxes">
                          
                        {/* VACCINATED */}
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <div>
                              <Checkbox
                                  value={vaccinated}
                                  name="isVaccinated"
                                  onChange={(e) => {
                                    var newValue = !formData.vaccinated;
                                    setFormData((data) => ({
                                      ...data,
                                      ["vaccinated"]: newValue,
                                      }));
                                  }}
                                  checked={formData.vaccinated}
                                  sx={{
                                      color: mode === "dark" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0,0.6)",
                                      transform: "scale(1.5)",
                                      "&:hover": {
                                      color: '#28b485'
                                      },
                                      "&.Mui-checked": {
                                      color: '#28b485',
                                      },
                                  }}
                              />
                              <span 
                                style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}
                                className="vaccinated">
                                  Напълно ваксинирано
                              </span>
                          </div>
                        </FormControl>

                        {/* ORIGINAL BREED */}
                        <FormControl fullWidth>
                          <div>
                              <Checkbox
                                  value={formData.originalBreed}
                                  name="isOriginalBreed"
                                  onChange={(e) => {
                                    var newValue = !formData.originalBreed;
                                    setFormData((data) => ({
                                      ...data,
                                      ["originalBreed"]: newValue,
                                      }));
                                  }}
                                  checked={formData.originalBreed}
                                  sx={{
                                      color: mode === "dark" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0,0.6)",
                                      transform: "scale(1.5)",
                                      "&:hover": {
                                      color: '#28b485'
                                      },
                                      "&.Mui-checked": {
                                      color: '#28b485',
                                      },
                                  }}
                              />
                              <span 
                                style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}
                                className="originalBreed">
                                  Потвърдено родословие
                              </span>
                          </div>
                        </FormControl>  
                              
                      </div> 

                      <div className="pet-checkboxes">
                          
                        {/* CHIP */}
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <div>
                              <Checkbox
                                  value={formData.chip}
                                  name="isChipped"
                                  onChange={(e) => {
                                    var newValue = !formData.chip;
                                    setFormData((data) => ({
                                      ...data,
                                      ["chip"]: newValue,
                                      }));
                                  }}
                                  checked={formData.chip}
                                  sx={{
                                      color: mode === "dark" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0,0.6)",
                                      transform: "scale(1.5)",
                                      "&:hover": {
                                        color: '#28b485'
                                      },
                                      "&.Mui-checked": {
                                        color: '#28b485',
                                      },
                                  }}
                              />
                              <span 
                                style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}} 
                                className="vaccinated">
                                  Чипирано
                              </span>
                          </div>
                        </FormControl>

                        {/* CASTRATED */}
                        <FormControl fullWidth>
                          <div>
                              <Checkbox
                                  value={formData.castrated}
                                  name="isCastrated"
                                  onChange={(e) => {
                                    var newValue = !formData.castrated;
                                    setFormData((data) => ({
                                      ...data,
                                      ["castrated"]: newValue,
                                      }));
                                  }}
                                  checked={formData.castrated}
                                  sx={{
                                      color: mode === "dark" ? "rgba(255, 255, 255, 0.4)" : "rgba(0,0,0,0.6)", 
                                      transform: "scale(1.5)",
                                      "&:hover": {
                                        color: '#28b485'
                                      },
                                      "&.Mui-checked": {
                                        color: '#28b485',
                                      },
                                  }}
                              />
                              <span 
                                style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}} 
                                className="originalBreed">
                                  Кастрирано
                              </span>
                          </div>
                        </FormControl>  
                              
                      </div> 

                      <div className="pet-checkboxes" style={{ marginTop: "0.8vw" }}>
                          
                        {/* PARTNER MODULE */}
                        <Toggle 
                          label={"Намери партньор"} 
                          checked={formData.partnerFinder} 
                          setChecked={setFormData}
                          name={"partnerFinder"}
                          handleInfoPopUp={handleInfoPopUp}
                          setToggleAction={setToggleAction}
                        />
    
                        {/* ADOPTION MODULE */}
                        <Toggle 
                          label={"Осинови приятел"} 
                          checked={formData.adoption} 
                          setChecked={setFormData}
                          name={"adoption"}
                          handleInfoPopUp={handleInfoPopUp}
                          setToggleAction={setToggleAction}
                        />
                      </div> 
                    </div>
                     
                  </section>
                )}

                {activeStep == 2 && (
                  <div className="gallery_container">
                    <p 
                      className="gallery-title" 
                      style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.7)" }}>
                        Качете до 8 снимки на Вашият домашен любимец
                    </p>

                    <div className="profile-img-title-cont">
                      <p className="profile-img-title">Профилна</p>
                    </div>

                    <div className="images_container" >
                      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                          {imageSlots.map( slot => {
                            return  <SortableContext items={imageSlots} strategy={horizontalListSortingStrategy}>
                                      <Sortable 
                                        id={slot.id} 
                                        images={ images } 
                                        setImages={ setImages } 
                                        profileImage={profileImage} 
                                        setProfileImage={setProfileImage}
                                        theme={mode}
                                      />
                                    </SortableContext>     
                          })}
                      </DndContext>
                    </div>
                  </div>
                )}

                {/* FORM BUTTONS */}      
                <div className="card-btns-container" style={{ textAlign: "left", marginTop: "1vw", marginLeft: "0rem"}}>
                  <Button
                      sx={{ fontFamily: "Adys Bold", transition: "all 0.5s", width: "22%", padding: "0.8rem 0.5rem", fontSize: "1.1vw", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 1)", transform: "scale(1.03)"} }}
                      variant="contained"
                      color="success"
                      className="card-btn"
                      disabled={activeStep === 0}
                      onClick={() => {
                        handleActiveStep("back");
                      }}
                  >
                      <WestIcon className="card-btn-icon" sx={{ fontSize: "1.8vw", mr: "0.5vw", mb: "0.3vw"}} />
                      НАЗАД
                  </Button>

                  {activeStep < 2 && (
                      <Button
                          sx={{ fontFamily: "Adys Bold", transition: "all 0.5s", width: "22%", ml: "1vw", padding: "0.8rem 0.5rem", fontSize: "1.1vw", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 1)", transform: "scale(1.03)"} }}
                          variant="contained"
                          color="success"
                          className="card-btn"
                          onClick={ () => { 
                              handleActiveStep("forward"); 
                          }}
                      >
                          НАПРЕД
                          <EastIcon className="card-btn-icon" sx={{ fontSize: "1.8vw", ml: "0.5vw", mb: "0.3vw"}} />
                      </Button>
                  )}

                  {activeStep == 2 && (
                    <Button
                      sx={{ fontFamily: "Adys Bold", width: "22%", padding: "0.8rem 0.5rem", fontSize: "1.1vw", ml: "1vw", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 1)", transform: "scale(1.03)"}}}
                      variant="contained"
                      color="success"
                      type="submit"
                      className="card-btn"
                      onClick={()=> { Save()}}
                    >
                      Запази
                      <SaveOutlinedIcon className="card-btn-icon" sx={{ fontSize: "1.7vw", ml: "0.5vw", mb: "0.3vw"}}></SaveOutlinedIcon>
                    </Button>
                  )}            
                </div>
            </div>
        </div>
    </>
  );
};
