const breeds = [
    {
        title: "Манск",
        type: "cat"
    },
    {
        title: "Манчкин",
        type: "cat"
    },
    {
        title: "Бомбайска котка",
        type: "cat"
    },
    {
        title: "Малайска котка",
        type: "cat"
    },
    {
        title: "Оцикет",
        type: "cat"
    },
    {
        title: "Ориенталска котка",
        type: "cat"
    },
    {
        title: "Руска синя котка",
        type: "cat"
    },
    {
        title: "Сиамска котка",
        type: "cat"
    },
    {
        title: "Сфинкс",
        type: "cat"
    },
    {
        title: "Британска късокосместа котка",
        type: "cat"
    },
    {
        title: "Бенгалска котка",
        type: "cat"
    },
    {
        title: "Корат",
        type: "cat"
    },
    {
        title: "Бурманска котка",
        type: "cat"
    },
    {
        title: "Хавана",
        type: "cat"
    },
    {
        title: "Пикси боб",
        type: "cat"
    },
    {
        title: "Лабрадор",
        type: "dog"
    },
    {
        title: "Голдън ретривър",
        type: "dog"
    },
    {
        title: "Немска овчарка",
        type: "dog"
    },
    {
        title: "Акита Ину",
        type: "dog"
    },
    {
        title: "Шиба Ину",
        type: "dog"
    },
    {
        title: "Мопс",
        type: "dog"
    },
    {
        title: "Алабай",
        type: "dog"
    },
    {
        title: "Чихуахуа",
        type: "dog"
    },
    {
        title: "Йоркшерски териер",
        type: "dog"
    },
    {
        title: "Пинчър",
        type: "dog"
    },
    {
        title: "Пудел",
        type: "dog"
    },
    {
        title: "Хъски",
        type: "dog"
    },
    {
        title: "Санбернар",
        type: "dog"
    },
    {
        title: "Бигъл",
        type: "dog"
    },
    {
        title: "Ротвайлер",
        type: "dog"
    },
    {
        title: "Английски булдог",
        type: "dog"
    },
    {
        title: "Френски булдог",
        type: "dog"
    },
    {
        title: "Боксер",
        type: "dog"
    },
    {
        title: "Померан",
        type: "dog"
    },
    {
        title: "Бултериер",
        type: "dog"
    },
    {
        title: "Доберман",
        type: "dog"
    },
    {
        title: "Самоед",
        type: "dog"
    },
    {
        title: "Корги",
        type: "dog"
    },
    {
        title: "Бордър Коли",
        type: "dog"
    },
    {
        title: "Австралийска овчарка",
        type: "dog"
    },
    {
        title: "Френска болонка",
        type: "dog"
    },
    {
        title: "Булмастиф",
        type: "dog"
    },
];

export default breeds;