const cities = {
    "title": "Град",
    "options": [
    {
        "cityId": "1",
        "title": "Айтос",
        "regionId": "1"
    },
    {
        "cityId": "2",
        "title": "Аксаково",
        "regionId": "2"
    },
    {
        "cityId": "3",
        "title": "Алфатар",
        "regionId": "3"
    },
    {
        "cityId": "4",
        "title": "Антоново",
        "regionId": "4"
    },
    {
        "cityId": "5",
        "title": "Априлци",
        "regionId": "5"
    },
    {
        "cityId": "6",
        "title": "Ардино",
        "regionId": "6"
    },
    {
        "cityId": "7",
        "title": "Асеновград",
        "regionId": "7"
    },
    {
        "cityId": "8",
        "title": "Ахелой",
        "regionId": "1"
    },
    {
        "cityId": "9",
        "title": "Ахтопол",
        "regionId": "1"
    },
    {
        "cityId": "10",
        "title": "Балчик",
        "regionId": "8"
    },
    {
        "cityId": "11",
        "title": "Банкя",
        "regionId": "9"
    },
    {
        "cityId": "12",
        "title": "Банско",
        "regionId": "10"
    },
    {
        "cityId": "13",
        "title": "Баня",
        "regionId": "7"
    },
    {
        "cityId": "14",
        "title": "Батак",
        "regionId": "11"
    },
    {
        "cityId": "15",
        "title": "Батановци",
        "regionId": "12"
    },
    {
        "cityId": "16",
        "title": "Белене",
        "regionId": "13"
    },
    {
        "cityId": "17",
        "title": "Белица",
        "regionId": "10"
    },
    {
        "cityId": "18",
        "title": "Белово",
        "regionId": "11"
    },
    {
        "cityId": "19",
        "title": "Белоградчик",
        "regionId": "14"
    },
    {
        "cityId": "20",
        "title": "Белослав",
        "regionId": "2"
    },
    {
        "cityId": "21",
        "title": "Берковица",
        "regionId": "15"
    },
    {
        "cityId": "22",
        "title": "Благоевград",
        "regionId": "10"
    },
    {
        "cityId": "23",
        "title": "Бобовдол",
        "regionId": "16"
    },
    {
        "cityId": "24",
        "title": "Бобошево",
        "regionId": "16"
    },
    {
        "cityId": "25",
        "title": "Божурище",
        "regionId": "17"
    },
    {
        "cityId": "26",
        "title": "Бойчиновци",
        "regionId": "15"
    },
    {
        "cityId": "27",
        "title": "Болярово",
        "regionId": "18"
    },
    {
        "cityId": "28",
        "title": "Борово",
        "regionId": "19"
    },
    {
        "cityId": "29",
        "title": "Ботевград",
        "regionId": "17"
    },
    {
        "cityId": "30",
        "title": "Брацигово",
        "regionId": "11"
    },
    {
        "cityId": "31",
        "title": "Брегово",
        "regionId": "14"
    },
    {
        "cityId": "32",
        "title": "Брезник",
        "regionId": "12"
    },
    {
        "cityId": "33",
        "title": "Брезово",
        "regionId": "7"
    },
    {
        "cityId": "34",
        "title": "Брусарци",
        "regionId": "15"
    },
    {
        "cityId": "35",
        "title": "Бургас",
        "regionId": "1"
    },
    {
        "cityId": "36",
        "title": "Бухово",
        "regionId": "9"
    },
    {
        "cityId": "37",
        "title": "Българово",
        "regionId": "1"
    },
    {
        "cityId": "38",
        "title": "Бяла",
        "regionId": "19"
    },
    {
        "cityId": "39",
        "title": "Бяла",
        "regionId": "19"
    },
    {
        "cityId": "40",
        "title": "Бяла Слатина",
        "regionId": "20"
    },
    {
        "cityId": "41",
        "title": "Бяла Черква",
        "regionId": "21"
    },
    {
        "cityId": "42",
        "title": "Варна",
        "regionId": "2"
    },
    {
        "cityId": "43",
        "title": "Велики Преслав",
        "regionId": "22"
    },
    {
        "cityId": "44",
        "title": "Велико Търново",
        "regionId": "21"
    },
    {
        "cityId": "45",
        "title": "Велинград",
        "regionId": "11"
    },
    {
        "cityId": "46",
        "title": "Ветово",
        "regionId": "19"
    },
    {
        "cityId": "47",
        "title": "Ветрен",
        "regionId": "11"
    },
    {
        "cityId": "48",
        "title": "Видин",
        "regionId": "14"
    },
    {
        "cityId": "49",
        "title": "Враца",
        "regionId": "20"
    },
    {
        "cityId": "50",
        "title": "Вълчедръм",
        "regionId": "15"
    },
    {
        "cityId": "51",
        "title": "Вълчидол",
        "regionId": "2"
    },
    {
        "cityId": "52",
        "title": "Върбица",
        "regionId": "22"
    },
    {
        "cityId": "53",
        "title": "Вършец",
        "regionId": "15"
    },
    {
        "cityId": "54",
        "title": "Габрово",
        "regionId": "23"
    },
    {
        "cityId": "55",
        "title": "Генерал Тошево",
        "regionId": "8"
    },
    {
        "cityId": "56",
        "title": "Главиница",
        "regionId": "3"
    },
    {
        "cityId": "57",
        "title": "Глоджево",
        "regionId": "19"
    },
    {
        "cityId": "58",
        "title": "Годеч",
        "regionId": "17"
    },
    {
        "cityId": "59",
        "title": "Горна Оряховица",
        "regionId": "21"
    },
    {
        "cityId": "60",
        "title": "Гоце Делчев",
        "regionId": "10"
    },
    {
        "cityId": "61",
        "title": "Грамада",
        "regionId": "14"
    },
    {
        "cityId": "62",
        "title": "Гулянци",
        "regionId": "13"
    },
    {
        "cityId": "63",
        "title": "Гурково",
        "regionId": "24"
    },
    {
        "cityId": "64",
        "title": "Гълъбово",
        "regionId": "24"
    },
    {
        "cityId": "65",
        "title": "Две могили",
        "regionId": "19"
    },
    {
        "cityId": "66",
        "title": "Дебелец",
        "regionId": "21"
    },
    {
        "cityId": "67",
        "title": "Девин",
        "regionId": "25"
    },
    {
        "cityId": "68",
        "title": "Девня",
        "regionId": "2"
    },
    {
        "cityId": "69",
        "title": "Джебел",
        "regionId": "6"
    },
    {
        "cityId": "70",
        "title": "Димитровград",
        "regionId": "26"
    },
    {
        "cityId": "71",
        "title": "Димово",
        "regionId": "14"
    },
    {
        "cityId": "72",
        "title": "Добринище",
        "regionId": "10"
    },
    {
        "cityId": "73",
        "title": "Добрич",
        "regionId": "8"
    },
    {
        "cityId": "74",
        "title": "Долнабаня",
        "regionId": "17"
    },
    {
        "cityId": "75",
        "title": "Долна Митрополия",
        "regionId": "13"
    },
    {
        "cityId": "76",
        "title": "Долна Оряховица",
        "regionId": "21"
    },
    {
        "cityId": "77",
        "title": "Долни Дъбник",
        "regionId": "13"
    },
    {
        "cityId": "78",
        "title": "Долни чифлик",
        "regionId": "2"
    },
    {
        "cityId": "79",
        "title": "Доспат",
        "regionId": "25"
    },
    {
        "cityId": "80",
        "title": "Драгоман",
        "regionId": "17"
    },
    {
        "cityId": "81",
        "title": "Дряново",
        "regionId": "23"
    },
    {
        "cityId": "82",
        "title": "Дулово",
        "regionId": "3"
    },
    {
        "cityId": "83",
        "title": "Дунавци",
        "regionId": "14"
    },
    {
        "cityId": "84",
        "title": "Дупница",
        "regionId": "16"
    },
    {
        "cityId": "85",
        "title": "Дългопол",
        "regionId": "2"
    },
    {
        "cityId": "86",
        "title": "Елена",
        "regionId": "21"
    },
    {
        "cityId": "87",
        "title": "Елин Пелин",
        "regionId": "17"
    },
    {
        "cityId": "88",
        "title": "Елхово",
        "regionId": "18"
    },
    {
        "cityId": "89",
        "title": "Етрополе",
        "regionId": "17"
    },
    {
        "cityId": "90",
        "title": "Завет",
        "regionId": "27"
    },
    {
        "cityId": "91",
        "title": "Земен",
        "regionId": "12"
    },
    {
        "cityId": "92",
        "title": "Златарица",
        "regionId": "21"
    },
    {
        "cityId": "93",
        "title": "Златица",
        "regionId": "17"
    },
    {
        "cityId": "94",
        "title": "Златоград",
        "regionId": "25"
    },
    {
        "cityId": "95",
        "title": "Ивайловград",
        "regionId": "26"
    },
    {
        "cityId": "96",
        "title": "Игнатиево",
        "regionId": "2"
    },
    {
        "cityId": "97",
        "title": "Искър",
        "regionId": "13"
    },
    {
        "cityId": "98",
        "title": "Исперих",
        "regionId": "27"
    },
    {
        "cityId": "99",
        "title": "Ихтиман",
        "regionId": "17"
    },
    {
        "cityId": "100",
        "title": "Каблешково",
        "regionId": "1"
    },
    {
        "cityId": "101",
        "title": "Каварна",
        "regionId": "8"
    },
    {
        "cityId": "102",
        "title": "Казанлък",
        "regionId": "24"
    },
    {
        "cityId": "103",
        "title": "Калофер",
        "regionId": "7"
    },
    {
        "cityId": "104",
        "title": "Камено",
        "regionId": "1"
    },
    {
        "cityId": "105",
        "title": "Каолиново",
        "regionId": "22"
    },
    {
        "cityId": "106",
        "title": "Карлово",
        "regionId": "7"
    },
    {
        "cityId": "107",
        "title": "Карнобат",
        "regionId": "1"
    },
    {
        "cityId": "108",
        "title": "Каспичан",
        "regionId": "22"
    },
    {
        "cityId": "109",
        "title": "Кермен",
        "regionId": "28"
    },
    {
        "cityId": "110",
        "title": "Килифарево",
        "regionId": "21"
    },
    {
        "cityId": "111",
        "title": "Китен",
        "regionId": "1"
    },
    {
        "cityId": "112",
        "title": "Клисура",
        "regionId": "7"
    },
    {
        "cityId": "113",
        "title": "Кнежа",
        "regionId": "13"
    },
    {
        "cityId": "114",
        "title": "Козлодуй",
        "regionId": "20"
    },
    {
        "cityId": "115",
        "title": "Койнаре",
        "regionId": "13"
    },
    {
        "cityId": "116",
        "title": "Копривщица",
        "regionId": "17"
    },
    {
        "cityId": "117",
        "title": "Костандово",
        "regionId": "11"
    },
    {
        "cityId": "118",
        "title": "Костенец",
        "regionId": "17"
    },
    {
        "cityId": "119",
        "title": "Костинброд",
        "regionId": "17"
    },
    {
        "cityId": "120",
        "title": "Котел",
        "regionId": "28"
    },
    {
        "cityId": "121",
        "title": "Кочериново",
        "regionId": "16"
    },
    {
        "cityId": "122",
        "title": "Кресна",
        "regionId": "10"
    },
    {
        "cityId": "123",
        "title": "Криводол",
        "regionId": "20"
    },
    {
        "cityId": "124",
        "title": "Кричим",
        "regionId": "7"
    },
    {
        "cityId": "125",
        "title": "Крумовград",
        "regionId": "6"
    },
    {
        "cityId": "126",
        "title": "Крън",
        "regionId": "24"
    },
    {
        "cityId": "127",
        "title": "Кубрат",
        "regionId": "27"
    },
    {
        "cityId": "128",
        "title": "Куклен",
        "regionId": "7"
    },
    {
        "cityId": "129",
        "title": "Кула",
        "regionId": "14"
    },
    {
        "cityId": "130",
        "title": "Кърджали",
        "regionId": "6"
    },
    {
        "cityId": "131",
        "title": "Кюстендил",
        "regionId": "16"
    },
    {
        "cityId": "132",
        "title": "Левски",
        "regionId": "13"
    },
    {
        "cityId": "133",
        "title": "Летница",
        "regionId": "5"
    },
    {
        "cityId": "134",
        "title": "Ловеч",
        "regionId": "5"
    },
    {
        "cityId": "135",
        "title": "Лозница",
        "regionId": "27"
    },
    {
        "cityId": "136",
        "title": "Лом",
        "regionId": "15"
    },
    {
        "cityId": "137",
        "title": "Луковит",
        "regionId": "5"
    },
    {
        "cityId": "138",
        "title": "Лъки",
        "regionId": "7"
    },
    {
        "cityId": "139",
        "title": "Любимец",
        "regionId": "26"
    },
    {
        "cityId": "140",
        "title": "Лясковец",
        "regionId": "21"
    },
    {
        "cityId": "141",
        "title": "Мадан",
        "regionId": "25"
    },
    {
        "cityId": "142",
        "title": "Маджарово",
        "regionId": "26"
    },
    {
        "cityId": "143",
        "title": "Малко Търново",
        "regionId": "1"
    },
    {
        "cityId": "144",
        "title": "Мартен",
        "regionId": "19"
    },
    {
        "cityId": "145",
        "title": "Мездра",
        "regionId": "20"
    },
    {
        "cityId": "146",
        "title": "Мелник",
        "regionId": "10"
    },
    {
        "cityId": "147",
        "title": "Меричлери",
        "regionId": "26"
    },
    {
        "cityId": "148",
        "title": "Мизия",
        "regionId": "20"
    },
    {
        "cityId": "149",
        "title": "Моминпроход",
        "regionId": "17"
    },
    {
        "cityId": "150",
        "title": "Момчилград",
        "regionId": "6"
    },
    {
        "cityId": "151",
        "title": "Монтана",
        "regionId": "15"
    },
    {
        "cityId": "152",
        "title": "Мъглиж",
        "regionId": "24"
    },
    {
        "cityId": "153",
        "title": "Неделино",
        "regionId": "25"
    },
    {
        "cityId": "154",
        "title": "Несебър",
        "regionId": "1"
    },
    {
        "cityId": "155",
        "title": "Николаево",
        "regionId": "24"
    },
    {
        "cityId": "156",
        "title": "Никопол",
        "regionId": "13"
    },
    {
        "cityId": "157",
        "title": "Нова Загора",
        "regionId": "28"
    },
    {
        "cityId": "158",
        "title": "Нови Искър",
        "regionId": "9"
    },
    {
        "cityId": "159",
        "title": "Нови пазар",
        "regionId": "22"
    },
    {
        "cityId": "160",
        "title": "Обзор",
        "regionId": "1"
    },
    {
        "cityId": "161",
        "title": "Омуртаг",
        "regionId": "4"
    },
    {
        "cityId": "162",
        "title": "Опака",
        "regionId": "4"
    },
    {
        "cityId": "163",
        "title": "Оряхово",
        "regionId": "20"
    },
    {
        "cityId": "164",
        "title": "Павелбаня",
        "regionId": "24"
    },
    {
        "cityId": "165",
        "title": "Павликени",
        "regionId": "21"
    },
    {
        "cityId": "166",
        "title": "Пазарджик",
        "regionId": "11"
    },
    {
        "cityId": "167",
        "title": "Панагюрище",
        "regionId": "11"
    },
    {
        "cityId": "168",
        "title": "Перник",
        "regionId": "12"
    },
    {
        "cityId": "169",
        "title": "Перущица",
        "regionId": "7"
    },
    {
        "cityId": "170",
        "title": "Петрич",
        "regionId": "10"
    },
    {
        "cityId": "171",
        "title": "Пещера",
        "regionId": "11"
    },
    {
        "cityId": "172",
        "title": "Пирдоп",
        "regionId": "17"
    },
    {
        "cityId": "173",
        "title": "Плачковци",
        "regionId": "23"
    },
    {
        "cityId": "174",
        "title": "Плевен",
        "regionId": "13"
    },
    {
        "cityId": "175",
        "title": "Плиска",
        "regionId": "22"
    },
    {
        "cityId": "176",
        "title": "Пловдив",
        "regionId": "7"
    },
    {
        "cityId": "177",
        "title": "Полски Тръмбеш",
        "regionId": "21"
    },
    {
        "cityId": "178",
        "title": "Поморие",
        "regionId": "1"
    },
    {
        "cityId": "179",
        "title": "Попово",
        "regionId": "4"
    },
    {
        "cityId": "180",
        "title": "Пордим",
        "regionId": "13"
    },
    {
        "cityId": "181",
        "title": "Правец",
        "regionId": "17"
    },
    {
        "cityId": "182",
        "title": "Приморско",
        "regionId": "1"
    },
    {
        "cityId": "183",
        "title": "Провадия",
        "regionId": "2"
    },
    {
        "cityId": "184",
        "title": "Първомай",
        "regionId": "7"
    },
    {
        "cityId": "185",
        "title": "Раднево",
        "regionId": "24"
    },
    {
        "cityId": "186",
        "title": "Радомир",
        "regionId": "12"
    },
    {
        "cityId": "187",
        "title": "Разград",
        "regionId": "27"
    },
    {
        "cityId": "188",
        "title": "Разлог",
        "regionId": "10"
    },
    {
        "cityId": "189",
        "title": "Ракитово",
        "regionId": "11"
    },
    {
        "cityId": "190",
        "title": "Раковски",
        "regionId": "7"
    },
    {
        "cityId": "191",
        "title": "Рила",
        "regionId": "16"
    },
    {
        "cityId": "192",
        "title": "Роман",
        "regionId": "20"
    },
    {
        "cityId": "193",
        "title": "Рудозем",
        "regionId": "25"
    },
    {
        "cityId": "194",
        "title": "Русе",
        "regionId": "19"
    },
    {
        "cityId": "195",
        "title": "Садово",
        "regionId": "7"
    },
    {
        "cityId": "196",
        "title": "Самоков",
        "regionId": "17"
    },
    {
        "cityId": "197",
        "title": "Сандански",
        "regionId": "10"
    },
    {
        "cityId": "198",
        "title": "Сапарева баня",
        "regionId": "16"
    },
    {
        "cityId": "199",
        "title": "Свети Влас",
        "regionId": "1"
    },
    {
        "cityId": "200",
        "title": "Свиленград",
        "regionId": "26"
    },
    {
        "cityId": "201",
        "title": "Свищов",
        "regionId": "21"
    },
    {
        "cityId": "202",
        "title": "Своге",
        "regionId": "17"
    },
    {
        "cityId": "203",
        "title": "Севлиево",
        "regionId": "23"
    },
    {
        "cityId": "204",
        "title": "Сеново",
        "regionId": "19"
    },
    {
        "cityId": "205",
        "title": "Септември",
        "regionId": "11"
    },
    {
        "cityId": "206",
        "title": "Силистра",
        "regionId": "3"
    },
    {
        "cityId": "207",
        "title": "Симеоновград",
        "regionId": "26"
    },
    {
        "cityId": "208",
        "title": "Симитли",
        "regionId": "10"
    },
    {
        "cityId": "209",
        "title": "Славяново",
        "regionId": "13"
    },
    {
        "cityId": "210",
        "title": "Сливен",
        "regionId": "28"
    },
    {
        "cityId": "211",
        "title": "Сливница",
        "regionId": "17"
    },
    {
        "cityId": "212",
        "title": "Сливополе",
        "regionId": "19"
    },
    {
        "cityId": "213",
        "title": "Смолян",
        "regionId": "25"
    },
    {
        "cityId": "214",
        "title": "Смядово",
        "regionId": "22"
    },
    {
        "cityId": "215",
        "title": "Созопол",
        "regionId": "1"
    },
    {
        "cityId": "216",
        "title": "Сопот",
        "regionId": "7"
    },
    {
        "cityId": "217",
        "title": "София",
        "regionId": "9"
    },
    {
        "cityId": "218",
        "title": "Средец",
        "regionId": "1"
    },
    {
        "cityId": "219",
        "title": "Стамболийски",
        "regionId": "7"
    },
    {
        "cityId": "220",
        "title": "Стара Загора",
        "regionId": "24"
    },
    {
        "cityId": "221",
        "title": "Стражица",
        "regionId": "21"
    },
    {
        "cityId": "222",
        "title": "Стралджа",
        "regionId": "18"
    },
    {
        "cityId": "223",
        "title": "Стрелча",
        "regionId": "11"
    },
    {
        "cityId": "224",
        "title": "Суворово",
        "regionId": "2"
    },
    {
        "cityId": "225",
        "title": "Сунгурларе",
        "regionId": "1"
    },
    {
        "cityId": "226",
        "title": "Сухиндол",
        "regionId": "21"
    },
    {
        "cityId": "227",
        "title": "Съединение",
        "regionId": "7"
    },
    {
        "cityId": "228",
        "title": "Сърница",
        "regionId": "11"
    },
    {
        "cityId": "229",
        "title": "Твърдица",
        "regionId": "28"
    },
    {
        "cityId": "230",
        "title": "Тервел",
        "regionId": "8"
    },
    {
        "cityId": "231",
        "title": "Тетевен",
        "regionId": "5"
    },
    {
        "cityId": "232",
        "title": "Тополовград",
        "regionId": "26"
    },
    {
        "cityId": "233",
        "title": "Троян",
        "regionId": "5"
    },
    {
        "cityId": "234",
        "title": "Трън",
        "regionId": "12"
    },
    {
        "cityId": "235",
        "title": "Тръстеник",
        "regionId": "13"
    },
    {
        "cityId": "236",
        "title": "Трявна",
        "regionId": "23"
    },
    {
        "cityId": "237",
        "title": "Тутракан",
        "regionId": "3"
    },
    {
        "cityId": "238",
        "title": "Търговище",
        "regionId": "4"
    },
    {
        "cityId": "239",
        "title": "Угърчин",
        "regionId": "5"
    },
    {
        "cityId": "240",
        "title": "Хаджидимово",
        "regionId": "10"
    },
    {
        "cityId": "241",
        "title": "Харманли",
        "regionId": "26"
    },
    {
        "cityId": "242",
        "title": "Хасково",
        "regionId": "26"
    },
    {
        "cityId": "243",
        "title": "Хисаря",
        "regionId": "7"
    },
    {
        "cityId": "244",
        "title": "Цар Калоян",
        "regionId": "27"
    },
    {
        "cityId": "245",
        "title": "Царево",
        "regionId": "1"
    },
    {
        "cityId": "246",
        "title": "Чепеларе",
        "regionId": "25"
    },
    {
        "cityId": "247",
        "title": "Червен бряг",
        "regionId": "13"
    },
    {
        "cityId": "248",
        "title": "Черноморец",
        "regionId": "1"
    },
    {
        "cityId": "249",
        "title": "Чипровци",
        "regionId": "15"
    },
    {
        "cityId": "250",
        "title": "Чирпан",
        "regionId": "24"
    },
    {
        "cityId": "251",
        "title": "Шабла",
        "regionId": "8"
    },
    {
        "cityId": "252",
        "title": "Шивачево",
        "regionId": "28"
    },
    {
        "cityId": "253",
        "title": "Шипка",
        "regionId": "24"
    },
    {
        "cityId": "254",
        "title": "Шумен",
        "regionId": "22"
    },
    {
        "cityId": "255",
        "title": "Ябланица",
        "regionId": "5"
    },
    {
        "cityId": "256",
        "title": "Якоруда",
        "regionId": "10"
    },
    {
        "cityId": "257",
        "title": "Ямбол",
        "regionId": "18"
    }
    ]
}

export default cities;