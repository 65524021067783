import React, { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";
import { multiFilter } from "../../../data/mui_styles"

import districts from "../../../data/districts";

export function DistrictSearchDropdown({selectedDistrict, setSelectedDistrict, setDistrictName, filters, isMobile}: any) {
  const customStyles = {
    width: !isMobile ? 250 : 300
  }

  const [values, setValues] = React.useState<string[]>([]);

  const options = districts.options.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });
  const title = districts.title || '';

  const newTheme = (theme: any) => createTheme({
      ...theme,
      components: !isMobile === true ? multiFilter.web : multiFilter.mobile   
  });

  useEffect(() => {
    if(filters.district == '')
      setValues([]);
  }, [filters.district]);

  return (
    <Stack sx={{ width: customStyles.width || 250 }}>
      <ThemeProvider theme={newTheme}>
          <Autocomplete
            multiple
            limitTags={1}
            {...autocompleteOptions}
            id="size-small-outlined-multi"
            size="small"
            options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
            value={values}
            getOptionLabel={(option: any) => option.title}
            sx={!isMobile ? getAutocompleteStyles("#52af77") : getAutocompleteStylesMobile("#52af77")}
            onChange={(e, v) => {
                if(setSelectedDistrict) {
                  if(v.length > 0){
                      let IDsArray = [];
                      let namesArray = [];
                      let names = '';
                      let ids = '';
                      for(let i = 0; i < v.length; i++) {
                          IDsArray.push(v[i].districtId);
                          namesArray.push(v[i].title);
                      }

                      ids = IDsArray.join(',');
                      setSelectedDistrict(ids);

                      names = namesArray.join(',');
                      setDistrictName(names);
                      setValues(v);
                  }
                  else {
                    setSelectedDistrict('');
                    setDistrictName('');
                  }
              }
            }}
            
            renderInput={(params) => (
              <TextField {...params} 
                label={title}
                placeholder={title}
                sx={{backgroundColor: "#fff"}}
              />
            )}
          />
      </ThemeProvider>
    </Stack>
  );
};
