import React, { useEffect, useState } from "react";
import oidcService from "../../auth/services/oidcService";
import { useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    oidcService
      .getUser()
      .then((user) => {
        if (user) {
          setIsAuthenticated(true); // User is authenticated
        } else {
          oidcService.login(location.pathname); // Redirect to Keycloak login page
        }
      })
      .finally(() => setIsLoading(false)); // Stop loading spinner
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Loading spinner while checking auth state
  }

  return isAuthenticated ? <>{children}</> : null; // Render content if authenticated
};

export default ProtectedRoute;