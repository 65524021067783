import "./dogProfile.css";
import { useState } from 'react';
import { SiDatadog } from "react-icons/si";
import { IoIosAdd } from "react-icons/io";
import { PiDog, PiDogLight, PiDogThin   } from "react-icons/pi";


import Modal from "../../modal/Modal";
import DogProfileCard from "./DogProfileCard";

export default function DogProfile ({isMobile, mode}: any) {
  const [showModal, setShowModal] = useState(false);
  const [modalFunction, setModalFunction] = useState('');
  const [selectedPet, setSelectedPet] = useState();

  const [pets, setPets] = useState([
    {
      uuid: "6a1e1e57-9f36-4617-9179-76083947eac7",
      name: "Арес",
      breed: {
       title: "Питбул",
       type: "dog"
      },
      type: "dog",
      region: {
        title: "Пловдив",
        districtId: "7",
        firstLetter: "П"
      },
      city: {firstLetter: 'П', cityId: '176', title: 'Пловдив', regionId: '7'},
      sex: "Мъжки",
      color: [{
        firstLetter: "Ч",
        hex: "#000",
        title: "Черен"
      }],
      weight: "87",
      height: "73",
      birthday: "",
      vaccinated: true,
      original_breed: true,
      chip: true,
      castrated: false,
      partner_finder: true,
      adoption: false,
      image: 'ares.jpg',
      gallery: [
          "ares1.jpg",
          "ares2.jpg",
          "ares3.jpg"
      ],
      document_image: "certificate.jpg"
    }
  ]); 

  const handleModal = function(type: string, pet: any) {
      setModalFunction(type);

      if(type == "editPetProfile") {
        setSelectedPet(pet);
      }
      else {
        setSelectedPet(undefined);
      }      
      setShowModal(true);
  }

  /* TODO:
      1. Calculate the age based on the birthday date
      2. Theme selection functionality
   */

  return (

    <div className="outter-cont">
      
      {pets.map((pet)=>{
        return <DogProfileCard pet={ pet } setShowModal={handleModal} setPets={setPets} isMobile={isMobile} mode={mode}></DogProfileCard>
      })}
      
      <div className={`${mode === "dark" ? "add-profile-cont-dark" : "add-profile-cont-light"}`}>
        <Modal showModal={ showModal } setShowModal={ setShowModal } pets={ pets } setPets={ setPets } content={modalFunction} selectedPet={selectedPet}></Modal>
        
        <div className="inner-cont">
            <p style={{ color: mode === "dark" ? "rgba(255,255,255, 0.6)" : "rgba(0,0,0,0.6)" }}>Добави профил на своя любимец </p>
            <div className="add-pet-profile-cont" onClick={() => {handleModal("createPetProfile", '')}}>
              <PiDogThin className="add-pet-profile-icon" />
              <IoIosAdd className="add-pet-profile-icon" style={{ fontSize: "2vw", marginLeft: "-1vw" }}/>
            </div>
        </div>
      </div>
    </div>
  );
};
