import React, { useState, useEffect } from "react";
import "./resultsSection.css";
import ResultCard from "./ResultCard/ResultCard";
import PaginationOutlined  from "../pagination/Pagination";
import petsData from "../../../data/pets";

type ResultSectionProps = {
    module: string;
    isLoading: any;
    filters: any;
    filtersCleared: any;
    setFiltersCleared: (a: boolean)=> void;
    openPetProfile: (pet: any) => void;
    setSelectedPet: (pet: any)=> void;
    setFavourites: (fav: any, operation: any)=> void;
    favourites: Array<{}>;
    showFavourites: boolean;
};
  

export const ResultsSection: React.FC<ResultSectionProps> = ({
    module,
    isLoading, 
    filters, 
    filtersCleared, 
    setFiltersCleared, 
    openPetProfile, 
    setSelectedPet,
    setFavourites,
    favourites,
    showFavourites}) => {

    const [pets, setPets] = useState<Array<{}>>(petsData || []);
    const [petsPerPage, setPetsPerPage] = useState(9);

    const [filteredPets, setFilteredPets] = useState<Array<{}>>(pets)
    const [pageItems, setPageItems] = useState<Array<{}>>([])
    const [currentPage, setCurrentPage] = useState(0); 

    useEffect(() => {
        var newArray = [];
        var startingIndex = petsPerPage * currentPage;

        for(var i = startingIndex; i < petsPerPage * (currentPage + 1); i++) {
            if(i < filteredPets.length)
                newArray.push(filteredPets[i]);
        }

        setPageItems(newArray);
    }, [filteredPets,currentPage]);


    useEffect(() => {
        if(isLoading) {
            setTimeout(()=>{
                if(filtersCleared) {
                    setFilteredPets(pets);
                    setFiltersCleared(false);
                }
                else if(showFavourites){
                    setFilteredPets(favourites);
                }
                else {
                    let filteredByAdoptFrom = (filters.adoptFrom && filters.adoptForm !== '') ? pets.filter(
                        (pet: any) => pet.adoptionFrom == filters.adoptFrom
                    ) || []: pets;
                    
                    let filteredByType = filters.petType != '' ? filteredByAdoptFrom.filter(
                        (pet: any) => pet.type == filters.petType
                    ) || []: filteredByAdoptFrom;
                
                    let filteredBySex = filters.sex != '' ? filteredByType.filter(
                        (pet: any) => pet.sex == filters.sex
                    ) || []: filteredByType;

                    let filteredByBreed = filters.breed != '' ? filteredBySex.filter(
                        (pet: any) => filters.breed.includes(pet.breed)
                    ) || []: filteredBySex;
                
                    let filteredByColor = filters.color != '' ? filteredByBreed.filter(
                        (pet: any) =>  filters.color.includes(pet.color.label)
                    ) || []: filteredByBreed;

                    let filteredByDistrict = filters.district != '' ? filteredByColor.filter(
                        (pet: any) =>  filters.district.includes(pet.region)
                    ) || []: filteredByColor;
    
                    let filteredByCity = filters.city != '' ? filteredByDistrict.filter(
                        (pet: any) =>  filters.city.includes(pet.city)
                    ) || []: filteredByDistrict;

                    let filteredByAge = filters.age.length !== 0 ? filteredByCity.filter(
                        (pet: any) =>  pet.age >= filters.age[0] && pet.age <= filters.age[1]
                    ) || []: filteredByCity;

                    let filteredByWeight = filters.weight.length !== 0 ? filteredByAge.filter(
                        (pet: any) =>  pet.weight >= filters.weight[0] && pet.weight <= filters.weight[1]
                    ) || []: filteredByAge;

                    let filteredByVaccinated = filters.vaccinated == true ? filteredByWeight.filter(
                        (pet: any) =>  pet.vaccinated == filters.vaccinated
                    ) || []: filteredByWeight
    
                    let filteredByOriginalBreed = filters.originalBreed == true ? filteredByVaccinated.filter(
                        (pet: any) =>  pet.original_breed == filters.originalBreed
                    ) || []: filteredByVaccinated

                    setFilteredPets(filteredByOriginalBreed);
                }
            }, 
            2000);
        }   
    }, [isLoading]);

    return (
        <>
            <span className="separator-line"></span>

            {filteredPets.length == 0 ? (
                <div className="no-results-cont" style={{ marginTop: "5rem" }}>
                    <p className="no-results-message">Няма намерени резултати при зададените от Вас критерии!</p>
                </div>
            ): (
                <>
                    {/* RESULTS */}
                    <div className="result_cards">
                        {pageItems.map((pet:any)=>{
                            return <ResultCard
                                        module={module} 
                                        pet={ pet } 
                                        openPetProfile={openPetProfile} 
                                        setSelectedPet={setSelectedPet}
                                        setFavourites={setFavourites}
                                    />
                        })}
                    </div>
                    
                    {/* PAGINATION */}
                    <div className="">
                        <div className="hump-pagination-container">
                            <PaginationOutlined data={filteredPets} itemsPerPage={petsPerPage} setPage={setCurrentPage}/>
                        </div>         
                    </div>
                </>
            )}

            
            
            
        </>
    );
};
