import React from 'react';
import { useState, useEffect } from 'react';
import './contactForm.css';
import { Navbar } from '../../navbar/Navbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputAdornment from "@mui/material/InputAdornment";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FiAlertTriangle } from "react-icons/fi";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from "@mui/material/Button";
import { Footer } from '../../landing/footer/Footer';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';

export const ContactForm = () => {
    const [emailError, setEmailError] = useState(false);
    const [contactInfo, setContactInfo] = useState({
        email: "",
        name: "",
        message: ""
    });
    const [apiMessage, setApiMessage] = useState<{ message: string; success: boolean } | null>(null);

    const apiKey = process.env.REACT_APP_CONTACT_EMAIL_AZURE_FUNCTION_API_KEY || "";
    const endpointURL = process.env.REACT_APP_CONTACT_EMAIL_AZURE_FUNCTION_URL || "";

    const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed
    const mode = useSelector((state: any) => state.theme.mode);

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContactInfo((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));

        if(validateEmail(e.target.value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const handleEmailSubmition = async () => {
        const { email, name, message } = contactInfo;
        const contactBody = { email, name, message };
    
        try {
            const response = await fetch(endpointURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": apiKey,
                },
                body: JSON.stringify(contactBody),
            });
            const responseData = await response.json();
            if (response.ok) {
                setApiMessage({
                    message: responseData.message,
                    success: true,
                });
            } else {
                setApiMessage({
                    message: responseData.message,
                    success: false,
                });
            }
        } catch (error) {
            console.error("Email was not sent:", error);
            setApiMessage({
                message: "Възникна техническа грешка. Извиняваме се за неудобството!",
                success: false,
            });
        }
        document.getElementById('emailed')?.classList.add('show-emailed');
    };
    
    useEffect(() => {
        if (apiMessage) {
            const timer = setTimeout(() => {
                setApiMessage(null); // Reset message after 3 seconds
                setContactInfo({         // Reset form data
                    name: "",
                    email: "",
                    message: "",
                });
            }, 3000); // 3 seconds
    
            return () => clearTimeout(timer);
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                entry.isIntersecting
                    ? entry.target.classList.add("show")
                    : entry.target.classList.remove("show");
            });
        });

        const hiddenLeft = document.querySelectorAll(".hide-left");
        const hiddenRight = document.querySelectorAll(".hide-right");

        hiddenLeft.forEach((el) => observer.observe(el));
        hiddenRight.forEach((el) => observer.observe(el));

        return () => observer.disconnect();
    }, [apiMessage]);

    const textFieldTheme = createTheme({
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: 'red',
                        margin: '5px auto'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        fontSize: isMobile ? "2rem" : "1.8rem",
                        height: isMobile ? "5rem" : "6rem",
                    },
                    input: {
                        fontFamily: "Adys Regular, sans-serif",
                        fontSize: isMobile ? "2rem" : "1.8rem",
                        height: isMobile ? "5rem" : "3rem",
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: "Adys Regular, sans-serif",
                        width: "max-content",
                        fontSize: isMobile ? "1.6rem" : "1.6rem"
                    },
                    shrink: {
                        fontFamily: "Adys Regular, sans-serif",
                        width: "max-content",
                        fontSize: isMobile ? "1.8rem" : "1.8rem"
                    }
                }
            }
        }
    })

    const multiLineFieldTheme = createTheme({
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: 'red',
                        margin: '5px auto'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        fontSize: isMobile ? "2rem" : "1.8rem",
                        height: "auto",
                    },
                    input: {
                        fontFamily: "Adys Regular, sans-serif",
                        fontSize: isMobile ? "2rem" : "1.8rem",
                        height: isMobile ? "5rem" : "6rem",
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        fontFamily: "Adys Regular, sans-serif",
                        width: "max-content",
                        fontSize: isMobile ? "1.6rem" : "1.6rem"
                    },
                    shrink: {
                        fontFamily: "Adys Regular, sans-serif",
                        width: "max-content",
                        fontSize: isMobile ? "1.8rem" : "1.8rem"
                    }
                }
            }
        }
    })

    return (
        <>
            <div style={{ backgroundImage: "linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1))",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "fixed",
            top: "0",
            right: "0",
            zIndex: "1000"
            }}>
            <Navbar />
            </div>
            <div className="contacts_page_container" >
                <div className="contact_form">
                <p>Изпратете ни имейл:</p>
                {apiMessage ? (
                        <div className={`emailed-message-cont show ${apiMessage.success ? 'success-message' : 'error-message'}`}>
                            <div id="emailed" className="emailed-cont">
                                {apiMessage.success ? (
                                    <CheckCircleIcon className="emailed-icon"/>
                                ): (
                                    <FiAlertTriangle className="error-icon" />
                                )}

                                <p className={apiMessage.success ? "heading-sub-text success_txt" : "heading-sub-text error_txt"}>{apiMessage.message}</p>
                            </div>
                        </div>
                    ) : (
                 <div>
                    {/* Име */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw", marginBottom: isMobile ? "0.7rem" : "0"}}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                id="name"
                                label="Име"
                                name="name"
                                value={contactInfo.name}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem"}}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactInfo((data) => ({
                                    ...data,
                                    [event.target.name]: event.target.value,
                                    }));
                                }}
                            />
                        </ThemeProvider>
                    </FormControl>

                    {/* Имейл */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                id="email"
                                label="Имейл"
                                name="email"
                                autoComplete='off'
                                value={contactInfo.email}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem", marginBottom: isMobile ? "0.7rem" : "0"}}
                                onChange={ handleEmailChange }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {(contactInfo.email.length < 3 && !emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "grey", fontSize: isMobile ? "1.7rem" : "1.5vw", mr: "0.6rem" }}/>
                                            )}
                                            {(emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "#b20000", fontSize: isMobile ? "1.7rem" : "1.5vw", mr: "0.6rem" }}/>
                                            )}
                                            {(contactInfo.email.length > 3 && !emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "#65C466", fontSize: isMobile ? "1.7rem" : "1.5vw", mr: "0.6rem" }}/>
                                            )}
                                        </InputAdornment>),
                                    type: "email"
                                }}
                            />
                        </ ThemeProvider >
                    </FormControl>

                    {/* Допълнителна информация */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <ThemeProvider theme={multiLineFieldTheme}>
                            <TextField
                                id="contactMessage"
                                label="Пишете тук"
                                name="message"
                                value={contactInfo.message}
                                variant="outlined"
                                color="success"
                                multiline
                                sx={{ width: "100%", fontSize: "1.4rem"}}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setContactInfo((data) => ({
                                    ...data,
                                    [event.target.name]: event.target.value,
                                    }));
                                }}
                            />
                        </ThemeProvider>
                    </FormControl>

                    <Button
                        className="form-button"
                        variant="contained"
                        color="success"
                        type="submit"
                        onClick={handleEmailSubmition}
                        disabled={ contactInfo.name.length < 3 || contactInfo.email === '' || emailError || contactInfo.message === ''}
                    >
                        ИЗПРАТИ
                    </Button>
                </div>
                  )}
                </div>
       
                <p className="socials-message">Или ни пишете в социалните мрежи:</p>

                <div className="socials_container" >
                    <div className="social_card" onClick={() => { window.open("https://www.facebook.com/profile.php?id=61561869945434", '_blank'); }}>
                        <p>Facebook</p>
                        <FacebookIcon className="social_icon"/>
                    </div> 

                    <div className="social_card" onClick={() => { window.open("https://www.instagram.com/petconnect.global/", '_blank'); }}>
                        <p>Instagram</p>
                        <InstagramIcon className="social_icon"/>
                    </div>            
                </div>
            </div>

            <div style={{ marginTop: "9vw" }}>
                <Footer theme={"dark"}/>
            </div>                    
        </>
        
    );
};
