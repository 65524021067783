import { useState, useEffect, useRef } from "react";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Button from "@mui/material/Button";
import "./resultCard.css";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import { FaWeightScale } from "react-icons/fa6";

import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import GiteOutlinedIcon from '@mui/icons-material/GiteOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

const ResultCard = ({ pet, openPetProfile, setSelectedPet, setFavourites, module }: any) => {
    const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed
    const mode = useSelector((state: any) => state.theme.mode);

    const [isFavourite, setIsFavourite] = useState(false);
    const dogImage = require('../../../../assets/' + pet.image);

    const handleFavourite = (pet: any, operation: any) => {
        setIsFavourite(!isFavourite);
        setFavourites(pet, operation);
    }

    return (
        <div className="result_card-containter" style={{ backgroundColor: mode === "dark" ? "#191919" : "#fff" }} onClick={ (event: any)=>{
                openPetProfile(pet);
            }}>
            <div className="result_card-content">

                <div className="result_card-top_container">
                    <div className="result_card-dog_details_header">
                        <div className="result_card-dog_details_header1">
                            {pet.type == "dog" ? (
                                <PiDog style={{ fontSize: "2.2rem", marginRight: "1.2rem", opacity: "0.5" }}/>
                            ): (
                                <PiCat style={{ fontSize: "2.2rem", marginRight: "1.2rem", opacity: "0.5" }}/>    
                            )}
                            <p className="dog-name">{pet.name}</p>
                            <p className="dog-breed" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>/<em>{pet.breed}</em></p>
                        </div>

                        {!isFavourite ? (
                            // ADD TO FAVOURITES
                            <Tooltip 
                                title="Запази в Харесани" 
                                arrow
                                slotProps={{
                                    popper: {
                                        sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginTop: '0.5rem',
                                                fontSize: '1.3rem',
                                                fontFamily: 'Adys Regular',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                maxWidth: 220,
                                                backgroundColor: '#f5f5f9',
                                                border: '1px solid #dadde9',
                                            },
                                        },
                                    },
                                }}
                            >
                                < FavoriteBorderOutlinedIcon
                                    style={{
                                        fontSize: "3rem", 
                                        marginLeft: "1rem", 
                                        transition: "all 0.3s", 
                                        color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)" 
                                    }} 
                                    className="favourites_icon"
                                    onClick={(event: any)=>{
                                       handleFavourite(pet, "add");
                                    }}
                                />
                            </Tooltip>
                        ): (
                            // REMOVE FROM FAVOURITES
                            <Tooltip
                                title="Премахни от Харесани" 
                                arrow
                                slotProps={{
                                    popper: {
                                        sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginTop: '0.5rem',
                                                fontSize: '1.3rem',
                                                fontFamily: 'Adys Regylar',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                maxWidth: 220,
                                                backgroundColor: '#f5f5f9',
                                                border: '1px solid #dadde9',
                                            },
                                        },
                                    },
                                }}
                                
                            >
                                <FavoriteOutlinedIcon
                                    style={{fontSize: "3rem", marginLeft: "1rem", transition: "all 0.3s", color: "#B43F50" }} 
                                    className="favourites_icon"
                                    onClick={(event: any)=>{
                                        handleFavourite(pet, "remove");
                                    }}
                                />
                            </Tooltip>    
                        )}
                    </div>       

                    <span className="result_card-separator-line"></span>
                </div>

                <div className="result_card-dog_details_container">

                    {/* IMAGE */}
                    <div style={{ opacity: "1", width: "24rem", height: "12rem"}}>
                        <img src={dogImage} className="result_card-dog_image" style={{width: "100%", height: "100%"}} /> 
                    </div>
                    
                    <div className="main-container">
                    
                            {/* ADDRESS */}
                            <div className="info-row-container">
                                <FmdGoodOutlinedIcon sx={{ fontSize: "1.8rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}} />
                                <p className="dog-address" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>{pet.city + ', ' + pet.region}</p>
                            </div>

                            {/* SEX */}
                            <div className="info-row-container">
                                {pet.sex == "Мъжки" && (
                                    <MaleOutlinedIcon sx={{ fontSize: "1.9rem", color: "#1073d6"}} />
                                )}
                                
                                {pet.sex == "Женски" && (
                                    <FemaleOutlinedIcon sx={{ fontSize: "1.9rem", color: "#bf1077"}} /> 
                                )}
                                
                                <p className="dog-sex" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>{pet.sex}</p>
                            </div>

                            {/* COLOR */}
                            <div className="info-row-container">
                                
                                {pet.color.value == "#fff" ? (
                                    <span className="color-indicator" style={{ backgroundColor: pet.color.value, border: "1px solid #000"}} ></span>
                                ): (
                                    <span className="color-indicator" style={{ backgroundColor: pet.color.value}} ></span>     
                                )} 
                                <p className="dog-color" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>{pet.color.label}</p>
                            </div>

                            {/* AGE */}
                            <div className="info-row-container">
                                <CakeIcon sx={{ fontSize: "1.8rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}/>
                                <p className="age" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>{pet.age} години</p>
                            </div>

                            {/* Weight */}
                            <div className="info-row-container">
                                <FaWeightScale style={{ fontSize: "1.4rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", marginLeft: "0.2rem"}}/>
                                <p className="weight" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>{pet.weight + " кг"}</p>
                            </div>
                    </div>

                </div>

                {/* BOTTOM CONTAINER */}
                <div className="result_card-bottom_container">
                    {/* CHECKBOXES */}
                    <div>
                        <div className="checbox-container">
                            {pet.vaccinated === true ? (
                            <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.6rem" : "1.2vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                            ): (
                                <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.6rem" : "1.2vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                            )}
                            <p style={{width: "100%", fontSize: isMobile ? "1.3rem" : "1.3rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}} >Напълно ваксиниран(а)</p>
                        </div>

                        <div className="checbox-container">
                            {pet.original_breed === true ? (
                            <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.6rem" : "1.2vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                            ): (
                                <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.6rem" : "1.2vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                            )}
                            <p style={{width: "100%", fontSize: isMobile ? "1.3rem" : "1.3rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}>Потвърдено родословие</p>
                        </div>
                    </div>

                    {/* BUTTON */}
                    <div className="result_card-buttons" style={{ marginLeft: isMobile ? "5rem" : "3rem" }}>
                        
                        {(module === "adoption" && pet.adoptionFrom === "shelter") && (
                            <GiteOutlinedIcon style={{ fontSize: "2.5rem", color: "rgba(0,0,0,0.3)", marginLeft: "4rem", marginTop: "2rem" }}/>
                        )}

                        {(module === "adoption" && pet.adoptionFrom === "person") && (
                            <PermIdentityOutlinedIcon style={{ fontSize: "2.5rem", color: "rgba(0,0,0,0.3)", marginLeft: "4rem", marginTop: "2rem" }}/>
                        )}                        

                       {/*  <Tooltip 
                            title="Пиши на Собственика" 
                            arrow
                            slotProps={{
                                popper: {
                                    sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                            marginTop: '0.5rem',
                                            fontSize: '1.3rem',
                                            fontFamily: 'Ubuntu',
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            maxWidth: 220,
                                            backgroundColor: '#f5f5f9',
                                            border: '1px solid #dadde9',
                                        },
                                    },
                                },
                            }}
                            
                        >
                            <QuestionAnswerOutlinedIcon sx={{ color: "rgba(0,0,0,0.6)", fontSize: "2.5rem", cursor: "pointer"}}/>
                        </Tooltip> */}

                        {/* <Tooltip 
                            title="ВИЖ ПРОФИЛА" 
                            arrow
                            slotProps={{
                                popper: {
                                    sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                            marginTop: '0.5rem',
                                            fontSize: '1.3rem',
                                            fontFamily: 'Ubuntu',
                                            color: 'rgba(0, 0, 0, 0.87)',
                                            maxWidth: 220,
                                            backgroundColor: '#f5f5f9',
                                            border: '1px solid #dadde9',
                                        },
                                    },
                                },
                            }}
                            onClick={ ()=>{openPetProfile(pet)}}
                        >
                            <Button
                                sx={{ transition: "all 0.5s", width: "18%", padding: "0.5rem", fontFamily: "Ubuntu", fontSize: "1.3rem", color: "#fff", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 0.9)", transform: "scale(1.03)"} }}
                                variant="contained"
                            >
                                Повече
                            </Button>
                            <AccountCircleOutlinedIcon sx={{ 
                                    color: "rgba(0,0,0,0.6)", 
                                    fontSize: "2.5rem", 
                                    cursor: "pointer"
                                }} 
                                onClick={ ()=>{openPetProfile(pet)}}/>
                        </Tooltip> */}
                    </div>

                </div>
                
            </div>   
        </div>
    );
};

export default ResultCard;