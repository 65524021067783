import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import oidcService from "./services/oidcService";
import { LOGIN_THEME_RESOURCES_FROMkEYCLOAK_VERSION_DEFAULT } from "keycloakify/bin/shared/constants";
import { User } from "oidc-client-ts";

interface CallbackProps {
  moduleRedirect: any;
}

const CallbackPage: React.FC<CallbackProps> = ({moduleRedirect}) => {
  const navigate = useNavigate();

  useEffect(() => {
    oidcService
      .handleCallback()
      .then((user: any) => { // TODO: make sure that User is not of type any (use custom state object for the redirectPath)
        const path = user.state?.redirectPath;

        navigate(path);
      })
      .catch((error) => {
        console.error("Error handling login callback:", error);
      });
  }, [navigate]);

  return <div>Processing login...</div>;
};

export default CallbackPage;