import React, { useEffect, useState } from "react";
import "./home.css";
import { useAuth } from "react-oidc-context";
import { FaDog, FaPhotoVideo, FaSearchLocation  } from "react-icons/fa";
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { FaHandHolding } from "react-icons/fa6";
import { IoPaw } from "react-icons/io5";

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import { Navbar } from "../../navbar/Navbar";
import InfoPopUp from "./InfoPopUp";
import { Footer } from "../../landing/footer/Footer";
import { MdEditCalendar } from "react-icons/md";
import { useMediaQuery } from "@mui/material";
import ModuleSummaryPopUp from "./ModuleSummaryPopUp";
import { useSelector } from "react-redux";



export const Home: React.FC = () => {
  let user = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER_KEY || '') + '');
  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  const lightColor = "rgba(255,255,255,0.7)";
  const darkColor = "rgba(0,0,0,0.8)"; 
  
  const mode = useSelector((state: any) => state.theme.mode);

  const shelterImage = require("../../../images/house.png");
  const dogPartnerImage = require("../../../images/dog.png");

  let username = '';

  if(user != null || user != undefined) {
    if(user.profile.account_type === "Personal") {
      username = user.profile.given_name;
    }
    else if (user.profile.account_type === "Shelter") {
      username = user.profile.org_name;
    }
  }

  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const [showModuleSummaryPopUp, setShowModuleSummaryPopUp] = useState(false);

  let [moduleHovered, setModuleHovered] = useState(false);
  let [headerOpacity, setHeaderOpacity] = useState(1);


  let [moduleName, setModuleName] = useState('');
  let [moduleTitle, setModuleTitle] = useState('');
  let [moduleSummary, setModuleSummary] = useState('');

  let [moduleImage, setModuleImage] = useState(<div></div>);
 
  let modules = {
    partner:  {
      title: "Намерете партньор",
      summary: "Искате да намерите партньор за Вашия четириног приятел, но не успявате да откриете подходящ? Потърсете тук, прилагайки важните за Вас филтри. Когато откриете животно, което намирате за подходящо за Вашия домашен любимец, може да се свържете със собственика му, използвайки ‚чат‘ бутона.",
      image: <div className="module-summary-image-container">
              <FaDog style={{fontSize: isMobile ? "2rem" : "3rem", color: "rgb(95, 209, 171)", marginRight: "0.4rem"}}/>
              <FavoriteBorderOutlinedIcon sx={{ 
                position: "block", 
                // fontSize: !isMobile ? "1.7rem" : "1.5rem",
                margin: isMobile ? "0 -1rem" : "0 -0.5rem",
                transform: "scale(1)",
                color: "#B90F22",
                animation: "heart 1.5s ease-in-out infinite"
               }}/>
              <FaDog style={{fontSize: isMobile ? "2rem" : "3rem", color: "rgb(95, 209, 171)", transform: "rotateY(180deg)", marginLeft: "0.4rem"}}/>
            </div>
    },
    adoption: {
      title: "Осиновете приятел",
      summary: "Какво по-добро от това да спасите и осигурите дом за изоставено животинче? Тук може да откриете животни, които са готови да бъдат осиновени. За тях може да се грижат понастоящем както приюти и неправителствени организации, така и обикновени хора. Тази информация ще може да откриете в профила на животното, под „Собственик“.",
      image: <div className="module-summary-image-container" 
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "center", 
                    alignItems: "center",
                  }}>
              {/* <HouseOutlinedIcon style={{fontSize: "4rem", color: "#55c57a"}}/> */}
              <IoPaw style={{fontSize: isMobile ? "1.5rem" : "2.5rem", color: "rgb(95, 209, 171)", marginBottom: "-1.9rem"}}/>
              <FaHandHolding style={{fontSize: isMobile ? "2rem" : "3.5rem", color: "rgb(95, 209, 171)"}}/>
            </div>
    },
    forum: {
      title: "Форум",
      summary: "Заповядайте във Форума на PetConnect. В него можете да откриете полезна информация за Вашия домашен любимец, както и да задавате и отговаряте на различни въпроси.",
      image: <div className="module-summary-image-container">
              <MessageOutlinedIcon style={{fontSize: "4rem", color: "rgb(95, 209, 171)"}}/>
            </div>
    },
    services: {
      title: "Запази час",
      summary: "Омръзнало Ви е да звъните по различни кабинети и клиники, опитвайки се да запазите час в удобно за Вас време? изпитвате затруднения в намирането на най-подходящия специалист? Тук е решението на Вашите проблеми.",
      image: <div className="module-summary-image-container">
              <MdEditCalendar style={{fontSize: "4rem", color: "rgb(95, 209, 171)"}}/>
            </div>
    }
  }; 

  const showModuleDetails = (id: string)=> {
    setModuleHovered(true);

    if(id === "partner" || id === "adoption" || id === "forum" || id === "services") {
      setModuleName(id);
      setModuleTitle(modules[id].title);
      setModuleSummary(modules[id].summary);
      setModuleImage(modules[id].image);
    }
  }

  const hideModuleDetails = (e:React.MouseEvent)=> {
    setModuleHovered(false);
  }

  const handleInfoPopUp = function() {
    setShowInfoPopUp(true);
  }

  const openModule = (moduleName: string) => {
    if(isMobile) {
      setShowModuleSummaryPopUp(true);
    }
    else {
      const origin = window.location.origin;
      const newURL = origin + "/" + moduleName;
      window.location.href = newURL;
    }
  }

  window.addEventListener('scroll', () => {
    
    if (document.body.scrollTop <= 0) {
      let opacity = (window.scrollY / 200);
      if((headerOpacity - opacity) < 0) {
        setHeaderOpacity(0);
      }
      else {
        setHeaderOpacity(1 -opacity);
      }
    }
    else {
      let opacity = (window.scrollY / 200);
      if(headerOpacity + opacity > 1) {
        setHeaderOpacity(1);
      }
      else {
        let newOpacity = headerOpacity + opacity;
        setHeaderOpacity(newOpacity);
      }  
    }
  })

  return (
    <div className="home_container">
      <div id="homeBackground" className="solid_background" style={{ opacity: headerOpacity.toString() }}>
      </div>
      
      <div style={{ opacity: headerOpacity.toString(), zIndex: "200", position: "fixed", top: "0", left: "0", bottom: "0", right: "0"}}>
        <Navbar />
      </div>
     
      <div className="home-content">
        <InfoPopUp showInfoPopUp={showInfoPopUp} setShowInfoPopUp={setShowInfoPopUp} module={moduleTitle}/>
        <ModuleSummaryPopUp showInfoPopUp={showModuleSummaryPopUp} setShowInfoPopUp={setShowModuleSummaryPopUp} module={{name: moduleName, title: moduleTitle, summary: moduleSummary}}/>
        
        <div className="welcome_message_container"> 
          {!isMobile &&(
            <>
               {!moduleHovered && ( 
                  <div style={{ marginTop: "6rem", marginLeft: isMobile ? "0" : "3rem", textAlign: "center" }}>
                    <div className="showFromTop" style={{ marginBottom: "4rem" }}>
                      <p className="welcome_message" style={{ color: mode == "dark" ? lightColor : darkColor }}>Добре дошли,</p>
                      <p className="welcome_message-name">{username}</p>
                    </div>
                    
                    <p className="module-selection-message showFromBottom" style={{ color: mode == "dark" ? lightColor : darkColor }}>Изберете модул, който бихте искали да използвате</p>
                
                  </div> 
                )}
            </>
          )}   

          {isMobile &&(
            <>
              <div style={{ marginTop: "6rem", marginLeft: isMobile ? "0" : "3rem", textAlign: "center" }}>
                <div className="showFromTop" style={{ marginBottom: "4rem" }}>
                  <p className="welcome_message" style={{ color: mode == "dark" ? lightColor : darkColor }}>Добре дошли,</p>
                  <p className="welcome_message-name" >{username}</p>
                </div>
                
                <p className="module-selection-message showFromBottom" style={{ color: mode == "dark" ? lightColor : darkColor }}>Изберете модул, който бихте искали да използвате</p>
            
              </div> 
            </>
          )} 
         

          {(moduleHovered && !isMobile) &&( 
            <div className="module-details-container">
              {moduleImage}
              <p className="module-title big">{moduleTitle}</p>
              <p className="module-summary-text" style={{ color: mode == "dark" ? lightColor : darkColor }}>{moduleSummary}</p>
            </div>
          )} 
        </div>
   
            
        <div className="module-nodes-container">
          <div id="central-node-container">
            <div id="central-node">
              <div className="central-node-image-wrap">
                {!moduleHovered && (
                  <div>
                    <div className="central-node-image-glitch reveal" style={{ filter: "drop-shadow(0 0 5px rgb(95, 209, 171))" }}></div>
                    <div style={{ position: "absolute"}} className="module-summary hide">
                      <div>
                          <p className="module-title">{moduleTitle}</p>
                      </div>
                    </div>
                  </div>
                )}
                {moduleHovered && (
                  <div className="module-summary">
                    <div id="mask" className="hide"></div>
                    <div className="reveal">
                      <p className="module-title">{moduleTitle}</p>
                    </div>
      
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="module-container" style={{ right: "0.3rem"}}>
            <div 
              className={`module first ${mode === "dark" ? "light" : "dark"}`}
              onClick={()=> { openModule('partner') }} 
              onMouseEnter={()=> {showModuleDetails("partner")}} 
              onMouseLeave={hideModuleDetails}>
                <FaDog className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "3.5rem", marginRight: "0.3rem", color: mode === "dark" ? lightColor : darkColor}}/> 
                <FavoriteBorderOutlinedIcon sx={{ 
                position: "block", 
                // fontSize: !isMobile ? "1.7rem" : "1.5rem",
                margin: isMobile ? "0 -0.6rem" : "0 -0.5rem",
                marginTop: isMobile ? "0.5rem" : "",
                transform: "scale(1)",
                color: "#B90F22",
                animation: "heart 1.5s ease-in-out infinite"
               }}/>
                <FaDog className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "3.5rem", transform: "rotateY(180deg)", marginLeft: "0.3rem", color: mode === "dark" ? lightColor : darkColor}} />
            </div>
            <div className="connector-outter">
              <div className="connector-inner"></div>
            </div>
          </div>

          <div className="module-container" style={{ left: "30.5rem", bottom: "14.5rem"}}>
            <div className={`module second ${mode === "dark" ? "light" : "dark"}`} 
                  onMouseEnter={()=> {showModuleDetails("adoption")}} 
                  onMouseLeave={hideModuleDetails}
                  onClick={()=> { openModule('adoption') }}
                  style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    justifyContent: "center", 
                    alignItems: "center",
                  }}>
              {/* <HouseOutlinedIcon className="module-icon" style={{fontSize: "5.5rem"}}/>  */}
              <IoPaw className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "2.5rem", marginBottom: "-1.9rem",  color: mode === "dark" ? lightColor : darkColor}} />
              <FaHandHolding className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "3.5rem",  color: mode === "dark" ? lightColor : darkColor}} />
            </div>
            <div className="connector-outter">
              <div className="connector-inner"></div>
            </div>
          </div>

          <div className="module-container" style={{ left: "2.5rem", bottom: "14.5rem"}}>
            <div 
              className={`module third ${mode === "dark" ? "light" : "dark"}`} 
              onClick={handleInfoPopUp} 
              onMouseEnter={()=> {showModuleDetails("forum")}} 
              onMouseLeave={hideModuleDetails}>
                <MessageOutlinedIcon className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "5.5rem",  color: mode === "dark" ? lightColor : darkColor }}/> 
            </div>
            <div className="connector-outter">
              <div className="connector-inner"></div>
            </div>
          </div>

          <div className="module-container" style={{ left: "27.5rem", bottom: "29.5rem",  color: mode === "dark" ? lightColor : darkColor }}>
            <div 
              className={`module fourth ${mode === "dark" ? "light" : "dark"}`} 
              onClick={handleInfoPopUp} 
              onMouseEnter={()=> {showModuleDetails("services")}} 
              onMouseLeave={hideModuleDetails}>
                <MdEditCalendar className="module-icon" style={{animation: "glow 2s linear infinite", fontSize: "5.5rem",  color: mode === "dark" ? lightColor : darkColor }}/> 
            </div>
            <div className="connector-outter">
              <div className="connector-inner"></div>
            </div>
          </div>
        </div>
  
      </div>

      <div className="footer-container">
        <Footer theme={mode}/>
      </div>
    </div>
  );
};
