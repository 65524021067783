import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import "./features.css";

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { About } from '../about/About';

interface FeatureProps {
  setSelectedModule: (pageName: string) => void;
}

export const Feature: React.FC<FeatureProps> = ({setSelectedModule}) => {
  let dogs = require('../../../assets/dogs.png');
  let dog_icon = require('../../../assets/dog_icon2.png');
  let adoption_icon = require('../../../assets/animal-welfare.png');
  let chat_icon = require('../../../assets/chat_icon.png');
  let calendar_icon = require('../../../assets/schedule.png');

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          } else {
            entry.target.classList.remove("show");
          }
        });
      });

      const hiddenElements = document.querySelectorAll(".hidden_feature");
      hiddenElements.forEach((el) => observer.observe(el));
  },[]);

  const showFeatureMessage = () => {

  }

  const openModule = (moduleName: string) => {
    const origin = window.location.origin;
    const newURL = origin + "/" + moduleName;
    window.location.href = newURL;
  }

  return (
    <section className="section-features">
      <img src={dogs} style={{}} className="features_heading_img"/>
      <div className="features-heading">
        <span className="features_heading-txt">Защо да изберете нас</span>
      </div>
      <div className="row">
        {/* НАМЕРИ ПАРТНЬОР */}
        <div className="col-1-of-4 hidden_feature" onClick={showFeatureMessage}>
          <div className="feature-box" onClick={() => {
            ReactGA.event(
              {
                  category: "Landing",
                  action: "btn_partner_finder"
              }
            )

            openModule('partner');
          }}>
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <img src={dog_icon} className="feature_icon"/>
              {/* <FavoriteBorderOutlinedIcon sx={{ 
                position: "absolute", 
                top: "10%", 
                right: "47%", 
                fontSize: "2rem", 
                color: "#B90F22",
                animation: "heart 1.5s ease-in-out infinite"
               }}/> */}
              {/* <img src={dog_icon} style={{ width: "5vw", marginLeft: "0.5vw" }}/> */}
            </div>

            <h3 className="feature-main-text">Намерете лесно партньор на вашия любимец</h3>

            <p className="feature-sub-text">
              С помощта на модула <br/><span className="gradient_green">Намери партньор</span>
            </p>
          </div>
        </div>

        {/* ADOPTION */}
        <div className="col-1-of-4 hidden_feature" onClick={showFeatureMessage}>
          <div className="feature-box" onClick={() => {
            ReactGA.event(
              {
                  category: "Landing",
                  action: "btn_partner_finder"
              }
            )

            openModule('adoption');
          }}>
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <img src={adoption_icon} className="feature_icon"/>
            </div>

            <h3 className="feature-main-text">
              
              Искате да намерите нов приятел?
              <br/>
            </h3>

            <p className="feature-sub-text">
              Погледнете в модула <br/><span className="gradient_green">Осинови приятел</span>
            </p>
          </div>
        </div>

        {/* FORUM */}
        <div className="col-1-of-4 hidden_feature">
          <div className="feature-box" onClick={() => {
            ReactGA.event(
              {
                  category: "Landing",
                  action: "btn_forum"
              }
            )

            setSelectedModule('Форум');
          }}>
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <img src={chat_icon} className="feature_icon"/>
            </div>

            <h3 className="feature-main-text">Намерете отговори на въпроси за вашия любимец</h3>

            <p className="feature-sub-text">
              В темите на нашия <br/><span className="gradient_green">Форум</span>
            </p>
          </div>
        </div>

        {/* SOCIAL */}
        <div className="col-1-of-4 hidden_feature">
          <div className="feature-box" onClick={() => {
            ReactGA.event(
              {
                category: "Landing",
                action: "btn_social_media"
              }
            )
            setSelectedModule('Запази час');
          }}>
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <img src={calendar_icon} className="feature_icon"/>
            </div>

            <h3 className="feature-main-text">Запазете бързо и лесно час при подходящ специалист</h3>

            <p className="feature-sub-text">
             В услугата <br/><span className="gradient_green">Запази час</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
