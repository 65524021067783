import "./sassStyles/main.css";
import "./fonts.css";
import "./app.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import { themeSettings } from "./themes/themeSettings";

import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { Layout } from "./helpers/Layout";
//import { Register } from "./components/common/register/Register";
import { RegisterForm } from "./keycloak-theme/login/components/RegisterForm";

import { Home } from "./components/common/home/Home";
import { LandingV2 } from "./components/landing_new/LandingV2";
import { GDPR } from "./components/landing_new/GDPR/GDPR";
import { Stories } from "./components/landing/stories/Stories";
import { ContactForm } from "./components/landing_new/contacts/ContactForm";
import { HumpHome } from "./components/humpme/home/HumpHome";
import { AdoptionHome } from "./components/adoption/home/AdoptionHome";

import { CreateDogForm } from "./components/dogform/CreateDogForm";
import { Login } from "./components/common/login/LoginCustom";
import { UserProfile } from "./components/common/profile/user/UserProfile";

import { useEffect, useMemo } from "react";
import { useState } from "react";

import { useTypedSelectorTheme } from "./features/themeSlice";

import ReactGA from 'react-ga4';
import ProtectedRoute from "./components/common/ProtectedRoute";
import CallbackPage from "./auth/CallbackPage";
import oidcService from "./auth/services/oidcService";

const App = () => {
  ReactGA.initialize('G-NSKBQ6D705');

  const mode = useTypedSelectorTheme((state) => state.theme.mode) as
    | "light"
    | "dark";
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [moduleRedirect, setModuleRedirect] = useState('home');

  const handleSelectedModuleRedirect = function(module: any) {
    setModuleRedirect(module);
  }

  const [isAuthenticated, setIsAuthenticated] = useState(false);

    const checkUser = async () => {
        const user = await oidcService.getUser();

        setIsAuthenticated(!!user); // Set true if user is authenticated, false otherwise
    };

    useEffect(() => {
        checkUser();
    }, [isAuthenticated]);

  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/auth-callback" element={<CallbackPage moduleRedirect={moduleRedirect}/>} />

                {!isAuthenticated && (
                  <Route path="/" element={<LandingV2 />} />
                )}

                <Route path="/dogform" element={<CreateDogForm />} />
                <Route path="/profile" element={<UserProfile />} />

                {isAuthenticated && (
                  <Route path="/" element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>} 
                  />
                )}

                <Route path="/home" element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>} 
                />
                
                <Route path="/partner" element={
                  <ProtectedRoute>
                    <HumpHome />
                  </ProtectedRoute>} 
                />
                <Route path="/adoption" element={
                  <ProtectedRoute>
                    <AdoptionHome />
                  </ProtectedRoute>} 
                />
                
                <Route path="/gdpr" element={<GDPR />} />
                <Route path="/team" element={<Stories />} />
                <Route path="/contact_us" element={<ContactForm />} />
              </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </>

  );
};

export default App;
