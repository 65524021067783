import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import "./toggle.css";


const IOSSwitch = styled((props: SwitchProps, checked: boolean) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} defaultChecked={checked}/>
))(({ theme }) => ({
    width: 38,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
        backgroundColor: '#28b485',
        opacity: 1,
        border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
        },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        
    },
    '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        
    },
    },
    '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
    },
    '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
        duration: 500,
    }),
    
    },
}));

export default function Toggle({label, checked, setChecked, name, handleInfoPopUp, setToggleAction}: any) {
    return (
        <FormControlLabel
            control={<IOSSwitch sx={{ m: "0.3vw", color: "rgba(255, 255, 255, 0.5)" }} checked={checked} />}
            label={label}
            onChange={(e) => {
                var newValue = !checked;
                if(newValue == true){
                    setToggleAction("add");
                }else {
                    setToggleAction("false");
                }
                setChecked((data: any) => ({
                    ...data,
                    [name]: newValue,
                }));
                handleInfoPopUp({label: label, name: name});
            }}
        />
    );
}