import "./profileSummary.css";
import React, { useState } from "react";

import { ThemeProvider, createTheme  } from '@mui/material/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import { FaUserEdit } from "react-icons/fa";
import { BiMessageSquareDetail } from "react-icons/bi";

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import FileUpload from "./FileUpload";
import FileUpdate from './FileUpdate';
import Modal from "../../modal/Modal";

export default function ProfileSummary ({handleModal, mode}: any) {
    const [profileImage, setProfileImage] = useState("");

    let user = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER_KEY || '') + '');
    const chat_url = process.env.REACT_APP_CHAT_URL || "";
    const chat_id = process.env.REACT_APP_CHAT_ID || "";

    const profile_upload_styles = {
        fontSize: "4vw", 
        color: mode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0,0,0,0.2)",  
        cursor: "pointer",
        "&:hover": { color: "rgba(40, 180, 133, 0.7)" }
    }

    const profile_update_styles = {
        width: "8vw",
        height: "8vw",
        marginBottom: "-0.7vw",
        marginLeft: "-2.4rem",
        
    }

    return (
        <div className="profile-summary-cont">

            {profileImage && typeof profileImage !== 'string' ? (
                <FileUpdate styles={ profile_update_styles } image={ profileImage } setImage={ setProfileImage }></FileUpdate>
                ): (
                <div 
                    className="profile-upload-cont" 
                    style={{ 
                        backgroundColor: mode === "dark" ? "rgba(40, 40, 40, 1)" : "#EDEDED",
                    }}>
                    <FileUpload styles={ profile_upload_styles } image={ profileImage } setImage={ setProfileImage }></FileUpload>
                </div>
            )}

            <div className="user-details">
                <div className="top-details">
                    <p className="user-name" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>
                        {(user != undefined && user.profile.account_type === "Personal") && (
                            <>
                                {user.profile.given_name} {user.profile.family_name}
                            </>
                        )}

                        {(user != undefined && user.profile.account_type === "Shelter") && (
                            <>
                                {user.profile.org_name}
                            </>
                        )}

                        {(user == undefined) && (
                            <>
                                
                            </>
                        )}
                    </p>
                    <div className="actions">
                        {/* <Tooltip 
                            title="Профил" 
                            arrow
                            slotProps={{
                            popper: {
                                sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                    marginTop: '0.5rem',
                                    fontSize: '1.4rem',
                                    fontFamily: 'Adys Bold',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    maxWidth: 220,
                                    backgroundColor: '#f5f5f9',
                                    border: '1px solid #dadde9',
                                    },
                                },
                            },
                            }}
                        >
                            <div className="action-cont">
                                <PersonAddAltOutlinedIcon className="action-icon" sx={{ fontSize: "1.8vw", color: "#fff"}}></PersonAddAltOutlinedIcon>
                            </div>
                        </Tooltip> */}

                        <Tooltip 
                            title="Започни чат" 
                            arrow
                            slotProps={{
                            popper: {
                                sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                    marginTop: '0.5rem',
                                    fontSize: '1.4rem',
                                    fontFamily: 'Adys Bold',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    maxWidth: 220,
                                    backgroundColor: '#f5f5f9',
                                    border: '1px solid #dadde9',
                                    },
                                },
                            },
                            }}
                        >
                            <div className="action-cont">
                                <BiMessageSquareDetail className="action-icon" style={{ color: mode === "dark" ? "#fff" : "rgba(0,0,0,0.8)" }} onClick={() => {
                                    const rocketChatUrl = chat_url.concat(`/direct/${chat_id}`);
                                    const newWindow = window.open(rocketChatUrl, '_blank');
                                }}/>
                            </div>
                        </Tooltip>
                            
                        <Tooltip 
                            title="Редактиране" 
                            arrow
                            slotProps={{
                                popper: {
                                    sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                        marginTop: '0.5rem',
                                        fontSize: '1.4rem',
                                        fontFamily: 'Adys Bold',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        maxWidth: 220,
                                        backgroundColor: '#f5f5f9',
                                        border: '1px solid #dadde9',
                                        },
                                    },
                                },
                            }}
                            onClick={handleModal}
                        >
                            <div className="action-cont">
                                <FaUserEdit className="action-icon" style={{ color: mode === "dark" ? "#fff" : "rgba(0,0,0,0.8)" }}/>
                            </div>
                        </Tooltip>            
                    </div>
                </div>
                <p className="user-address" style={{color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>{user.profile.city}, {user.profile.district}</p>
            </div>

            {/* <ClearOutlinedIcon sx={{ fontSize: "2.3vw", ml: "5vw", mt: "1vw", cursor: "pointer"}}></ClearOutlinedIcon> */}
        </div>
    );
};
