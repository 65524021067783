import React, { useEffect, useState } from 'react';
import "./navbar.css";
import {
  IconButton,
  InputBase,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";

import AccountMenu from "./AccountMenu";
import { NotificationsMenu } from "./notificationsMenu/NotificationsMenu";
import { ChatMenu } from "./chatMenu/ChatMenu";

import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import FlexBetween from "../FlexBetween";
import oidcService from "../../auth/services/oidcService";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

export const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkUser = async () => {
      const user = await oidcService.getUser();

      setIsAuthenticated(!!user); // Set true if user is authenticated, false otherwise
  };

  useEffect(() => {
      checkUser();
  }, [isAuthenticated]);

  const mode = useSelector((state: any) => state.theme.mode);

  const lightColor = "rgba(255,255,255, 1)";
  const darkColor = "rgba(0,0,0,0.8)"; 

  let user = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER_KEY || '') + '');
  const theme = useTheme();

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = width <= 600;

  const openPage = (page: string) => {
    const origin = window.location.origin;
    const newURL = origin + page;
    window.location.href = newURL;
  }

  const handleLogin = () => {
    oidcService.login('/home'); // Redirect to Keycloak login page
}

  return (
    <>
      <FlexBetween
        padding={ isMobile ? "0.5rem 2%" : "1rem 2%"}
        width="100vw"
      >
        {/* LOGO + NAME */}
        <div className="home_glitch_wrap" style={{zIndex: "2000"}} onClick={()=> {openPage('')}}>
            <div className={`${mode === "dark" ? "home_glitch-dark" : "home_glitch-light"} home_header_logo `}></div>
        </div>

        {!isMobile && (
          <div className="home_glitch-text_wrap" style={{ marginLeft: "-10rem" }}>
            <p data-text="PetConnect" className={`${mode === "dark" ? "home_glitch-text-dark fast-anim" : "home_glitch-text-light fast-anim"}`}>
              PetConnect
              <span data-text="beta" className={`${mode === "dark" ? "beta-text-dark" : "beta-text-light"}`}>beta</span>
            </p>
          </div>
        )}

        {!isMobile && (
            <div className="nav-search-container">
            <FlexBetween
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1rem"
              sx={{
                backgroundColor:
                  theme.palette.mode == "light" ? "#F0F2F5" : "#3A3B3C",
                boxShadow: "inset 1px 1px 2px 1px rgba(0,0,0,0.5)"
              }}
            >
              <InputBase placeholder="Търсене" style={{ fontFamily: "Adys Regular, sans-serif", fontWeight: "400" }}/>
              <IconButton>
                <Search sx={{ fontSize:"2.2rem" }}/>
              </IconButton>
            </FlexBetween>
            </div>
        )}

        {!isMobile && (
          <FlexBetween gap="4rem">
            <div className="navigation-item" onClick={() => {openPage('/team')}}>
                <PeopleAltOutlinedIcon className="login-icon" sx={{ color: mode === "dark" ? darkColor : lightColor, fontSize: "2vw", marginRight: "0.5rem", marginBottom: "0.5rem", transition: "all 0.3s"}}/>
                <p style={{ fontFamily: "Adys Bold, sans-serif", fontSize: "1.5vw", transition: "all 0.3s", color: mode === "dark" ? darkColor : lightColor}}>За нас</p>
            </div>
            <div className="navigation-item" onClick={() => {openPage('/contact_us')}}>
                <ForwardToInboxOutlinedIcon className="login-icon" sx={{ color: mode === "dark" ? darkColor : lightColor, fontSize: "2vw", marginRight: "0.5rem", marginBottom: "0.5rem", transition: "all 0.3s"}}/>
                <p style={{ fontFamily: "Adys Bold, sans-serif", fontSize: "1.5vw", transition: "all 0.3s", color: mode === "dark" ? darkColor : lightColor }}>Контакти</p>
            </div>
            {/* <span className="home_nav-text" onClick={() => {openPage('/team')}}>За нас</span>
            <span className="home_nav-text" onClick={() => {openPage('/contact_us')}}>Контакти</span> */}
          </FlexBetween>
        )}

       {isAuthenticated == true ? (
          <FlexBetween gap="2rem">
            <NotificationsMenu mode={mode}/>
            <ChatMenu mode={mode}/>
            <AccountMenu user={user} isMobile={isMobile}/>
          </FlexBetween>
        ): (
          <>
            {!isMobile ? (
              <>
                <FlexBetween gap="2rem">
                  <NotificationsMenu mode={mode}/>
                  <ChatMenu mode={mode}/>
                </FlexBetween>
                <div className="navigation-item" style={{ marginLeft: "-8rem"}} onClick={ () => {handleLogin()}}>
                  <LoginOutlinedIcon 
                    className="login-icon" 
                    sx={{ 
                      color: mode === "dark" ? "rgba(0,0,0,0.8)" : "#fff", 
                      fontSize: "2vw", 
                      marginRight: "0.5rem", 
                      marginBottom: "0.5rem", 
                      transition: "all 0.3s"
                    }}
                  />
                  <p 
                    style={{ 
                      color: mode === "dark" ? "rgba(0,0,0,0.8)" : "#fff",
                      fontFamily: "Adys Bold, sans-serif", 
                      fontSize: "1.5vw", 
                      transition: "all 0.3s" 
                    }} >
                      Вход
                    </p>
                </div>
              </>
            ):(
              <div className="mobile-menu-cont" style={{zIndex: "1000"}}>
                  <Tooltip title="Account settings">
                      <IconButton
                      onClick={handleClick}
                      size="small"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      >
                          <MenuOutlinedIcon sx={{ color: "#fff", fontSize: "3rem" }} />
                      </IconButton>
                  </Tooltip>

                  <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                          elevation: 0,
                          sx: {
                              zIndex: "2000 !important",
                              overflow: "auto",
                              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 0,
                              ml: -2,
                              "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                              }
                          },
                      }}

                  >
                      <MenuItem onClick={ () => {openPage('team')}}>
                          <PeopleAltOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                          <Typography>Нашият екип</Typography> 
                      </MenuItem>

                      <MenuItem onClick={ () => {openPage('contact_us')}}>
                          <ForwardToInboxOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                          <Typography>Контакти</Typography>
                      </MenuItem>
                      
                      <MenuItem onClick={ () => {handleLogin()}}>
                          <LoginOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                          <Typography>Вход</Typography>
                      </MenuItem>

                  </Menu>
                  
              </div>
            )}
          </> 
        )}
        
      </FlexBetween>
    </>
  );
};
