import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Stack from '@mui/material/Stack';

import { multiFilter } from "../../../data/mui_styles"

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";

import breeds from "../../../data/breeds";

type DogBreedSearchDropdownProps = {
  selectedType: string;
  setBreed: (breed: any) => void;
  filters: any;
  isMobile: any;
  width: any;
};

interface DogBreed {
  label: string;
}

const fetchRequestMock = (duration: number): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10%",
    },
  },
};

export const DogBreedSearchDropdown: React.FC<DogBreedSearchDropdownProps> = ({
  selectedType, setBreed, filters, isMobile, width
}) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = React.useState<string[]>([]);

  let filteredBreeds = selectedType != '' ? breeds.filter(
    (breed) => breed.type == selectedType
    
  ) || []: [];

  const breedsObj = {
    title: "Порода",
    options: filteredBreeds
  }
  
  const customStyles = {
    width: !isMobile ? 250 : width
  }

  const options = breedsObj.options.map((option: any) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });
  const title = breedsObj.title || '';

  const newTheme = (theme: any) => createTheme({
      ...theme,
      components: !isMobile === true ? multiFilter.web : multiFilter.mobile 
  });  

  useEffect(() => {
    if(filters.breed == '')
      setValues([]);
  }, [filters.breed]);

  return (
    <Stack sx={{ width: customStyles.width || 250 }}>
        <ThemeProvider theme={newTheme}>
            <Autocomplete
                multiple
                limitTags={1}
                {...autocompleteOptions}
                id="size-small-outlined-multi"
                size="small"
                options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                value={values}
                getOptionLabel={(option: any) => option.title}
                sx={!isMobile ? getAutocompleteStyles("#52af77") : getAutocompleteStylesMobile("#52af77")}
                onChange={(e, v) => {
                  if(v.length > 0){
                    let breeds = '';
                    for(let i = 0; i < v.length; i ++) {
                      breeds += v[i].title + ',';
                    }

                    setBreed(breeds);
                    setValues(v);
                  }
                  else {
                    setBreed('');
                  }
                }}
                
                renderInput={(params) => (
                    <TextField {...params} 
                        label={title}
                        placeholder={title}
                        onChange={(target) => {
                            // setValue(event.target.value);
                        }}
                        sx={{backgroundColor: "#fff"}}/>
                    )}
            />
        </ThemeProvider>
    </Stack>
  );
};
