import * as React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { getAutocompleteStylesMobile } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";

import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import { Box } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";

import { singleFilter } from "../../../data/mui_styles"

type singleFilterProps = {
    filterOptions: {
        title: string,
        options: any
    };
    setPetType: (type: string) => void;
    filters: any;
    isMobile: any;
    width: any
};

export const SingleSelectFilter: React.FC<singleFilterProps> = ({filterOptions, setPetType, filters, isMobile, width}) => {
   
    const options = filterOptions.options || [];
    const title = filterOptions.title || '';

    const newTheme = (theme: any) => createTheme({
        ...theme,
        components: !isMobile === true ? singleFilter.web : singleFilter.mobile 
    });

    return (
        <Stack sx={{ width: width }}>
            <ThemeProvider theme={newTheme}>
                <Autocomplete
                    limitTags={2}
                    {...autocompleteOptions}
                    id="size-small-outlined-multi"
                    size="small"
                    options={options}
                    getOptionLabel={(option: any) => {
                        if(filters.sex == '')
                            return ''
                        else
                            return option.title
                    }}
                    sx={!isMobile ? getAutocompleteStyles("#52af77") : getAutocompleteStylesMobile("#52af77")}
                    onChange={(e, v) => {
                        if(v){
                            setPetType(v.title); 
                        }
                        else {
                            setPetType(''); 
                        }
                        
                    }}
                    renderInput={(params) => (
                        <TextField {...params} 
                            label={title}
                            placeholder={title} 
                            sx={{backgroundColor: "#fff"}}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {params.inputProps.value !== '' ? (
                                            <>
                                                {params.inputProps.value === "Мъжки" ? (
                                                    <InputAdornment position="start">
                                                        <MaleIcon sx={{ fontSize: !isMobile ? "2.2rem" : "1.5rem", marginRight: !isMobile ? "0" : "-0.5rem" }}/>
                                                    </InputAdornment>
                                                ): (<InputAdornment position="start">
                                                        <FemaleIcon sx={{ fontSize: !isMobile ? "2.2rem" : "1.5rem", marginRight: !isMobile ? "0" : "-0.5rem" }}/>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ): (<></>)}
                                           
                                    </>
                                ),
                            }}
                        />
                    )} 
    
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            {option.title === "Мъжки" ? (
                                <MaleIcon sx={{ color: "rgb(95, 209, 171)", fontSize: !isMobile ? "2rem" : "1.5rem" }} />
                            ) : (
                                <FemaleIcon sx={{ color: "rgb(95, 209, 171)", fontSize: !isMobile ? "2rem" : "1.5rem" }} />
                            )}
                            <div style={{marginLeft:"5px"}}>{option.title}</div>
                        </Box>
                    )} 
                />
            </ThemeProvider>
        </Stack>
    );
}
