import { UserManagerSettings } from "oidc-client-ts";

const oidcConfig: UserManagerSettings = {
  authority: `${process.env.REACT_APP_KEYCLOAK_URL}/realms/PetConnect`, // Keycloak realm URL
  client_id: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`, // Client ID from Keycloak
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/auth-callback`, // Callback URL in Keycloak
  post_logout_redirect_uri: `${process.env.REACT_APP_BASE_URL}/`, // Logout redirect URL
  response_type: "code", // Authorization Code Flow
  response_mode: "query",
  scope: "openid", // Scopes requested from Keycloak
};

export default oidcConfig;