export const singleFilter = {
    web: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    
                },
                tag: {
                    fontSize: "1rem"
                }, 
                option: {
                    fontFamily: "Adys Regular",
                    fontWeight: "400",
                    
                },
                inputRoot: {
                    
                },
                inputFocused: {
                    borderColor: "rgb(95, 209, 171)"
                },
                input: {
                    fontFamily: "Adys Regular",
                    fontWeight: "400",
                    fontSize: "1.7rem",
                    
                },
                listbox: {
                    background: "rgba(255,255,255, 0.7)",
                },
                paper: {
                    background: "rgba(255,255,255, 0.7)",
                },
                popper: {
                    background: "rgba(255,255,255, 0.7)",
                }
            }
        }
    },

    mobile: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    
                },
                tag: {
                    fontSize: "0.6rem"
                }, 
                option: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    
                },
                inputRoot: {
                    
                },
                inputFocused: {
                    borderColor: "#f5f5f5"
                },
                input: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    fontSize: "1.2rem",
                    
                },
                listbox: {
                    background: "rgba(255,255,255, 0.7)",
                },
                paper: {
                    background: "rgba(255,255,255, 0.7)",
                },
                popper: {
                    background: "rgba(255,255,255, 0.7)",
                }
            }
        }
    }
}

export const multiFilter = {
    web: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    
                },
                tag: {
                    fontSize: "1rem"
                }, 
                option: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    
                },
                inputRoot: {
                    
                },
                inputFocused: {
                    borderColor: "#f5f5f5"
                },
                input: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    fontSize: "1.7rem",
                    
                },
                listbox: {
                    background: "rgba(255,255,255, 0.7)",
                },
                paper: {
                    background: "rgba(255,255,255, 0.7)",
                },
                popper: {
                    background: "rgba(255,255,255, 0.7)",
                }
            }
        }
    },

    mobile: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    
                },
                tag: {
                    fontSize: "0.8rem",
                    padding: "0rem"
                }, 
                option: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    
                },
                inputRoot: {
                    
                },
                inputFocused: {
                    borderColor: "#f5f5f5"
                },
                input: {
                    fontFamily: "Ubuntu",
                    fontWeight: "400",
                    fontSize: "1.2rem",
                    
                },
                listbox: {
                    background: "rgba(255,255,255, 0.7)",
                },
                paper: {
                    background: "rgba(255,255,255, 0.7)",
                },
                popper: {
                    background: "rgba(255,255,255, 0.7)",
                }
            }
        }
    }
}

export const sliderFilter = {
    web: {
        MuiSlider: {
          styleOverrides: {
            track: {
              border: 'none',
            },
            thumb: {
              height: 24,
              width: 24,
              backgroundColor: '#fff',
              border: '2px solid currentColor',
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
              },
              '&::before': {
                display: 'none',
              },
            }
          }
        }
    },

    mobile: {
        MuiSlider: {
            styleOverrides: {
                track: {
                border: 'none',
                },
                thumb: {
                    height: 15,
                    width: 15,
                    backgroundColor: '#fff',
                    border: '2px solid currentColor',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'inherit',
                    },
                    '&::before': {
                        display: 'none',
                    },
                }
            }
        }
    }
}