import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./pagination.css";

export default function PaginationOutlined({data, itemsPerPage, setPage}: any) {

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontFamily: "Ubuntu",
            fontSize: "2rem" 
          },
          icon: {
            fontSize: "2rem"
          }
        }
      }
    }   
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };

  return (
    <Stack spacing={2}>
      <ThemeProvider theme={newTheme}>
        <Pagination 
          count={Math.ceil(data.length/itemsPerPage)}
          boundaryCount={1} 
          size="large" 
          variant="outlined" 
          color="primary" 
          showFirstButton 
          showLastButton
          onChange={handleChange}
          renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                
              />
          )}
        />
      </ThemeProvider>
    </Stack>
  );
}