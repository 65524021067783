import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@mui/material/FormControl";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { transform } from 'cypress/types/lodash';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getSingleAutocompleteStyles } from '../humpme/filters/autoCompleteHelper';


export default function BasicDatePicker() {

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: {
      MuiStack: {
        styleOverrides: {
          root: {
            paddingTop: "0px !important"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: "1.3vw",
            fontWeight: "400",
            borderColor: "#28b485 !important",
            "&.Mui-focused": {
              "root": {
                "borderColor": "#28b485 !important"
              }
            }
          },
          input: {
            fontFamily: "Adys Regular, sans-serif",
            fontWeight: "400",
            fontSize: "1.3vw",
            borderColor: "#28b485 !important"
          },
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Adys Regular, sans-serif",
            fontSize: "1.3vw",
          },
          shrink: {
            fontFamily: "Adys Bold, sans-serif",
            fontSize: "1.3vw",
            color: "#28b485"
          }
        }
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Adys Regular",
            color: 'rgba(0,0,0,0.8)',
            borderRadius: '50%',
            borderWidth: '2px',
            borderColor: 'rgba(0,0,0,0.8)',
            border: '1.5px solid',
            backgroundColor: '#fff',
            fontSize: "1.3vw",
            "&:hover": {
              backgroundColor: '#d4ffdc'
            }
          },
          today: {
            backgroundColor: '#a3ffb4'
          }
        }
      },
    
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            color: '#65C466',
            borderRadius: '2px',
            borderWidth: '1px',
            backgroundColor: '#fff'
          },
          label: {
            fontFamily: "Adys Regular",
            color: 'rgba(0,0,0,0.8)',
            fontSize: "1.3vw",
          },
          switchViewButton: {
            transform: 'scale(1.4)'
          }
        }
      },

      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            fontFamily: "Adys Regular",
            color: 'rgba(0,0,0,0.8)',
            backgroundColor: '#fff',
            fontSize: "1.3vw",
          }
        }
      },

      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            fontFamily: "Adys Regular",
            fontSize: 18
          }
        }
      },

      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            fontSize: '14px'
          }
        }
      },

      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '26px',
            '&:hover': {
              color: '#65C466'
            }
          }
        }
      }
    }
  })

  return (
    <FormControl size="medium" sx={{width: '100%', zIndex: "10", marginTop: "-8px"}}>
      <LocalizationProvider  dateAdapter={AdapterDayjs}>
          <DemoContainer  components={['DatePicker']} sx={getSingleAutocompleteStyles("#28b485")} >
            <ThemeProvider theme={newTheme}>
              <DesktopDatePicker 
                label="Дата на раждане"
                sx={getSingleAutocompleteStyles("#28b485")}
                />
            </ThemeProvider>
              
          </DemoContainer>
      </LocalizationProvider>
    </FormControl>
  );
}