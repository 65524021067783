import React from "react";
import { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import "./header.css";
import { useTranslation } from "react-i18next";

import { NavLink as Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AccountMenu from "../../navbar/AccountMenu";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import oidcService from "../../../auth/services/oidcService";
import ThemeSwitch from "../../common/themes/ThemeSwitch";

const scrollToSection = (sectionId: string): void => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: "smooth",
      });
    }
};

export const Header: React.FC = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    let video = require("../../../assets/DogVideo.webm");

    const handleLogin = () => {
        oidcService.login('/home'); // Redirect to Keycloak login page
    }

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const checkUser = async () => {
        const user = await oidcService.getUser();

        setIsAuthenticated(!!user); // Set true if user is authenticated, false otherwise
    };

    useEffect(() => {
        checkUser();
    }, [isAuthenticated]);

    const openPage = (pageName: string) => {
        const origin = window.location.origin;
        const newURL = origin + "/" + pageName;
        window.location.href = newURL;
    }

    return (
        <header className="header cont-row">
             <div className="bg-video">
                <video className="bg-video__content" autoPlay muted loop>
                <source src={video} type="video/webm"></source>
                Your Browser is not supported!
                </video>
            </div>
            <div  className="header-nav">

                <div className="text-logo-cont">
                    <div className="glitch_wrap">
                        <div className="header__logo glitch"></div>
                    </div>
                    <div className="glitch-text_wrap" style={{ marginLeft: "-2vw"}}>
                        <p data-text="PetConnect" className="glitch-text fast-anim">PetConnect</p>
                    </div>
                </div>
                

                {/* {!isMobile && (
                    <ThemeSwitch />
                    )}
                */}
                {!isMobile && (
                    <div className="header-nav-links">

                        <div className="navigation-item" onClick={ () => {openPage('team')}}>
                            <PeopleAltOutlinedIcon className="header_nav_icon" sx={{ color: "#fff", fontSize: "2.5rem", marginRight: "0.5rem", marginBottom: "0.5rem" }}/>
                            <p>Нашият екип</p>
                        </div>
                        <div className="navigation-item" onClick={ () => {openPage('contact_us')}}>
                            <ForwardToInboxOutlinedIcon className="header_nav_icon" sx={{ color: "#fff", fontSize: "2.5rem", marginRight: "0.5rem", marginBottom: "0.5rem" }}/>
                            <p>Контакти</p>
                        </div>

                        <div className="navigation-item" onClick={ () => {handleLogin()}}>
                            <LoginOutlinedIcon className="header_nav_icon" sx={{ color: "#fff", fontSize: "2.5rem", marginRight: "0.5rem", marginBottom: "0.5rem" }}/>
                            <p>Вход</p>
                        </div>
                    </div>
                )}

                {isMobile && (
                    <div className="mobile-menu-cont">
                        <Tooltip title="Account settings">
                            <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            >
                                <MenuOutlinedIcon sx={{ color: "#fff", fontSize: "3rem" }} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "auto",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 0,
                                    ml: -2,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    }
                                },
                            }}

                        >
                            <MenuItem onClick={ () => {openPage('team')}}>
                                <PeopleAltOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                                <Typography>Нашият екип</Typography> 
                            </MenuItem>

                            <MenuItem onClick={ () => {openPage('contact_us')}}>
                                <ForwardToInboxOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                                <Typography>Контакти</Typography>
                            </MenuItem>
                            
                            <MenuItem onClick={ () => {handleLogin()}}>
                                <LoginOutlinedIcon sx={{ color: "rgba(0,0,0,0.7)", fontSize: "1.5rem", marginRight: "0.5rem"}}/>
                                <Typography>Вход</Typography>
                            </MenuItem>
        
                        </Menu>
                        
                    </div>
                )}

            </div>

            <div className="header__text-box">
                <h1 className="heading-primary">

                    <div className="header-main-text-cont">
                        <p className="header_main_text">Една платформа за всичко,</p>
                    </div>
        
                    <p className="header_sub_text">от което се нуждае Вашият любимец</p>
                </h1>

                <div className="arrow-indicators-container">
                    <ExpandMoreIcon className="arrow-icon moveDown" sx={{ fontSize: "7vw", color: "#fff", marginTop: "-5vw" }}/>
                </div>
            </div>
        </header>
    )
}