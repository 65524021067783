import "./editProfile.css";

import React, { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveIcon from '@mui/icons-material/Save';
import { autocompleteOptions, getSingleAutocompleteStyles, getTextFieldStyles } from "../../../humpme/filters/autoCompleteHelper";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FaUserEdit } from "react-icons/fa";

import districts from "../../../../data/districts";
import cities from "../../../../data/cities";

export const EditProfile = ({showModal, setShowModal, isMobile}: any): JSX.Element => {
    let user = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_STORAGE_USER_KEY || '') + '');

    const [selectedDistrict, setSelectedDistrict] = useState(
        {
            title: "",
            districtId: "",
            firstLetter: ""
        }
    );

    const [city, setCity] = useState({
        title: '',
        cityId: '',
        regionId: '',
        firstLetter: ''
    });

    let districtId = districts.options.filter(
        (d) => d.title === user.profile.district) || [];

    let cityId = cities.options.filter(
        (c) => c.title === user.profile.city) || [];

    const [personalInfo, setPersonalInfo] = useState({
        firstName: user.profile.given_name + "",
        lastName: user.profile.family_name + "",
        district: {
            title: user.profile.district,
            districtId: districtId[0].districtId,
            firstLetter: user.profile.district.split("")[0]
        },
        city: {
            title: user.profile.city,
            cityId: cityId[0].cityId,
            regionId: districtId[0].districtId,
            firstLetter: user.profile.city.split("")[0]
        },
        code: "",
        phone: "",
    });

    const districtOptions = districts.options.map((option: any) => {
        const firstLetter = option.title[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });
    
    let citiesArray = cities.options;
    
    let filteredCities = personalInfo.district.districtId != '' ? citiesArray.filter(
        (city) => personalInfo.district.districtId == city.regionId
        
        ) || []: [];
    
    const cityObj = filteredCities.map((option: any) => {
        const firstLetter = option.title[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    // PHONE CODES
    interface CountryType {
        code: string;
        label: string;
        phone: string;
        suggested?: boolean;
    }

    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
    const countries: readonly CountryType[] = [     
    { code: 'AL', label: 'Албания', phone: '+355' },
    { code: 'AT', label: 'Австрия', phone: '+43' },
    { code: 'BA', label: 'Босна и Херцеговина', phone: '+387'},
    { code: 'BE', label: 'Белгия', phone: '+32' },
    { code: 'BG', label: 'България', phone: '+359' },
    { code: 'BY', label: 'Беларус', phone: '+375' },
    { code: 'CA', label: 'Канада',  phone: '+1', suggested: true,},
    { code: 'CH', label: 'Швейцария', phone: '+41' },
    { code: 'CY', label: 'Кипър', phone: '+357' },
    { code: 'CZ', label: 'Чехия', phone: '+420' },
    { code: 'DE', label: 'Германия', phone: '+49', suggested: true},
    { code: 'DK', label: 'Дания', phone: '+45' },
    { code: 'ES', label: 'Испания', phone: '+34' },
    { code: 'FI', label: 'Финландия', phone: '+358' },
    { code: 'FR', label: 'Франция', phone: '+33', suggested: true},
    { code: 'GB', label: 'Англия', phone: '+44' },
    { code: 'GR', label: 'Гърция', phone: '+30' },
    { code: 'HR', label: 'Хърватия', phone: '+385' },
    { code: 'HU', label: 'Унгария', phone: '+36' },
    { code: 'IE', label: 'Ирландия', phone: '+353' },
    { code: 'IT', label: 'Италия', phone: '+39' },
    { code: 'MK', label: 'Македония', phone: '+389',},
    { code: 'NL', label: 'Холандия', phone: '+31' },
    { code: 'NO', label: 'Норвегия', phone: '+47' },
    { code: 'PL', label: 'Полша', phone: '+48' },
    { code: 'PT', label: 'Португалия', phone: '+351' },
    { code: 'RO', label: 'Румъния', phone: '+40' },
    { code: 'RS', label: 'Сърбия', phone: '+381' },
    { code: 'RU', label: 'Русия', phone: '+7' },
    { code: 'SE', label: 'Швеция', phone: '+46' },
    { code: 'SI', label: 'Словения', phone: '+386' },
    { code: 'SK', label: 'Словакия', phone: '+421' },  
    { code: 'TR', label: 'Турция', phone: '+90' },
    { code: 'UA', label: 'Украйна', phone: '+380' },
    { code: 'US', label: 'Америка', phone: '+1', suggested: true },
    ];

    // ERRORS
    const [phoneError, setPhoneError] = useState(false);

  

    const containsOnlyNumbers = (str: string) => {
        return /^\d+$/.test(str);
    };    

    const checkFormFields = () => {
        return  personalInfo.firstName === "" || personalInfo.lastName === "" || city.title == '' || personalInfo.phone === "" || phoneError;
    }

    const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPersonalInfo((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));

        if(e.target.value.length == 9 && containsOnlyNumbers(e.target.value)) {
            setPhoneError(false);
        }else {
            setPhoneError(true);
        }
    };

    const handleSubmit = async () => {
        //TODO: Make the changes to the User Profile data
        
        setShowModal(false);
    };

    // CUSTOM THEMES
    const textFieldTheme = createTheme({
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: 'red',
                        margin: '5px auto'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        fontSize: isMobile ? "1.6rem !important" : "1.3vw",
                        borderColor: "#28b485 !important",
                        "&.Mui-focused": {
                            "root": {
                                "borderColor": "#28b485 !important"
                            }
                        },
                        margin: "0"
                    },
                    input: {
                        fontFamily: "Adys Regular, sans-serif",
                        fontSize: isMobile ? "1.6rem !important" : "1.3vw",
                        borderColor: "#28b485 !important",
                        margin: "0"
                    }
                }
            },
            MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            fontFamily: "Adys Regular, sans-serif",
                            fontSize: isMobile ? "1.6rem !important" : "1.3vw",
                        },
                        shrink: {
                            fontFamily: "Adys Bold, sans-serif",
                            fontSize: isMobile ? "1.6rem !important" : "1.3vw",
                            color: "#28b485"
                        }
                }
            }
        }
    })

    const multiSelectTheme = (theme: any) => createTheme({
        ...theme,
        components: {
          MuiAutocomplete: {
              styleOverrides: {
                  root: {
                      
                  },
                  tag: {
                      fontSize: "1vw"
                  }, 
                  option: {
                      fontFamily: "Adys Regular",
                      fontWeight: "400",
                  },
                  inputRoot: {
                      
                  },
                  inputFocused: {
                      borderColor: "#f5f5f5"
                  },
                  input: {
                      fontFamily: "Adys Regular",
                      fontWeight: "400",
                      fontSize: isMobile ? "1.6rem !important" : "1.3vw",
                  },
                  listbox: {
                      background: "rgba(255,255,255, 0.7)",
                  },
                  paper: {
                      background: "rgba(255,255,255, 0.7)",
                  },
                  popper: {
                      background: "rgba(255,255,255, 0.7)",
                  }
              }
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                fontSize: isMobile ? "1.6rem !important" : "1.3vw",
              },
              input: {
                fontFamily: "Adys Regular, sans-serif",
                fontSize: isMobile ? "1.6rem !important" : "1.3vw",
              }
            }
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                fontFamily: "Adys Regular, sans-serif",
                fontSize: isMobile ? "1.6rem !important" : "1.3vw",
              },
              shrink: {
                fontFamily: "Adys Regular, sans-serif",
                fontSize: isMobile ? "1.6rem !important" : "1.3vw",
              }
            }
          }
        }   
    });

    //------------------------------------------------------------------------
 
    return (
        <div className="edit-form-container">

            <div className="close-edit-profile-card" style={{ width: "fix-content !important" }}>
                  <CloseOutlinedIcon 
                    sx={{ 
                      fontSize: isMobile ? "2.2rem" : "2.2vw", 
                      cursor: "pointer", 
                      borderRadius: "50%", 
                      padding: "3px", 
                      transition: "0.3s all", 
                      "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                    }}
                    onClick={() => {setShowModal(false)}} 
                  />
                </div>

            <div className="edit-form-title">
                <FaUserEdit style={{ fontSize: isMobile ? "2rem" : "2vw", marginBottom: "0.5vw"}} />
                <p style={{ fontFamily: "Adys Bold" }}>Редактирайте своя профил</p>
            </div>

            <section>
                {/* FIRST NAME */}
                <ThemeProvider theme={textFieldTheme}>
                    <TextField
                        id="firstName"
                        label="Име"
                        name="firstName"
                        value={personalInfo.firstName}
                        variant="outlined"
                        color="success"
                        sx={getTextFieldStyles("#28b485")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPersonalInfo((data) => ({
                            ...data,
                            [event.target.name]: event.target.value,
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                {personalInfo.firstName.length >= 3 && (
                                <CheckCircleIcon color="success" sx={{ color: "rgba(40, 180, 133, 0.7)", fontSize: isMobile ? "1.5rem" : "2rem" }}/>
                                )}
                            </InputAdornment>
                            ),
                        }}
                    />
                </ThemeProvider>

                 {/* LAST NAME */}
                 <ThemeProvider theme={textFieldTheme}>
                    <TextField
                        id="lastName"
                        label="Фамилия"
                        name="lastName"
                        value={personalInfo.lastName}
                        variant="outlined"
                        color="success"
                        sx={getTextFieldStyles("#28b485")}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPersonalInfo((data) => ({
                            ...data,
                            [event.target.name]: event.target.value,
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                {personalInfo.lastName.length >= 3 && (
                                <CheckCircleIcon color="success" sx={{ color: "rgba(40, 180, 133, 0.7)", fontSize: isMobile ? "1.5rem" : "2rem" }}/>
                                )}
                            </InputAdornment>
                            ),
                        }}
                    />
                </ThemeProvider>        

                {/* DISTRICT & CITY*/}
                <div className="district_city_container">
                    {/* DISTRICT */}
                    <ThemeProvider theme={multiSelectTheme}>
                        <Autocomplete
                            limitTags={1}
                            {...autocompleteOptions}
                            id="size-medium-outlined-multi"
                            size="medium"
                            options={districtOptions.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                            getOptionLabel={(option: any) => option.title}
                            sx={getSingleAutocompleteStyles("#28b485")}
                            value={personalInfo.district}
                            onChange={(e, v) => {
                                if(v) {
                                    setPersonalInfo((data) => ({
                                        ...data,
                                        ['district']: v
                                    }));
                                }
                                else {
                                    setPersonalInfo((data) => ({
                                        ...data,
                                        ['district']: {
                                            title: "",
                                            districtId: "",
                                            firstLetter: ""
                                        }
                                    }));
                                }
                            }}
                            renderInput={(params) => (
                            <TextField {...params} 
                                label={"Област"}
                                placeholder={"Област"}
                                sx={{backgroundColor: "#fff"}}
                            />
                            )}
                        />
                    </ThemeProvider>

                    {/* CITY */}
                    <ThemeProvider theme={multiSelectTheme}>
                        <Autocomplete
                            limitTags={1}
                            {...autocompleteOptions}
                            id="size-medium-outlined-multi"
                            size="medium"
                            options={cityObj.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                            getOptionLabel={(option: any) => option.title}
                            value={personalInfo.city}
                            sx={getSingleAutocompleteStyles("#28b485")}
                            onChange={(e, v) => {
                                if(v){
                                    setPersonalInfo((data) => ({
                                        ...data,
                                        ['city']: v
                                    }));
                                }else {
                                    setPersonalInfo((data) => ({
                                        ...data,
                                        ['city']: {
                                            title: "",
                                            cityId: "",
                                            regionId: "",
                                            firstLetter: ""
                                        }
                                    }));
                                }
                            }}  
                            
                            renderInput={(params) => (
                                <TextField {...params} 
                                    label={"Град"}
                                    placeholder={"Град"}
                                    name="city"
                                    sx={{backgroundColor: "#fff"}}/>
                                )}
                        />
                    </ThemeProvider>
                </div>
                    
                {/* Телефонен номер */}
                <FormControl sx={{ width: "100%", display: "flex", flexDirection: "row"}}>
                    {/* КОД на държавата */}
                    <ThemeProvider theme={multiSelectTheme}>
                        <Autocomplete
                            id="country-select-demo"
                            options={countries}
                            style={{ width: "18rem" }}
                            sx={getSingleAutocompleteStyles("#28b485")}
                            autoHighlight
                            inputValue={personalInfo.code}
                            onChange={(event, newValue) => {

                                let tempValue = newValue === null ? {
                                    code: "",
                                    label: "",
                                    phone: ""
                                }: newValue;
                                
                                setPersonalInfo((data) => ({
                                    ...data,
                                    ["code"]: tempValue.phone
                                }));
                                
                            }}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props: any, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                        key={key}
                                        component="li"
                                        sx={{ '& > img': { mr: 1, flexShrink: 0 }, fontSize: "1.5rem" }}
                                        {...optionProps}
                                    >
                                        <img
                                        loading="lazy"
                                        width="16"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                        />
                                        <span style={{ fontSize: "1.2rem" }}>{option.phone}</span>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Код"
                                    name="code"
                                    variant="outlined"
                                    color="success"
                                    sx={{ fontSize: "0.8rem" }}
                                    value={personalInfo.code}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        console.log("CODE: " + personalInfo.code);
                                        
                                        setPersonalInfo((data) => ({
                                            ...data,
                                            [event.target.name]: event.target.value,
                                        }));
                                    }} 
                                />
                            )}
                        />
                    </ThemeProvider>

                    {/* Номер */}
                    <ThemeProvider theme={textFieldTheme}>
                        <TextField
                            id="phone"
                            label="Телефонен номер"
                            name="phone"
                            value={personalInfo.phone}
                            variant="outlined"
                            color="success"
                            sx={getTextFieldStyles("#28b485")}
                            onInput = {(e: any) =>{
                                if(e.target.value != "")
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,9)
                            }}
                            onChange={ handlePhoneNumber }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(personalInfo.code != '' && personalInfo.phone.length == 9 && !phoneError) && (
                                            <CheckCircleIcon color="success" sx={{ color: "rgba(40, 180, 133, 0.7)", fontSize: "2rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </ThemeProvider>
                    
                </FormControl>     
            </section>
            
            {/* BUTTONS */}
            <div className="edit-buttons-container">
                {/* <Button
                    sx={{ width: "12rem", padding: "0.9rem 3.4rem", fontSize: "1.4rem", backgroundColor: "#A51414", "&:hover": {backgroundColor: "#8D1111", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    <DisabledByDefaultOutlinedIcon sx={{ fontSize: "2rem", mr: "0.5vw"}}></DisabledByDefaultOutlinedIcon>
                    ЗАТВОРИ
                </Button>  */}  

                <Button
                    sx={{ fontFamily: "Adys Bold", fontSize: isMobile ? "1.4rem" : "1.1vw", width: "12rem", padding: "0.9rem 3.4rem", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 1)", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    // disabled={ checkFormFields() }
                    onClick={ handleSubmit }
                    className="edit-card-btn"
                >
                    ЗАПАЗИ
                    <SaveIcon className="edit-card-btn-icon" sx={{ fontSize: isMobile ? "2rem" : "1.6vw", ml: "0.5vw", mb: isMobile ? "0.5rem" : "0.3vw"}}></SaveIcon>
                </Button>                  
            </div>
        </div>
    );
};
