import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import "./confirmPopUp.css";

const  ConfirmPopUp = ({ showInfoPopUp, setShowInfoPopUp,  module, action, setChecked }: any) => {
    const backdrop = {
        visible: { opacity: 1},
        hidden: { opacity: 0},
    }
    
    const modal = {
        hidden: {
            y: "-100vh",
            opacity: 0
        },
        visible: {
            y: "10vh",
            opacity: 1,
            transition: {
                delay: 0.5
            }
        }
    }

    const handleClosePopUp = function() {
        setShowInfoPopUp(false);
    }

    var partner = require('../../../../assets/breeding.png');

    const deny = () => {
        let newValue = true;

        if(action == "add") {
            newValue = false;
        }

        setChecked((data: any) => ({
            ...data,
            [module.name]: newValue,
        }));

        setShowInfoPopUp(false);
    };

    const confirm = () => {
        let newValue = false;;

        if(action == "add") {
            newValue = true;
        }

        setChecked((data: any) => ({
            ...data,
            [module.name]: newValue,
        }));

        setShowInfoPopUp(false);
    };


    return (
        <AnimatePresence mode='wait'>
            { showInfoPopUp && (
               <motion.div className="backdrop" style={{zIndex: "300", height: "100vh"}} variants={ backdrop } initial="hidden" animate="visible" exit="hidden">
                    <motion.div className="modal" variants={ modal }>
                        
                        <div className="info-popup-container">
                            <div className="close-icon-container">
                                <CloseOutlinedIcon 
                                    sx={{ fontSize: "2.8rem", 
                                        cursor: "pointer", 
                                        borderRadius: "50%", 
                                        padding: "3px", 
                                        transition: "0.3s all", 
                                        "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                    }}
                                    onClick={handleClosePopUp} 
                                />
                            </div>
                            <div className="info-popup-content">
                                <img src={partner} className='content-image' style={{ width: "14rem", top: "0"}}/>
                                <h4 style={{ marginTop: "1rem", marginBottom: "-1rem", textAlign: "center", fontFamily: "Adys Bold" }}>{action == "add" ? "Добавяне в" : "Премахване от"} модул </h4>
                                <span className="module-name" style={{ fontFamily: "Adys Bold" }}>{module.label}</span>
                                <div>
                                    <p style={{ textAlign: "center", fontFamily: "Adys Regular" }}>Желаете ли да направите промяната?</p>
                                </div>
                                <div className="confirm-btns-container" >
                                    <button className="confirm-btn deny" onClick={() => {deny()}}>НЕ</button>
                                    <button className="confirm-btn confirm" onClick={() => {confirm()}}>ДА</button>
                                </div>
                                
                            </div>
                            
                        </div>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default ConfirmPopUp;
 

