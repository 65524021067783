import { useState, useEffect, useRef } from "react";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import { FaWeightScale } from "react-icons/fa6";
import { PiDog } from "react-icons/pi";
import { PiCat } from "react-icons/pi";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { PiHouseLine } from "react-icons/pi";

import Carousel from "../modal/Carousel";
import "./petProfile.css";
import users from "../../../data/users";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useMediaQuery } from "@mui/material";

const PetProfile = ({ showInfoPopUp, setShowInfoPopUp, pet, setFavourites, module }: any) => {
    const [isFavourite, setIsFavourite] = useState(false);
    const [showCarousel, setShowCarousel] = useState(false);
    const chat_url = process.env.REACT_APP_CHAT_URL || "";
    const chat_id = process.env.REACT_APP_CHAT_ID || "";

    const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed


    const user = users.filter((u)=> {
        return u.pets.includes(pet.uuid);
    })

    let dogImage = '';

    if(pet.image != undefined) {
        dogImage = require('../../../assets/' + pet.image);
    }

    const handleFavourite = (pet: any, operation: any) => {
        setIsFavourite(!isFavourite);
        setFavourites(pet, operation);
    }

    const backdrop = {
        visible: { opacity: 1},
        hidden: { opacity: 0},
    }
    
    const modal = {
        hidden: {
            y: "-100vh",
            opacity: 0
        },
        visible: {
            y: "10vh",
            opacity: 1,
            transition: {
                delay: 0.5
            }
        }
    }

    const handleClosePopUp = function() {
        setShowInfoPopUp(false);
    }

    const openProfile = () => {
        const origin = window.location.origin;
        const newURL = origin + "/profile";
        window.location.href = newURL;
    }

    return (
        <AnimatePresence mode='wait'>
            { showInfoPopUp && (
            <motion.div className="backdrop" variants={ backdrop } initial="hidden" animate="visible" exit="hidden">
                    <motion.div className="pet_modal" variants={ modal }>
                        <Carousel showModal={showCarousel} setShowModal={setShowCarousel} images={pet.gallery}></Carousel>

                        <div className="pet_card-containter">
                            <div className="pet_card-content">
                                <div className="pet_card-top_container">
                                    <div className="pet_card-dog_details_header">
                                        <div className="pet_card-dog_details_header1">
                                            {pet.type == "dog" ? (
                                                <PiDog style={{ fontSize: "2.2rem", marginRight: "1.2rem", opacity: "0.5" }}/>
                                            ): (
                                                <PiCat style={{ fontSize: "2.2rem", marginRight: "1.2rem", opacity: "0.5" }}/>    
                                            )}
                                            <p className="dog-name">{pet.name}</p>
                                            <p className="dog-breed">/<em>{pet.breed}</em></p>

                                            {!isFavourite ? (
                                            // ADD TO FAVOURITES
                                            <Tooltip 
                                                title="Запази в Харесани" 
                                                arrow
                                                slotProps={{
                                                    popper: {
                                                        sx: {
                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                            {
                                                                marginTop: '0.5rem',
                                                                fontSize: '1.3rem',
                                                                fontFamily: 'Ubuntu',
                                                                color: 'rgba(0, 0, 0, 0.87)',
                                                                maxWidth: 220,
                                                                backgroundColor: '#f5f5f9',
                                                                border: '1px solid #dadde9',
                                                            },
                                                        },
                                                    },
                                                }}
                                                onClick={ () => {}}
                                            >
                                                < FavoriteBorderOutlinedIcon
                                                    style={{ fontSize: "3rem", marginLeft: "1rem", transition: "all 0.3s" }} 
                                                    className="favourites_icon"
                                                    onClick={()=>{
                                                        handleFavourite(pet, "add");
                                                    }}
                                                />
                                            </Tooltip>
                                            ): (
                                                // REMOVE FROM FAVOURITES
                                                <Tooltip 
                                                    title="Премахни от Харесани" 
                                                    arrow
                                                    slotProps={{
                                                        popper: {
                                                            sx: {
                                                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                                {
                                                                    marginTop: '0.5rem',
                                                                    fontSize: '1.3rem',
                                                                    fontFamily: 'Ubuntu',
                                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                                    maxWidth: 220,
                                                                    backgroundColor: '#f5f5f9',
                                                                    border: '1px solid #dadde9',
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    onClick={ () => {}}
                                                >
                                                    <FavoriteOutlinedIcon 
                                                        style={{ fontSize: "3rem", marginLeft: "1rem", transition: "all 0.3s", color: "#B43F50" }} 
                                                        className="favourites_icon"
                                                        onClick={()=>{
                                                            handleFavourite(pet, "add");
                                                        }}
                                                    />
                                                </Tooltip>    
                                        )}
                                        </div>
                                       
                                        <div className="pet-close-icon-container" style={{ width: "fix-content !important" }}>
                                            <CloseOutlinedIcon 
                                                sx={{ fontSize: "2.8rem", 
                                                    cursor: "pointer", 
                                                    borderRadius: "50%", 
                                                    padding: "3px", 
                                                    transition: "0.3s all", 
                                                    "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                                }}
                                                onClick={handleClosePopUp} 
                                            />
                                        </div>
                                    </div>       

                                    <span className="pet_card-separator-line"></span>
                                </div>

                                <div className="pet_card-dog_details_container">

                                    {/* IMAGE */}
                                    <div className="profile-image-wrapper" style={{ opacity: "1", width: "24rem", height: "12rem"}} >
                                        <img src={dogImage} className="pet_card-dog_image" style={{width: "100%", height: "100%"}} /> 

                                        <div className="profile-uploader" onClick={()=>{setShowCarousel(true)}}>
                                            <span className="profile-edit-image" > </span>
                                            
                                            <div style={{position:"absolute", bottom: "1.6rem", left: "4.5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-0.8rem", marginLeft: "-1.5rem"}}>
                                                <p>ГАЛЕРИЯ</p>
                                            </div>  
                                        </div>
                                    </div>
                                    
                                    <div className="main-container">
                                    
                                            {/* ADDRESS */}
                                            <div className="info-row-container">
                                                <FmdGoodOutlinedIcon sx={{ fontSize: "1.8rem", color: "rgba(0,0,0,0.7)"}} />
                                                <p className="dog-address">{pet.city + ', ' + pet.region}</p>
                                            </div>

                                            {/* SEX */}
                                            <div className="info-row-container">
                                                {pet.sex == "Мъжки" && (
                                                    <MaleOutlinedIcon sx={{ fontSize: "1.9rem", color: "#1073d6"}} />
                                                )}
                                                
                                                {pet.sex == "Женски" && (
                                                    <FemaleOutlinedIcon sx={{ fontSize: "1.9rem", color: "#bf1077"}} /> 
                                                )}
                                                
                                                <p className="dog-sex">{pet.sex}</p>
                                            </div>

                                            {/* COLOR */}
                                            {/* <div className="info-row-container">
                                                
                                                {pet.color.value == "#fff" ? (
                                                    <span className="color-indicator" style={{ backgroundColor: pet.color.value, border: "1px solid #000"}} ></span>
                                                ): (
                                                    <span className="color-indicator" style={{ backgroundColor: pet.color.value}} ></span>     
                                                )} 
                                                <p className="dog-color">{pet.color.label}</p>
                                            </div> */}

                                            {/* AGE */}
                                            <div className="info-row-container">
                                                <CakeIcon sx={{ fontSize: "1.8rem", color: "rgba(0,0,0,0.4)"}}/>
                                                <p className="age">{pet.age} години</p>
                                            </div>

                                            {/* Weight */}
                                            <div className="info-row-container">
                                                <FaWeightScale style={{ fontSize: "1.4rem", color: "rgba(0,0,0,0.4)", marginLeft: "0.2rem"}}/>
                                                <p className="weight">{pet.weight + " кг"}</p>
                                            </div>
                                    </div>

                                    {/* CHECKBOXES CONTAINER */}
                                    <div className="pet_card-bottom_container">
                                        {/* RIGHT SIDE */}
                                        <div>
                                            {/* CHECKBOXES */}
                                            <div className="checbox-container">
                                                {pet.vaccinated === true ? (
                                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.2vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                                ): (
                                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.2vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                                )}
                                                <p style={{width: "100%", fontSize:"1.4rem"}}>Напълно ваксиниран(а)</p>
                                            </div>

                                            <div className="checbox-container">
                                                {pet.original_breed === true ? (
                                                    <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.2vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>    
                                                ): (
                                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.2vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                                )}
                                                <p style={{width: "100%", fontSize:"1.4rem"}}>
                                                    Потвърдено родословие
                                                </p>

                                                {pet.original_breed === true && (
                                                    <Tooltip 
                                                        title="Виж родословието" 
                                                        arrow
                                                        slotProps={{
                                                            popper: {
                                                                sx: {
                                                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                                    {
                                                                        marginTop: '0.5rem',
                                                                        fontSize: '1.3rem',
                                                                        fontFamily: 'Ubuntu',
                                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                                        maxWidth: 220,
                                                                        backgroundColor: '#f5f5f9',
                                                                        border: '1px solid #dadde9',
                                                                    },
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <WallpaperOutlinedIcon sx={{
                                                            fontSize: "2.4rem",  
                                                            marginLeft: "1rem", 
                                                            borderRadius: "50%", 
                                                            cursor: "pointer", 
                                                            padding: "4px", 
                                                            transition: "0.3s all", 
                                                            "&:hover": { 
                                                                backgroundColor: "rgba(0,0,0,0.1)"
                                                            }
                                                        }}/>
                                                    </Tooltip>
                                                )}
                                            </div>

                                            {/* OWNER DETAILS */}
                                            <div className="owner-details-container">
                                                

                                                {(module === "adoption" && pet.adoptionFrom === "person") && (
                                                    <p className="owner-title">Собственик:</p>
                                                )}

                                                {(module === "adoption" && pet.adoptionFrom === "shelter") && (
                                                    <p className="owner-title">Приют:</p>
                                                )}

                                                {(module === "partner") && (
                                                    <p className="owner-title">Собственик:</p>
                                                )}
                    
                                                <span className="pet_card-separator-line"></span>

                                                {(module === "adoption" && pet.adoptionFrom === "shelter") && (
                                                    <div className="owner-details">
                                                        <PiHouseLine style={{fontSize: "2.4rem"}}/>
                                                        <p style={{ fontSize: "1.5rem" }}>"Четири лапи"</p>

                                                        <Tooltip 
                                                            title="Свържи се с приюта" 
                                                            arrow
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                                        {
                                                                            marginTop: '0.5rem',
                                                                            fontSize: '1.3rem',
                                                                            fontFamily: 'Ubuntu',
                                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                                            maxWidth: 220,
                                                                            backgroundColor: '#f5f5f9',
                                                                            border: '1px solid #dadde9',
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <QuestionAnswerOutlinedIcon sx={{ color: "rgba(0,0,0,0.6)", fontSize: "2rem", cursor: "pointer"}}/>
                                                        </Tooltip>
                                                    </div>   
                                                )}

                                                {((module === "adoption" && pet.adoptionFrom === "person") || module === "partner") && (
                                                    <div className="owner-details">
                                                       {/*  <img 
                                                            src={require("../../../assets/about_iliyan.jpg")}
                                                            onClick={openProfile}
                                                        >
                                                        </img> */}
                                                        <AccountCircleIcon 
                                                            onClick={openProfile}
                                                            sx={{
                                                                fontSize: isMobile ? "" : "4rem",
                                                                color: "rgba(0,0,0,0.4)"
                                                            }}
                                                        />
                                                        <p>{user[0].first_name}</p>

                                                        <Tooltip 
                                                            title="Пиши на собственика" 
                                                            arrow
                                                            slotProps={{
                                                                popper: {
                                                                    sx: {
                                                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                                        {
                                                                            marginTop: '0.5rem',
                                                                            fontSize: '1.3rem',
                                                                            fontFamily: 'Adys Regular',
                                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                                            maxWidth: 220,
                                                                            backgroundColor: '#f5f5f9',
                                                                            border: '1px solid #dadde9',
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <QuestionAnswerOutlinedIcon sx={{ color: "rgba(0,0,0,0.6)", fontSize: "2rem", cursor: "pointer"}} onClick={() => {
                                                                // todo get the user id
                                                                const directChat = chat_url.concat(`/direct/${chat_id}`);
                                                                window.open(directChat, '_blank');
                                                            }}/>
                                                        </Tooltip>
                                                    </div>   
                                                )}
 
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <div className="pet-description">
                                  {pet.description === '' ? (
                                    <p style={{ color: "rgba(0,0,0,0.6)"}} >Липсва описание от собственика.</p>
                                  ):(
                                    <p>{pet.description}</p>
                                  )}
                                </div>
                                
                            </div>   
                        </div>
                    </motion.div>

                </motion.div> 
                )}
        </AnimatePresence>
    );
};

export default PetProfile;