import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { getAutocompleteStyles } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";


import { Box, useTheme } from "@mui/material";
import { props } from "cypress/types/bluebird";
import {SingleSelectFilter} from "./SingleSelectFilter";

type SexFilterProps = {
  setSex: (type: string) => void;
  filters: any;
  isMobile: any;
  width: any
};

export const DogSexSearchDropdown: React.FC<SexFilterProps> = ({ setSex, filters, isMobile, width
}) => {
  const theme = useTheme();

  const primaryColor = theme.palette.primary.light;

  const sexes = {
    title: "Пол",
    options: [
      { title: "Мъжки", order: 1 },
      { title: "Женски", order: 2 },
    ]
  };

  return (
    <SingleSelectFilter filterOptions={sexes} setPetType={setSex} filters={filters} isMobile={isMobile} width={width}/>
  );
};
