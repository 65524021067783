import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { sliderFilter } from "../../../data/mui_styles";


type SliderComponentProps = {
  defaultValue: number[];
  description: string;
  value: Array<number>,
  setValue: (val: any)=> void;
  isMobile: any;
};

const minDistance = 1;

export const DogWeigthSlider: React.FC<SliderComponentProps> = ({
  defaultValue,
  description,
  value,
  setValue,
  isMobile
}) => {

  const theme = useTheme();

  const typographyColor = theme.palette.primary.light;

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: !isMobile === true ? sliderFilter.web : sliderFilter.mobile
  })


  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        if (clamped) setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue([clamped - minDistance, clamped]);
      }
    } else {
      setValue(newValue as number[]);
    }
  };
  
  
  return (
    <div style={{ marginTop: !isMobile ? "2rem" : "-1rem", position: "relative" }}>
      <Typography
        gutterBottom
        style={{ color: "rgb(95, 209, 171)", marginBottom: !isMobile ? "1rem" : "0rem", fontFamily: "Adys Regular", fontSize: !isMobile ? "1.8rem" : "1.1rem" }}
      >
        {description}
      </Typography>
      <ThemeProvider theme={newTheme}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="off" // Set valueLabelDisplay to "off" to hide the popups
          step={1}
          min={0}
          max={80}
          disableSwap
          style={{
            width: !isMobile ? "25rem" : "11rem",
            height: !isMobile ? 3 : 2,
            color: 'rgb(95, 209, 171)',
          }}
        />
      </ThemeProvider>
      {/* Additional Box component to customize the position of the value labels */}
      <Box
        display="flex"
        justifyContent="space-between"
        color="white"
        paddingLeft={ !isMobile ? "1.6rem" : "0rem"}
        paddingRight={ !isMobile ? "1.6rem" : "0rem"}
        marginTop={!isMobile ? "0" : "-1rem"}
      >
        <Typography style={{ 
          color: "rgba(0,0,0,0.7)", 
          borderRadius: "50%", 
          width: !isMobile ? "3.2rem" : "2.2rem", 
          backgroundColor: "rgba(0,0,0,0.13)",
          fontFamily: "Adys Regular", 
          fontWeight: "400",
          fontSize: !isMobile ? "1.6rem" : "1.1rem"
        }}>
            {value[0]}
        </Typography>
        <Typography style={{ 
          color: "rgba(0,0,0,0.7)", 
          borderRadius: "50%", 
          width: !isMobile ? "3.2rem" : "2.2rem", 
          backgroundColor: "rgba(0,0,0,0.13)",
          fontFamily: "Adys Regular", 
          fontWeight: "400", 
          fontSize: !isMobile ? "1.6rem" : "1.1rem"
        }}>
          {value[1]}
        </Typography>
      </Box>
    </div>

    
  );
};
