const usersData = [
    {
        uuid: "5995f6ff-e808-432f-a3f3-dd6fce79c384",
        first_name: "Димитър",
        family_name: "Пенев",
        district: {
            "districtId": "10",
            "title": "Благоевград"
        },
        city: {
            "cityId": "12",
            "title": "Банско",
            "regionId": "10"
        },
        pets: ["0c7bbdec-b4a6-4b32-b7da-b0740023d2f4"]
    },
    {
        uuid: "d53b4282-f1ca-4659-84f4-e6d89d37b17c",
        first_name: "Мария",
        family_name: "Иванова",
        email: "m_ivanova@gmail.com",
        district: {
            "districtId": "1",
            "title": "Бургас"
        },
        city: {
            "cityId": "35",
            "title": "Бургас",
            "regionId": "1"
        },
        pets: ["5fe86211-82ed-4988-a274-fe4d736ef2ef"]
    },
    {
        uuid: "e775b3ed-0f56-43db-b09e-4d0db9764719",
        first_name: "Петя",
        family_name: "Узунова",
        email: "pet_uzunova@gmail.com",
        district: {
            "districtId": "7",
            "title": "Пловдив"
        },
        city: {
            "cityId": "176",
            "title": "Пловдив",
            "regionId": "7"
        },
        pets: ["2852d7a8-080f-453b-a2e9-a9cf1c3c4a43"]
    },
    {
        uuid: "b286d08b-ae93-4d4c-9fb5-8641706161ec",
        first_name: "Кирил",
        family_name: "Теодоров",
        email: "kiril.todorov89@gmail.com",
        district: {
            "districtId": "9",
            "title": "София-град"
        },
        city: {
            "cityId": "217",
            "title": "София",
            "regionId": "9"
        },
        pets: ['1b560d63-8d5d-4e55-9c98-084885e3cbd0']
    },
    {
        uuid: "b34195c9-45b9-41cf-a4e2-ae777bb5a59e",
        first_name: "Иван",
        family_name: "Странски",
        email: "i.stranski00@gmail.com",
        district: {
            "districtId": "26",
            "title": "Хасково"
        },
        city: {
            "cityId": "242",
            "title": "Хасково",
            "regionId": "26"
        },
        pets: ["6ad09ca6-3014-4b20-88f7-e488e2fa7eab"]
    },
    {
        uuid: "ae4b72d8-d5c3-4e63-a9b4-0819646ef6c7",
        first_name: "Мая",
        family_name: "Христова",
        email: "mhristova99@gmail.com",
        district: {
            "districtId": "10",
            "title": "Благоевград"
        },
        city: {
            "cityId": "12",
            "title": "Банско",
            "regionId": "10"
        },
        pets: ["1183d498-ed97-4ca8-992a-d11d361d9a59"]
    },
    {
        uuid: "bb4e36d9-a5e2-4c82-88ff-6474a53e7805",
        first_name: "Виктор",
        family_name: "Петров",
        email: "victor_pet97@gmail.com",
        chat_id_staging: "gegpboTi8m65kcHmfpuNg7LSGCP7sqetJL",
        chat_id_prod: "gegpboTi8m65kcHmfpuNg7LSGCP7sqetJL",
        district: {
            "districtId": "28",
            "title": "Сливен"
        },
        city: {
            "cityId": "252",
            "title": "Шивачево",
            "regionId": "28"
        },
        pets: ["15bcc33c-7f52-49fe-aa3f-f2c605181d3f"]
    },
    {
        uuid: "0138a18a-7af0-4b77-9f4a-5514bc479e35",
        first_name: "Александър",
        family_name: "Попов",
        district: {
            "districtId": "10",
            "title": "Благоевград"
        },
        city: {
            "cityId": "12",
            "title": "Банско",
            "regionId": "10"
        },
        pets: ["01800f14-9eba-44d5-b4a8-985fc36c751a"]
    },
    {
        uuid: "085c5966-9a66-4a74-b5dd-53e022284d87",
        first_name: "Камелия",
        family_name: "Маринова",
        district: {
            "districtId": "18",
            "title": "Ямбол"
        },
        city: {
            "cityId": "257",
            "title": "Ямбол",
            "regionId": "18"
        },
        pets: ["8fe28130-c526-4a8a-9046-e45c0918628c"]
    },
    {
        uuid: "e08b83fa-3d66-41ae-ba48-4df48d1b6a4f",
        first_name: "Петър",
        family_name: "Василев",
        district: {
            "districtId": "9",
            "title": "София-град"
        },
        city: {
            "cityId": "217",
            "title": "София",
            "regionId": "9"
        },
        pets: ["0fea756b-71a8-46bf-a9ef-076605954e93"]
    }
];

export default usersData;