import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from 'framer-motion';

import {
    Grid,
    Container,
    Box,
    Typography,
    Button,
    useMediaQuery,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { IconWithBadge } from "../../navbar/badge/IconWithBadge";
import { DogBreedSearchDropdown } from "../../humpme/filters/DogBreedSearchDropdown";
import { DistrictSearchDropdown } from "../../humpme/filters/DistrictSearchDropdown";
import { DogWeigthSlider } from "../../humpme/filters/DogWeigthSlider";
import { DogColorSearchDropdown } from "../../humpme/filters/DogColorSearchDropdown";
import { DogAgeSlider } from "../../humpme/filters/DogAgeSlider";
import { TownSearchDropdown } from "../../humpme/filters/TownSearchDropdown";
import { CheckboxFilters } from "../../humpme/filters/CheckboxFilters";
import { DogSexSearchDropdown } from "../../humpme/filters/DogSexSearchDropdown";
import { PetTypeFilter } from "../../humpme/filters/PetTypeFilter";
import { AdoptFromFilter } from "../../humpme/filters/AdoptFromFilter";

import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { LuFilter } from "react-icons/lu";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import themes from "../../../data/themes";

import "./filtersModal.css";

type filterProps = {
    showModal: any;
    setShowModal: (a: boolean) => void;
    module: string;
    setLoading: () => void;
    filters: any;
    setFilters: (a: {}) => void;
    setFiltersCleared: (a: boolean) => void;
    isMobile: boolean
  };

const FiltersModal: React.FC<filterProps> = ({
    showModal,
    setShowModal,
    module,
    setLoading,
    filters, 
    setFilters, 
    setFiltersCleared,
    isMobile }) => 
{

    // const isMobile = useMediaQuery("(max-width:600px)");

    const backdrop = {
        visible: { opacity: 1},
        hidden: { opacity: 0},
    }
    
    const modal = {
        hidden: {
            y: "-50vh",
            opacity: 0
        },
        visible: {
            y: "6vh",
            opacity: 1,
            transition: {
                delay: 0.5
            }
        }
    }

    const [showFilters, setShowFilters] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedType, setSelectedType] = useState('');

    const handleTypeSelect = (type: string) => {
    setSelectedType(type);

    let filter = {
      name: "petType",
      value: type
    }
    setFilters(filter);
    };

    const handleAdoptFrom = (type: string) => {

        let filter = {
        name: "adoptFrom",
        value: type
        }
        setFilters(filter);
    };

    const handleSexSelect = (sex: any) => {
        let filter = {
        name: "sex",
        value: sex
        }

        setFilters(filter);
    };

    const handleColorSelect = (color: any) => {
        let filter = {
        name: "color",
        value: color
        }

        setFilters(filter);
    };

    const handleVaccinatedSelect = (vaccinated: any) => {
        let filter = {
        name: "vaccinated",
        value: vaccinated
        }

        setFilters(filter);
    };

    const handleOriginalBreedSelect = (originalBreed: any) => {
        let filter = {
        name: "originalBreed",
        value: originalBreed
        }

        setFilters(filter);
    };

    const handleBreedSelect = (breed: any) => {
        let filter = {
        name: "breed",
        value: breed
        }

        setFilters(filter);
    };

    const handleSelectedDistrict = (district: any) => {
        let filter = {
        name: "district",
        value: district
        }
        setFilters(filter);
    };

    const handleSelectedTown = (town: any) => {
        let filter = {
        name: "city",
        value: town
        }
        setFilters(filter);
    };

    const handleAge = (age: any) => {
        let filter = {
        name: "age",
        value: age
        }

        setFilters(filter);
    };

    const handleWeight = (weight: any) => {
        let filter = {
        name: "weight",
        value: weight
        }

        setFilters(filter);
    };

    const clearFilters = () => {
        setLoading();
        setFiltersCleared(true);
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const handleSearch = () => {
        setLoading();
        closeModal();
    }

    return (
        <AnimatePresence mode='wait'>
            { showModal && (
                <motion.div className="backdrop" variants={ backdrop } initial="hidden" animate="visible" exit="hidden">
                    <motion.div className="pet_modal" variants={ modal }>
                        <Container >
                            {/* Filters Close Button */}
                            <p 
                                onClick={() => {closeModal()}}
                                style={{ 
                                    fontFamily: "Adys Bold", 
                                    fontSize: "1.3rem",
                                    backgroundColor: "#fff",
                                    width: "fit-content",
                                    padding: "0.4rem",
                                    borderRadius: "8px",
                                    position: "absolute",
                                    border: "1px solid rgba(0,0,0,0.3)",
                                    right: "40%",
                                    top: "-1.4rem",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.2rem",
                                    cursor: "pointer"
                                }}
                                >
                                ФИЛТРИ
                                <CancelOutlinedIcon style={{ fontSize: "1.7rem", marginBottom: "0.3rem"}} />
                            </p>
                            <Grid
                                container
                                spacing={2}
                                justifyContent={"center"}
                                style={{
                                    display: "flex",
                                    backgroundColor: "#fff",
                                    width: "94%",
                                    height: "max-content",
                                    margin: "0 auto",
                                    borderRadius: 8,
                                }}
                            >
                            
                            
                                {module === "adoption" && (
                                    <Grid 
                                        container
                                        spacing={2}
                                        flexDirection={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        gap={"0.5rem"}
                                        style={{
                                            width: "100%",
                                            margin: "1.5rem auto",
                                            marginTop: "2.8rem"
                                        }}
                                    >
                                        <Grid item style={{ padding: "0" }}>
                                            <AdoptFromFilter setAdoptFrom={handleAdoptFrom} filters={filters} isMobile={isMobile}/>
                                        </Grid>

                                        <Grid item style={{ padding: "0" }}>
                                            <PetTypeFilter setPetType={handleTypeSelect} filters={filters} isMobile={isMobile}/>
                                        </Grid>
                                    </Grid>
                                    
                                )}
                                
                                {module === "partner" && (
                                    <Grid 
                                        container
                                        spacing={2}
                                        flexDirection={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        gap={"1rem"}
                                        style={{
                                            width: "100%",
                                            margin: "1.5rem auto",
                                            marginTop: "2.8rem"
                                            
                                    }}>
                                        <Grid item style={{ padding: "0" }}>
                                            <PetTypeFilter setPetType={handleTypeSelect} filters={filters} isMobile={isMobile}/>
                                        </Grid>

                                        <Grid item style={{ padding: "0" }}>
                                            <DogSexSearchDropdown setSex={handleSexSelect} filters={filters} isMobile={isMobile} width={145}/>
                                        </Grid>     
                                    </Grid>
                                )}
                                
                                
                                {module === "adoption" && (
                                    <Grid 
                                        container
                                        spacing={2}
                                        flexDirection={"row"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        gap={"0.5rem"}
                                        style={{
                                            width: "100%",
                                            margin: "1.5rem auto",
                                            marginTop: "1.2rem"
                                        }}>
                                            <Grid item style={{ padding: "0" }}>
                                                <DogSexSearchDropdown setSex={handleSexSelect} filters={filters} isMobile={isMobile} width={120}/>
                                            </Grid> 
                                            <Grid item style={{ padding: "0" }}>
                                                <DogBreedSearchDropdown selectedType={selectedType} setBreed={handleBreedSelect} filters={filters} isMobile={isMobile} width={170}/>
                                            </Grid>
                                    </Grid>
                                )}

                                {module === "partner" && (
                                    <Grid item style={{ padding: "0", marginBottom: "1.5rem" }}>
                                        <DogBreedSearchDropdown selectedType={selectedType} setBreed={handleBreedSelect} filters={filters} isMobile={isMobile} width={300}/>
                                    </Grid>
                                )}
                                
                                <Grid item style={{ padding: "0", marginBottom: "1.5rem" }}>
                                    <DogColorSearchDropdown setColor={handleColorSelect} filters={filters} isMobile={isMobile}/>
                                </Grid>

                                <Grid item style={{ padding: "0", marginBottom: "1.5rem"}}>
                                    <DistrictSearchDropdown setSelectedDistrict={setSelectedDistrict} setDistrictName={handleSelectedDistrict} filters={filters} isMobile={isMobile}/>
                                </Grid>

                                <Grid item style={{ padding: "0", marginBottom: "1.5rem" }}>
                                    <TownSearchDropdown selectedDistrictId={selectedDistrict} setTown={handleSelectedTown} filters={filters} isMobile={isMobile}/>
                                </Grid>
                    
                                {/* Second Line */}
                                <Grid 
                                    container 
                                    item 
                                    justifyContent="center" 
                                    spacing={ module === "partner" ? 4 :16}
                                    style={{ padding: "0", marginBottom: "0" }}
                                >
                                    <Grid item>
                                        <Box textAlign="center">
                                            <DogAgeSlider
                                                defaultValue={[0, 6]}
                                                description="Възраст (години)"
                                                value={filters.age}
                                                setValue={handleAge}
                                                isMobile={isMobile}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box textAlign="center">
                                            <DogWeigthSlider
                                                defaultValue={[0, 80]}
                                                description="Тегло (кг)"
                                                value={filters.weight}
                                                setValue={handleWeight}
                                                isMobile={isMobile}
                                            />
                                            </Box>
                                    </Grid>

                                
                                    <Grid item style={{ padding: "0", marginLeft: isMobile ? "4rem" : "0"}}>
                                        <Box textAlign="center">
                                            <CheckboxFilters 
                                                vaccinated={filters.vaccinated} 
                                                setVaccinated={handleVaccinatedSelect} 
                                                originalBreed={filters.originalBreed} 
                                                setOriginalBreed={handleOriginalBreedSelect}
                                                isMobile={isMobile}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box textAlign="left">
                                            <>
                                                {module === "adoption" && (
                                                    <Grid item style={{ marginBottom: "1rem"}}>
                                                        <DistrictSearchDropdown setSelectedDistrict={setSelectedDistrict} setDistrictName={handleSelectedDistrict} filters={filters} isMobile={isMobile}/>
                                                    </Grid>
                                                )} 
                                            </>
                                            <Box
                                                textAlign="right"
                                                marginTop={!isMobile ? 2 : -4}
                                                marginLeft={!isMobile ? 30 : -2}
                                                marginBottom={!isMobile ? 3 : 0}
                                                sx={{ 
                                                    width: "max-content",
                                                    display: "flex", 
                                                    flexDirection: "row", 
                                                    justifyContent: "center", 
                                                    alignItems: "start",
                                                    gap: "1.5rem"
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleSearch}
                                                        sx={{ 
                                                        borderRadius: 10,
                                                        width: !isMobile ? "15rem" : "9rem",
                                                        backgroundColor: "rgba(95, 209, 171)",
                                                        color: "rgba(0,0,0,0.7)",
                                                        fontFamily: "Adys Bold",
                                                        fontWeight: "400",
                                                        fontSize: !isMobile ? "1.7rem" : "1.2rem",
                                                        "&:hover": {
                                                            color: "#fff",
                                                            backgroundColor: "rgba(95, 209, 171)"
                                                        }
                                                        }}
                                                    >
                                                        <SearchOutlinedIcon sx={{ fontSize: !isMobile ? "2rem" : "1.3rem", marginLeft: "-1rem", marginRight: "0.5rem"}} />
                                                        Търси
                                                    </Button>
                                                    <Typography
                                                        variant="h6"
                                                        align="center"
                                                        gutterBottom
                                                        marginBottom=""
                                                        marginTop="1rem"
                                                        marginLeft={!isMobile ? "1rem" : "0"}
                                                    
                                                        onClick={() => {clearFilters()}}
                                                        sx={{ 
                                                        fontFamily: "Adys Regular", 
                                                        fontWeight: "400", 
                                                        fontSize: !isMobile ? "1.3rem" : "1rem", 
                                                        color: "rgba(0,0,0,0.6)", 
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: isMobile ? "0" : "0.5rem",
                                                        "&:hover": {
                                                            textDecoration: "underline",
                                                            color: themes.main.button_main
                                                        } 
                                                        }}
                                                    >
                                                        <FilterAltOffOutlinedIcon style={{ marginLeft: isMobile ? "2rem" : "0", marginRight: isMobile ? "0rem" : "0"}}/>
                                                        Изчисти филтрите
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </motion.div>
                </motion.div> 
            )}
        </AnimatePresence>
    );
};

export default FiltersModal;