import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import "./infoPopUp.css";

const  InfoPopUp = ({ showInfoPopUp, setShowInfoPopUp,  module }: any) => {
    const backdrop = {
        visible: { opacity: 1},
        hidden: { opacity: 0},
    }
    
    const modal = {
        hidden: {
            y: "-100vh",
            opacity: 0
        },
        visible: {
            y: "10vh",
            opacity: 1,
            transition: {
                delay: 0.5
            }
        }
    }

    const handleClosePopUp = function() {
        setShowInfoPopUp(false);
    }

    var dog_image = require('../../../assets/dog_puppy_sad.png');

    return (
        <AnimatePresence mode='wait'>
            { showInfoPopUp && (
               <motion.div className="backdrop" style={{zIndex: "300", height: "100vh"}} variants={ backdrop } initial="hidden" animate="visible" exit="hidden">
                    <motion.div className="modal" variants={ modal }>
                        
                        <div className="info-popup-container">
                            <div className="close-icon-container">
                                <CloseOutlinedIcon 
                                    sx={{ fontSize: "2.8rem", 
                                        cursor: "pointer", 
                                        color: "rgba(0, 0, 0, 0.8)",
                                        borderRadius: "50%", 
                                        padding: "3px", 
                                        transition: "0.3s all", 
                                        "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                    }}
                                    onClick={handleClosePopUp} 
                                />
                            </div>
                            <div className="info-popup-content">
                                <img src={dog_image} className='content-image' />
                                <h4>В процес на разработка...</h4>
                                <div>
                                    <p>Благодарим Ви за проявения интерес!</p>
                                    <p>Ще Ви информираме, когато пуснем модул <span className="module-name">{module}</span></p>
                                </div>
                                
                            </div>
                            
                        </div>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default InfoPopUp;
 

