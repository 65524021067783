export type District= {
    districtId: string,
    title: string
}

let districtsArray: District[] = [
    {
        "districtId": "1",
        "title": "Бургас"
    },
    {
        "districtId": "2",
        "title": "Варна"
    },
    {
        "districtId": "3",
        "title": "Силистра"
    },
    {
        "districtId": "4",
        "title": "Търговище"
    },
    {
        "districtId": "5",
        "title": "Ловеч"
    },
    {
        "districtId": "6",
        "title": "Кърджали"
    },
    {
        "districtId": "7",
        "title": "Пловдив"
    },
    {
        "districtId": "8",
        "title": "Добрич"
    },
    {
        "districtId": "9",
        "title": "София-град"
    },
    {
        "districtId": "10",
        "title": "Благоевград"
    },
    {
        "districtId": "11",
        "title": "Пазарджик"
    },
    {
        "districtId": "12",
        "title": "Перник"
    },
    {
        "districtId": "13",
        "title": "Плевен"
    },
    {
        "districtId": "14",
        "title": "Видин"
    },
    {
        "districtId": "15",
        "title": "Монтана"
    },
    {
        "districtId": "16",
        "title": "Кюстендил"
    },
    {
        "districtId": "17",
        "title": "Софийска"
    },
    {
        "districtId": "18",
        "title": "Ямбол"
    },
    {
        "districtId": "19",
        "title": "Русе"
    },
    {
        "districtId": "20",
        "title": "Враца"
    },
    {
        "districtId": "21",
        "title": "Велико Търново"
    },
    {
        "districtId": "22",
        "title": "Шумен"
    },
    {
        "districtId": "23",
        "title": "Габрово"
    },
    {
        "districtId": "24",
        "title": "Стара Загора"
    },
    {
        "districtId": "25",
        "title": "Смолян"
    },
    {
        "districtId": "26",
        "title": "Хасково"
    },
    {
        "districtId": "27",
        "title": "Разград"
    },
    {
        "districtId": "28",
        "title": "Сливен"
    }

];

let districts = {
    "title": "Област",
    "options": districtsArray
}

export default districts;