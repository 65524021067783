import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import FlexBetween from "../../FlexBetween";
import { Message, Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { IconWithBadge } from "../badge/IconWithBadge";
import { ChatList } from "./ChatList";
import { useTypedSelectorMessage } from "../../../features/messagesSlice";
import { useTheme } from "@mui/material/styles";

export const ChatMenu = ({mode}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const chat_url = process.env.REACT_APP_CHAT_URL || "";
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };


  const lightColor = "rgba(255,255,255, 1)";
  const darkColor = "rgba(0,0,0,0.8)";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const rocketChatUrl = chat_url.concat(`/home`);
    const newWindow = window.open(rocketChatUrl, '_blank');
  };
  
  const newMessages = useTypedSelectorMessage(
    (state) => state.messages.unreadMessages
  );

  const theme = useTheme();

  return (
    <>
      <Tooltip 
        title="ЧАТОВЕ"
        arrow
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: '0px',
                  fontSize: '1.4rem',
                  fontFamily: 'Adys Regular',
                  color: 'rgba(0, 0, 0, 0.87)',
                  maxWidth: 220,
                  backgroundColor: '#f5f5f9',
                  border: '1px solid #dadde9'
                },
            },
          },
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: "50%",
            position: "relative",
          }}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <IconWithBadge
            icon={<Message />}
            badgeCount={newMessages}
            iconStyles={{
              fontSize: "25px",
              color: mode === "dark" ? darkColor : lightColor,
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
};
