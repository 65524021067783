import React, { useState } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { IconWithBadge } from "../../navbar/badge/IconWithBadge";
import { DogBreedSearchDropdown } from "./DogBreedSearchDropdown";
import { DistrictSearchDropdown } from "./DistrictSearchDropdown";
import { DogWeigthSlider } from "./DogWeigthSlider";
import { DogColorSearchDropdown } from "./DogColorSearchDropdown";
import { DogAgeSlider } from "./DogAgeSlider";
import { TownSearchDropdown } from "./TownSearchDropdown";
import { CheckboxFilters } from "./CheckboxFilters";
import { DogSexSearchDropdown } from "./DogSexSearchDropdown";
import { PetTypeFilter } from "./PetTypeFilter";
import { AdoptFromFilter } from "./AdoptFromFilter";

import { VscFilter } from "react-icons/vsc";
import IconButton from "@mui/material/IconButton";
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import { LuFilter } from "react-icons/lu";

import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import themes from "../../../data/themes";

type filterProps = {
  module: string;
  isMobile: boolean;
  setLoading: () => void;
  openFiltersModal: (a: boolean) => void;
  filters: any;
  setFilters: (a: {}) => void;
  setFiltersCleared: (a: boolean) => void;
  favouritesCount: number;
  showFavourites: boolean;
  setShowFavourites: (a: any)=> void;
};

export const FiltersLayout: React.FC<filterProps> = ({
  module,
  isMobile,
  setLoading,
  openFiltersModal, 
  filters, 
  setFilters, 
  setFiltersCleared, 
  favouritesCount,
  showFavourites,
  setShowFavourites}) => {

  const [showFilters, setShowFilters] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const mode = useSelector((state: any) => state.theme.mode);

  const handleTypeSelect = (type: string) => {
    setSelectedType(type);

    let filter = {
      name: "petType",
      value: type
    }
    setFilters(filter);
  };

  const handleAdoptFrom = (type: string) => {

    let filter = {
      name: "adoptFrom",
      value: type
    }
    setFilters(filter);
  };

  const handleSexSelect = (sex: any) => {
    let filter = {
      name: "sex",
      value: sex
    }

    setFilters(filter);
  };

  const handleColorSelect = (color: any) => {
    let filter = {
      name: "color",
      value: color
    }

    setFilters(filter);
  };

  const handleVaccinatedSelect = (vaccinated: any) => {
    let filter = {
      name: "vaccinated",
      value: vaccinated
    }

    setFilters(filter);
  };

  const handleOriginalBreedSelect = (originalBreed: any) => {
    let filter = {
      name: "originalBreed",
      value: originalBreed
    }

    setFilters(filter);
  };

  const handleBreedSelect = (breed: any) => {
    let filter = {
      name: "breed",
      value: breed
    }

    setFilters(filter);
  };

  const handleSelectedDistrict = (district: any) => {
    let filter = {
      name: "district",
      value: district
    }
    setFilters(filter);
  };

  const handleSelectedTown = (town: any) => {
    let filter = {
      name: "city",
      value: town
    }
    setFilters(filter);
  };

  const handleAge = (age: any) => {
    let filter = {
      name: "age",
      value: age
    }

    setFilters(filter);
  };

  const handleWeight = (weight: any) => {
    let filter = {
      name: "weight",
      value: weight
    }

    setFilters(filter);
  };

  const clearFilters = () => {
    setLoading();
    setFiltersCleared(true);
  };

  // const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed

  // Conditionally set the display style with if-else statement
  let filtersDisplayStyle = "flex";
  let filtersBackgroundColor = "transparent"; // Default background color
  let filtersWidth = "auto";

  if (isMobile) {
    filtersDisplayStyle = showFilters ? "flex" : "none";
    filtersWidth = showFilters ? "100%" : "auto";
/*     filtersBackgroundColor = showFilters ? "grey" : "transparent"; // Apply grey background only when filters are shown
*/    
  }

  return (
    <Container>
      {/* Conditionally render Typography based on screen size */}
      {!isMobile && (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5rem" }}>
          <VscFilter className="filters-icon" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}/>
          <Typography
            variant="h6"
            align="center"
            gutterBottom
            marginBottom="1rem"
            marginTop="0"
            sx={{ fontFamily: "Adys Regular", fontWeight: "400", fontSize: "2rem", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}
          >
            Моля, задайте критериите си тук
          </Typography>
        </div>
      )}

      {/* Button to toggle filters on mobile */}
      {isMobile && (
        <Box textAlign="center" marginBottom="20px" marginTop="30px">
          <Button
            variant="contained"
            onClick={ ()=> {openFiltersModal(true)}}
            style={{
              borderRadius: 10,
              width: "20rem",
              height: "5rem",
              fontSize: "1.8rem",
              backgroundImage: "linear-gradient(to left, rgb(178, 253, 165), rgb(95, 209, 171))",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center"
            }}
          >
            <LuFilter style={{ marginRight: "0.8rem", fontSize: "2rem" }} />
            {showFilters ? "Филтри" : "Филтри"}
          </Button>
        </Box>
      )}

      {/* Filters */}
      {!isMobile && (
        <Grid
        container
        spacing={2}
        justifyContent={isMobile ? "center" : "center"}
        style={{
          display: filtersDisplayStyle,
          backgroundColor: filtersBackgroundColor,
          width: filtersWidth,
          margin: "0 auto",
          borderRadius: isMobile ? 10 : 0,
        }}
      >
        {module === "adoption" && (
          <Grid item>
            <AdoptFromFilter setAdoptFrom={handleAdoptFrom} filters={filters} isMobile={isMobile}/>
          </Grid>
        )}
        
        <Grid item>
          <PetTypeFilter setPetType={handleTypeSelect} filters={filters} isMobile={isMobile}/>
        </Grid>
        <Grid item>
          <DogSexSearchDropdown setSex={handleSexSelect} filters={filters} isMobile={isMobile} width={170}/>
        </Grid>
        <Grid item>
          <DogBreedSearchDropdown selectedType={selectedType} setBreed={handleBreedSelect} filters={filters} isMobile={isMobile} width={"100%"}/>
        </Grid>
        <Grid item>
          <DogColorSearchDropdown setColor={handleColorSelect} filters={filters} isMobile={isMobile}/>
        </Grid>
        {module === "partner" && (
          <Grid item>
            <DistrictSearchDropdown setSelectedDistrict={setSelectedDistrict} setDistrictName={handleSelectedDistrict} filters={filters} isMobile={isMobile}/>
          </Grid>
        )}
        
        
        {isMobile && (
          <Grid item>
            <DogColorSearchDropdown setColor={handleColorSelect} filters={filters} isMobile={isMobile}/>
          </Grid>
        )}

        {/* Second Line */}
        <Grid container item justifyContent="center" spacing={ module === "partner" ? 4 :16}>
          <Grid item>
            <Box textAlign="center">
              <DogAgeSlider
                defaultValue={[0, 12]}
                description="Възраст (години)"
                value={filters.age}
                setValue={handleAge}
                isMobile={isMobile}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box textAlign="center">
              <DogWeigthSlider
                defaultValue={[0, 80]}
                description="Тегло (кг)"
                value={filters.weight}
                setValue={handleWeight}
                isMobile={isMobile}
              />
            </Box>
          </Grid>

          {module === "partner" && (
            <Grid item style={{marginLeft: !isMobile ? "6.2rem" : "0"}}>
              <Box textAlign="left">
                <CheckboxFilters 
                  vaccinated={filters.vaccinated} 
                  setVaccinated={handleVaccinatedSelect} 
                  originalBreed={filters.originalBreed} 
                  setOriginalBreed={handleOriginalBreedSelect}
                  isMobile={isMobile}
                />
              </Box>
            </Grid>
          )}
          

          <Grid item style={{marginLeft: module === "adoption" ? "5.8rem" : ""}}>
            <Box textAlign="center">
              {isMobile ? null : (
                <>
                  {module === "adoption" && (
                    
                      <Grid item style={{ marginBottom: "1rem"}}>
                        <DistrictSearchDropdown setSelectedDistrict={setSelectedDistrict} setDistrictName={handleSelectedDistrict} filters={filters} isMobile={isMobile}/>
                      </Grid>
                    
                  )}
                  <Grid item>
                    <TownSearchDropdown selectedDistrictId={selectedDistrict} setTown={handleSelectedTown} filters={filters} isMobile={isMobile}/>
                  </Grid>  
                </>
                
              )}
              <Box
                textAlign="right"
                marginTop={2}
                marginLeft={isMobile ? 30 : 4}
                marginBottom={isMobile ? 3 : 0}
                sx={{ 
                  display: "flex", 
                  flexDirection: "row", 
                  justifyContent: "center", 
                  alignItems: "start",
                  gap: "1.5rem"
                }}
              >
                <Tooltip 
                  title={showFavourites == true ? "Скрий Харесани": "Покажи Харесани"}
                  arrow
                  slotProps={{
                    popper: {
                      sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                          {
                            marginTop: '0.5rem',
                            fontSize: '1.3rem',
                            fontFamily: 'Adys Regular',
                            color: 'rgba(0, 0, 0, 0.87)',
                            maxWidth: 220,
                            backgroundColor: '#f5f5f9',
                            border: '1px solid #dadde9',
                          },
                      },
                    },
                  }}
                >
                  <IconButton
                    onClick={()=>{
                      setShowFavourites(!showFavourites);
                      setLoading();
                    }}
                    sx={{
                      borderRadius: "50%",
                      position: "relative",
                    }}
                    size="small"
                  >
                    {!showFavourites && (
                      <IconWithBadge
                        icon={
                          <FavoriteBorderOutlinedIcon sx={{
                            fontSize: "4rem",
                            color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)",
                            borderRadius: "50%", 
                            cursor: "pointer", 
                            padding: "4px", 
                            transition: "0.3s all", 
                            "&:hover": { 
                                backgroundColor: "rgba(0,0,0,0.1)"
                            }
                          }}/>
                        }
                        badgeCount={favouritesCount}     
                      />
                    )}

                    {showFavourites && (
                      <IconWithBadge
                      icon={
                        <FavoriteOutlinedIcon sx={{
                          color: "rgb(180, 63, 80)",
                          fontSize: "4rem",
                          borderRadius: "50%", 
                          cursor: "pointer", 
                          padding: "4px", 
                          transition: "0.3s all", 
                          "&:hover": { 
                              backgroundColor: "rgba(0,0,0,0.1)"
                          }
                        }}/>
                      }
                      badgeCount={favouritesCount}     
                    />
                    )}
                    
                  </IconButton>
                </Tooltip>
                <div>
                  <Button
                    variant="contained"
                
                    onClick={() => {setLoading()}}
                    sx={{ 
                      borderRadius: 10,
                      width: "15rem",
                      background: 'linear-gradient(to left, rgb(178, 253, 165), rgb(95, 209, 171))',
                      color: "rgba(0,0,0,0.7)",
                      fontFamily: "Adys Regular",
                      fontWeight: "400",
                      fontSize: "1.7rem",
                      "&:hover": {
                        color: "#fff",
                        background: "linear-gradient(to left, rgb(178, 253, 165), rgb(95, 209, 171))"
                      }
                    }}
                  >
                    <SearchOutlinedIcon sx={{ fontSize: "2.2rem", marginLeft: "-1rem", marginRight: "0.5rem"}} />
                    Търси
                  </Button>
                  <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    marginBottom=""
                    marginTop="1rem"
                    marginLeft="1rem"
                   
                    onClick={() => {clearFilters()}}
                    sx={{ 
                      fontFamily: "Adys Regular", 
                      fontWeight: "400", 
                      fontSize: "1.3rem", 
                      color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", 
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      "&:hover": {
                        textDecoration: "underline",
                        color: themes.main.button_main
                      } 
                    }}
                  >
                    <FilterAltOffOutlinedIcon />
                    Изчисти филтрите
                  </Typography>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
        </Grid>
      )}
      
      
    </Container>
  );
};
