import React from "react";
import { useTheme } from "@mui/material";
import { props } from "cypress/types/bluebird";
import { SingleSelectFilter } from "./SingleSelectFilter";

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getAutocompleteStyles } from "./autoCompleteHelper";
import { autocompleteOptions } from "./autoCompleteHelper";
import { Box } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import GiteOutlinedIcon from '@mui/icons-material/GiteOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { singleFilter } from "../../../data/mui_styles";

type PetTypeFilterProps = {
    setAdoptFrom: (type: string) => void;
  filters: any;
  isMobile: boolean;
};

export const AdoptFromFilter: React.FC<PetTypeFilterProps> = ({setAdoptFrom, filters, isMobile
}) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.light;

    const adoptionTypes = {
        title: "Осинови от",
        options: [
            { title: "Приют", type: "shelter" },
            { title: "Човек", type: "person" },
        ]
    };

    const options = adoptionTypes.options;
    const title = adoptionTypes.title;

  /*   const newTheme = (theme: any) => createTheme({
        ...theme,
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        
                    },
                    tag: {
                        fontSize: "1rem"
                    }, 
                    option: {
                        fontFamily: "Ubuntu",
                        fontWeight: "400",
                        
                    },
                    inputRoot: {
                        
                    },
                    inputFocused: {
                        borderColor: "#f5f5f5"
                    },
                    input: {
                        fontFamily: "Ubuntu",
                        fontWeight: "400",
                        fontSize: "1.7rem",
                        
                    },
                    listbox: {
                        background: "rgba(255,255,255, 0.7)",
                    },
                    paper: {
                        background: "rgba(255,255,255, 0.7)",
                    },
                    popper: {
                        background: "rgba(255,255,255, 0.7)",
                    }
                }
            }
        }   
    }); */

    const newTheme = (theme: any) => createTheme({
        ...theme,
        components: !isMobile === true ? singleFilter.web : singleFilter.mobile 
    });


    return (
    <Stack sx={{ width: isMobile ? 145 : 170 }}>
        <ThemeProvider theme={newTheme}>
            <Autocomplete
                limitTags={2}
                {...autocompleteOptions}
                id="size-small-outlined-multi"
                size="small"
                options={options}
                getOptionLabel={(option: any) => {
                    if(filters.adoptFrom == '')
                        return ''
                    else
                        return option.title
                }}
                sx={getAutocompleteStyles("#52af77")}
                onChange={(e, v) => {
                    if(v){
                      if(v.type) {
                        setAdoptFrom(v.type);
                      }
                    }
                    else {
                        setAdoptFrom('');
                    }
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        value={filters.type} 
                        label={title}
                        placeholder={title}
                        sx={{backgroundColor: "#fff"}}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {params.inputProps.value !== '' ? (
                                        <>
                                          {params.inputProps.value === "Приют" ? (
                                              <InputAdornment position="start">
                                                  <GiteOutlinedIcon style={{ fontSize: "2.2rem" }}/>
                                              </InputAdornment>
                                          ): (<InputAdornment position="start">
                                                  <PermIdentityOutlinedIcon style={{ fontSize: "2.2rem" }}/>
                                              </InputAdornment>
                                          )} 
                                        </>
                                    ): (<></>)}
                                       
                                </>
                            ),
                        }}
                    />
                )} 

                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <>
                        {option.title === "Приют" ? (
                            <GiteOutlinedIcon style={{ color: "#52af77", fontSize: "2rem" }} />
                        ) : (
                            <PermIdentityOutlinedIcon style={{ color: "#52af77", fontSize: "2rem" }} />
                        )}
                    </>
                      <div style={{marginLeft:"5px"}}>{option.title}</div>
                  </Box>
                )} 
            />
        </ThemeProvider>
    </Stack>
    );
};
