import React, { useState, useEffect } from "react";
import "./faq.css";
import CustomAccordion  from "./Accordion";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

export default function FAQ() {

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show");
            } else {
              entry.target.classList.remove("show");
            }
          });
        });
    
        const hiddenElementsLeft = document.querySelectorAll(".hide-left");

        hiddenElementsLeft.forEach((el) => observer.observe(el));
      },[]);


    let dog1 = require('../../../assets/mini_dog1.png');
    let dog2 = require('../../../assets/mini_dog2.png');
    let dog3 = require('../../../assets/mini_dog3.png');
    let dog4 = require('../../../assets/mini_dog4.png');
    let dog5 = require('../../../assets/mini_dog5.png');
    let dog6 = require('../../../assets/mini_dog6.png');

    const [accordions, setAccordions] = useState([
        {
            id: "pet1",
            image: dog1,
            expanded: false,
            q: "Кога платформата ще бъде готова за използване?",
            a: "PetConnect е дългосрочен проект, чиито функционалности постепенно ще бъдат представяни пред потребителите. Първите два модула - 'Намери партньор' и 'Осинови Приятел' - планираме да бъдат налични от месец Декември 2024.",
            socials: false,
            label: "faq_app_state"
        },
        {
            id: "pet2",
            image: dog2,
            expanded: false,
            q: "Безплатно ли е?",
            a: "Да. Всички планирани функционалности ще бъдат напълно безплатни за потребителите на PetConnect.",
            socials: false,
            label: "faq_free_to_use"
        },
        {
            id: "pet3",
            image: dog3,
            expanded: false,
            q: "Има ли мобилно приложение?",
            a: "Към момента няма, но планираме създаването на такова.",
            socials: false
        },
        {
            id: "pet4",
            image: dog4,
            expanded: false,
            q: "Как мога да се свържа с екипа на PetConnect?",
            a: "Можете да се свържете с нас като изпратите имейл на help@petconnect.global или като ни пишете в нашите социални мрежи:",
            socials: true,
            label: "faq_contacts"
        }
    ]);

    return (
        <div className="faq-container" >
            <div className="u-center-text u-margin-bottom-big">
                <h2 className="heading-secondary">
                    Често задавани въпроси
                </h2>
            </div>
            
            <div className="accordions-container hide-left">
                {accordions.map((accordion)=>{
                    return ( 
                        <div style={{ marginTop: "0.1vw" }}>
                            <CustomAccordion config={ accordion } accordions={ accordions } setAccordions={ setAccordions } />
                        </div> );
                })}
            </div>
            
            
        </div>
    );
}