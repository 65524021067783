import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import "./overrides.css";

type CheckboxProps = {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMobile: any;
};

type CheckboxFilterProps = {
  vaccinated: any;
  setVaccinated: (type: any) => void;
  originalBreed: any;
  setOriginalBreed: (type: any) => void;
  isMobile: any;
};

const HorizontalCheckboxes: React.FC<CheckboxProps> = (props) => {
  const { label, checked, onChange, isMobile} = props;

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: {
      MuiTypography: {
        styleOverrides: {
            root: {
                fontFamily: "Adys Regular", 
                fontWeight: "400", 
                width: "max-content",
                marginLeft: !isMobile ? "0" : "-0.8rem",
                fontSize: isMobile ? "1rem !important" : ""
            },
        }
      }
    }   
});

  return (
    <ThemeProvider theme={newTheme}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            sx={{ 
              "& .MuiSvgIcon-root": { fontSize: !isMobile ? 28 : 14 }
            }}
          />
        }
        label={label}
      />
    </ThemeProvider>
  );
};

export const CheckboxFilters: React.FC<CheckboxFilterProps> = ({vaccinated, setVaccinated, originalBreed, setOriginalBreed, isMobile}) => {
 
  return (
    <div style={{ display: "flex", flexDirection: "column"}}>
      <div style={{ marginBottom: "", width: "fit-content"}}>
        <HorizontalCheckboxes
          label="Напълно ваксиниран(а)"
          checked={vaccinated}
          onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setVaccinated(e.target.checked)}}
          isMobile={isMobile}  
        />
      </div>
      <div style={{ marginBottom: "", width: "fit-content"}}>
        <HorizontalCheckboxes
            label="Потвърдено родословие"
            checked={originalBreed}
            onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setOriginalBreed(e.target.checked)}}
            isMobile={isMobile}  
          />
      </div>
        
    </div>
  );
};
