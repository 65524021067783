import { useState, useEffect, useRef } from "react";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import MaleOutlinedIcon from '@mui/icons-material/MaleOutlined';
import CakeIcon from '@mui/icons-material/Cake';
import { TfiRuler } from "react-icons/tfi";
import { FaWeightScale } from "react-icons/fa6";

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Button from "@mui/material/Button";
import "./dogProfileCard.css";
import FileUpdate from './FileUpdate';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";


const DogProfileCard = ({ pet, setPets, setShowModal, isMobile, mode }: any) => {
    const inputRef = useRef<any>(null);

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
    }

    const image_update_styles = {
        width: "11vw",
        height: "10vw",
        marginBottom: "-1vw",
        marginLeft: "-0.4vw"
    }

    return (
        <div 
            className={`${mode === "dark" ? "card_containter dark_theme" : "card_containter light_theme"}`} >
            <div className="card-content">            
                {pet.image != "" ? (

                    <div onClick={ handleImageClick} className="image-wrapper" style={{ border: "2px solid rgba(40, 180, 133, 1)", opacity: "1", width: isMobile ? "7rem" : "16rem", height: isMobile ? "7rem" : "16rem", marginTop: isMobile ? "2rem" : "3vw"}}>
                        <img src={require('../../../../assets/' + pet.image)} style={{width: "100%", height: "100%"}} /> 

                        <div className="uploader">
                            <input type="file" ref={inputRef}  onChange={handleImageChange} className="edit-image" />
                            
                            <div style={{position:"absolute", bottom: "1.6rem", left: "4.5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-1vw", marginLeft: "-0.4vw"}}>
                                <CameraAltOutlinedIcon sx={{fontSize: "2vw", color: "#fff"}}></CameraAltOutlinedIcon>
                                <p style={{ fontFamily: "Adys Bold" }}>Смени</p>
                            </div>
                            
                        </div>
                    </div>
                ): (
                    <div className="image-wrapper" style={{ opacity: "1", width: "16rem", height: "16rem"}}>
                        <img src={require('../../../../assets/' + pet.image)} style={{width: "100%", height: "100%"}} /> 
                    </div>
                )}

                <div className="dog-details-container">
                    <div className="dog-details-header">
                        <p 
                            className="dog-name" 
                            style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" 
                                
                            }}
                        >
                            {pet.name}
                        </p> 
                        <span 
                            style={{ 
                                color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", 
                                fontFamily: "Ubuntu", 
                                fontWeight: "200", 
                                fontSize: isMobile ? "1.3rem" : "1.5vw", 
                                marginBottom: "0.4vw" 
                            }}
                        >/</span> 
                        <p 
                            className="dog-breed" 
                            style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>
                                {pet.breed.title}
                        </p>
                        
                        {/* EDIT BUTTON */}
                        <Tooltip 
                            title="Редактиране" 
                            arrow
                            slotProps={{
                                popper: {
                                    sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                        marginTop: '0.5rem',
                                        fontSize: '1.4rem',
                                        fontFamily: 'Adys Regular',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        maxWidth: 220,
                                        backgroundColor: '#f5f5f9',
                                        border: '1px solid #dadde9',
                                        },
                                    },
                                },
                            }}
                            onClick={ () => {setShowModal("editPetProfile", pet)}}
                        >
                            <div className="edit-btn">
                                <ModeEditOutlinedIcon className="edit-btn-icon" sx={{ fontSize: isMobile ? "1.3rem" : "1.6vw", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.8)"}}></ModeEditOutlinedIcon>
                            </div>
                        </Tooltip>
                    </div>

                    <span className="result_card-separator-line" style={{ marginLeft: "-7vw", marginTop: "1vw", marginBottom: isMobile ? "0.5rem" : "-1vw" }}></span>

                    <div className="main-container" style={{ width: isMobile ? "90%" : "80%" }}>
                        <div className="left-container">

                            <div style={{display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "1vw" }}>

                                <div className="profile-data-row"> 
                                    <FmdGoodOutlinedIcon className="profile-data-icon" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}/>
                                    <p className="dog-address" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>{pet.city.title+ ', ' + pet.region.title}</p>
                                </div>

                                <div className="profile-data-row">
                                    {pet.sex == "Мъжки" && (
                                        <MaleOutlinedIcon className="profile-data-icon" style={{ color: "#1073d6" }}/>
                                    )}

                                    {pet.sex == "Женски" && (
                                        <FemaleOutlinedIcon className="profile-data-icon" style={{ color: "#bf1077" }}/>
                                    )}
                                    
                                    <p className="dog-sex" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>{pet.sex}</p>
                                </div>
                                
                                {/* COLORS */}
                                <div className="profile-data-row">
                                    {pet.color.map((c: any) => {
                                        return <div className="profile-data-row">

                                           {mode === "light" ? (
                                                <>
                                                    {c.hex == "#fff" ? (
                                                        <span className="color-indicator" style={{ backgroundColor: c.hex, border: "1px solid #000"}} ></span>
                                                    ): (
                                                        <span className="color-indicator" style={{ backgroundColor: c.hex}} ></span>     
                                                    )} 
                                                </>
                                           ) : (
                                                <>
                                                    {c.hex == "#000" ? (
                                                        <span className="color-indicator" style={{ backgroundColor: c.hex, border: "1px solid rgba(255, 255, 255, 0.6)"}} ></span>
                                                    ): (
                                                        <span className="color-indicator" style={{ backgroundColor: c.hex}} ></span>     
                                                    )} 
                                                </>
                                           )} 
                                            
                                           
                                            <p className="dog-color" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>{c.title}</p>
                                        </div>    
                                    })}
                                </div>
                                    
                            </div>
                        </div>

                        <div className="right-container">
                            <div style={{ textAlign: "left"}}>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "start", marginBottom: "1vw" }}>
                                   
                                    <div className="profile-data-row">
                                        <FaWeightScale className="profile-data-icon" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", fontSize: isMobile ? "1.2rem" : "1.2vw", marginRight: isMobile ? "0.1rem" : "0" }}/>
                                        <p style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>{pet.weight + " кг"}</p>
                                    </div>
                                    
                                    <div className="profile-data-row"> 
                                        <TfiRuler className="profile-data-icon" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", fontSize: isMobile ? "1.2rem" : "1.2vw", marginRight: isMobile ? "0.1rem" : "" }}/>
                                        <p style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}>{pet.height + " см"}</p>
                                    </div>
                                    
                                    <div className="profile-data-row">
                                        <CakeIcon className="profile-data-icon" style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)" }}/>
                                        <p style={{ color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)", marginTop: isMobile ? "0.2rem" : "0" }}>6 м</p> 
                                    </div>

                                </div>  
                            </div>
                            
                        </div>

                        
                    </div>
                    
                </div>               
            </div>  

             {/*CHECKBOXES  */}
            <div style={{ 
                display: "flex", 
                flexDirection: "row", 
                justifyContent: "center", 
                alignItems: "center", 
                gap: isMobile ? "1rem" : "3rem", 
                marginTop: isMobile ? "1rem" : "3rem", 
                marginBottom: isMobile ? "1rem" : "0"
            }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                    
                    <div className="checbox-container">
                        {pet.vaccinated === true ? (
                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                            ): (
                            <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                        )}
                            <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Напълно ваксинирано</p>
                    </div>

                    <div className="checbox-container">
                        {pet.original_breed === true ? (
                        <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                        ): (
                            <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                        )}
                        <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Потвърдено родословие</p>
                    </div>            
                </div>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                    <div className="checbox-container">
                        {pet.chip === true ? (
                        <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                        ): (
                            <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                        )}
                        <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Чипирано</p>
                    </div>

                    <div className="checbox-container">
                        {pet.castrated === true ? (
                        <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                        ): (
                            <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                        )}
                        <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Кастрирано</p>
                    </div>    
                </div>    

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                    <div className="checbox-container">
                                    {pet.partner_finder === true ? (
                                    <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                    ): (
                                        <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                    )}
                                    <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Намери партньор</p>
                    </div>

                    <div className="checbox-container">
                        {pet.adoption === true ? (
                        <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                        ): (
                            <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: isMobile ? "1.2rem" : "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                        )}
                        <p style={{width: "100%", color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.5)"}}>Осинови приятел</p>
                    </div>    
                </div>        
            </div>
        </div>
    );
};

export default DogProfileCard;