const petsData = [
    {
        uuid: "0c7bbdec-b4a6-4b32-b7da-b0740023d2f4",
        name: "Арес",
        type: "dog",
        breed: "Питбул",
        region: "Пловдив",
        city: "Пловдив",
        sex: "Мъжки",
        color: {
            label: "Черен",
            value: "#000"
        },
        weight: 32,
        age: 2.5,
        vaccinated: true,
        original_breed: false,
        image: 'ares.jpg',
        gallery: [
            "ares.jpg",
            "ares1.jpg",
            "ares2.jpg",
            "ares3.jpg"
        ],
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut!",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
    {
        uuid: "5fe86211-82ed-4988-a274-fe4d736ef2ef",
        name: "Фрея",
        type: "dog",
        breed: "Голдън ретривър",
        region: "София-град",
        city: "София",
        sex: "Женски",
        color: {
            label: "Рус",
            value: "#E5A657"
        },
        weight: 25,
        age: 1,
        vaccinated: true,
        original_breed: true,
        image: "freya.jpg",
        gallery: [
            "freya.jpg",
            "freya1.jpg",
            "freya2.jpg",
            "freya3.jpg"
        ],
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut!",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
    {
        uuid: "2852d7a8-080f-453b-a2e9-a9cf1c3c4a43",
        name: "Айра",
        type: "dog",
        breed: "Лабрадор",
        region: "София-град",
        city: "София",
        sex: "Женски",
        color: {
            label: "Рус",
            value: "#E5A657"
        },
        weight: 30,
        age: 3,
        vaccinated: true,
        original_breed: true,
        image: "aira.jpg",
        gallery: [
            "aira.jpg",
            "aira1.jpg",
            "aira2.jpg",
        ],
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut!",
        document_image: "",
        favourite: false,
        adoptionFrom: "shelter"
    },
    {
        uuid: '1b560d63-8d5d-4e55-9c98-084885e3cbd0',
        name: "Роки",
        type: "dog",
        breed: "Пинчер",
        region: "Варна",
        city: "Варна",
        sex: "Мъжки",
        color: {
            label: "Бял",
            value: "#fff"
        },
        weight: 2.5,
        age: 1.5,
        vaccinated: false,
        original_breed: true,
        image: "roki.jpg",
        gallery: [
            "roki.jpg",
            "roki1.jpg",
            "roki2.jpg",
        ],
        description: "",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
    {
        uuid: "6ad09ca6-3014-4b20-88f7-e488e2fa7eab",
        name: "Мая",
        type: "cat",
        breed: "Руска синя котка",
        region: "Варна",
        city: "Варна",
        sex: "Женски",
        color: {
            label: "Сив",
            value: "#AFB2BA"
        },
        weight: 2,
        age: 1.5,
        vaccinated: true,
        original_breed: true,
        image: "maya.jpg",
        gallery: [
            "maya.jpg",
            "maya1.jpg",
            "maya2.jpg",
            "maya3.jpg"
        ],
        description: "",
        document_image: "",
        favourite: false,
        adoptionFrom: "shelter"
    },
    {
        uuid: "1183d498-ed97-4ca8-992a-d11d361d9a59",
        name: "Бинг",
        type: "cat",
        breed: "Бомбайска котка",
        region: "Бургас",
        city: "Айтос",
        sex: "Мъжки",
        color: {
            label: "Черен",
            value: "#000"
        },
        weight: 2,
        age: 3,
        vaccinated: false,
        original_breed: true,
        image: "bing.jpg",
        gallery: [
            "bing.jpg",
            "bing1.jpg",
            "bing2.jpg",
            "bing3.jpg"
        ],
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut!",
        document_image: "",
        favourite: false,
        adoptionFrom: "shelter"
    },
    {
        uuid: "15bcc33c-7f52-49fe-aa3f-f2c605181d3f",
        name: "Бъкс",
        type: "dog",
        breed: "Мопс",
        region: "Бургас",
        city: "Созопол",
        sex: "Мъжки",
        color: {
            label: "Черен",
            value: "#000"
        },
        weight: 7,
        age: 2,
        vaccinated: true,
        original_breed: true,
        image: 'bucks.jpg',
        gallery: [
            "bucks.jpg",
            "bucks1.jpg",
            "bucks2.jpg",
            "bucks3.jpg"
        ],
        description: "",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
    {
        uuid: "01800f14-9eba-44d5-b4a8-985fc36c751a",
        name: "Веси",
        type: "cat",
        breed: "Манчкин",
        region: "София-град",
        city: "София",
        sex: "Женски",
        color: {
            label: "Бял",
            value: "#fff"
        },
        weight: 6,
        age: 2,
        vaccinated: true,
        original_breed: true,
        image: 'vesi.jpg',
        gallery: [
            "vesi.jpg",
            "vesi1.jpg",
            "vesi2.png"
        ],
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam dicta doloremque blanditiis consequuntur itaque sunt odio pariatur! Accusantium cumque non nesciunt reprehenderit veritatis, repellat, ad veniam, magnam minima earum aut!",
        document_image: "",
        favourite: false,
        adoptionFrom: "shelter"
    },
    {
        uuid: "0fea756b-71a8-46bf-a9ef-076605954e93",
        name: "Рекс",
        type: "dog",
        breed: "Немска овчарка",
        region: "София-град",
        city: "София",
        sex: "Мъжки",
        color: {
            label: "Кафяв",
            value: "#834545"
        },
        weight: 26,
        age: 3,
        vaccinated: true,
        original_breed: true,
        image: 'reks.jpg',
        gallery: [
            "reks.jpg",
            "reks1.jpg",
            "reks2.jpg",
            "reks3.jpg"
        ],
        description: "",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
    {
        uuid: "8fe28130-c526-4a8a-9046-e45c0918628c",
        name: "Страхил",
        type: "dog",
        breed: "Мопс",
        region: "Враца",
        city: "Мездра",
        sex: "Мъжки",
        color: {
            label: "Черен",
            value: "#000"
        },
        weight: 11,
        age: 2,
        vaccinated: true,
        original_breed: true,
        image: 'reks.jpg',
        gallery: [
            "reks.jpg",
            "reks1.jpg",
            "reks2.jpg",
            "reks3.jpg"
        ],
        description: "",
        document_image: "",
        favourite: false,
        adoptionFrom: "person"
    },
];

export default petsData;