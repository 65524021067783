import React from "react";
import { useState } from "react";

import  { useMediaQuery} from "@mui/material";

import { Navbar } from "../../navbar/Navbar";
import { FiltersLayout } from "../../humpme/filters/FiltersLayout";
import { ResultsSection } from "../../humpme/resultsSection/resultsSection";
import PaginationOutlined  from "../../humpme/pagination/Pagination";
import PetProfile from "../../common/profile/PetProfile";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { FaHandHolding } from "react-icons/fa6";
import { IoPaw } from "react-icons/io5";

import themes from "../../../data/themes";
import "./adoption.css";
import FiltersModal from "../../common/modal/FiltersModal";
import { Footer } from "../../landing/footer/Footer";
import { useSelector } from "react-redux";

export const AdoptionHome = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const [selectedPet, setSelectedPet] = useState<any>({});
  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  const [favourites, setFavourites] = useState<Array<{}>>([]);
  const [favouritesCount, setFavouritesCount] = useState(0);
  const [showFavourites, setShowFavourites] = useState(false);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [navbarOpacity, setNavbarOpacity] = useState(1);

  const [filters, setFilters] = useState({
    adoptFrom: "",
    petType: "",
    sex: "",
    breed: "",
    color: "",
    district: "",
    city: "",
    age: [0, 12],
    weight: [0, 80],
    vaccinated: false,
    originalBreed: false
  });

  const isMobile = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint as needed
  const mode = useSelector((state: any) => state.theme.mode);

  const handleLoading = () => {
    setIsLoading(true);
    let resultsCont = document.getElementById('results');
    resultsCont?.classList.add('hidden');

    setTimeout(()=> {
      resultsCont?.classList.remove('hidden');
      setIsLoading(false);
    }, 2500);
  }

  const handleFiltersCleared = (value: boolean) => {
    setFiltersCleared(value);

    if(value == true) {
      setFilters({
        adoptFrom: "",
        petType: "",
        sex: "",
        breed: "",
        color: "",
        district: "",
        city: "",
        age: [0, 12],
        weight: [0, 80],
        vaccinated: false,
        originalBreed: false
      });
    }
  };

  const handleFilters = (filter: any) => {
    setFilters((state) => ({
      ...state,
      [filter.name]: filter.value,
    }));
  }

  const handleInfoPopUp = function(pet: any) {
    setShowInfoPopUp(true);
    setSelectedPet(pet);
  }

  const handleSelectedPet = function(pet: any) {
    setShowInfoPopUp(true);
    setSelectedPet(pet);
  }

  const handleFavourites = function(pet: any, operation: any) {
    let newArray = favourites;
  
    if(operation === "add") {
      newArray.push(pet);
      setFavourites(newArray);
      setFavouritesCount(newArray.length);
    }
    else if(operation === "remove") {
      let index = newArray.indexOf(pet);
      if(index !== -1) {
        newArray.splice(index, 1);
        setFavourites(newArray);
        setFavouritesCount(newArray.length);
      }
    }
  }

  const handleFiltersModal = function (action: boolean) {
    setShowFiltersModal(action);
  }

  window.addEventListener('scroll', () => {
    
    if (document.body.scrollTop <= 0) {
      let opacity = (window.scrollY / 500);
      if((navbarOpacity - opacity) < 0) {
        setNavbarOpacity(0);
      }
      else {
        setNavbarOpacity(1 -opacity);
      }
    }
    else {
      let opacity = (window.scrollY / 500);
      if(navbarOpacity + opacity > 1) {
        setNavbarOpacity(1);
      }
      else {
        let newOpacity = navbarOpacity + opacity;
        setNavbarOpacity(newOpacity);
      }  
    }
  })

  return (
    <>
      {/* NAVBAR */}
      <div 
          style={{
              opacity: navbarOpacity.toString(),
              transition: "all 0.5s",
              backgroundImage: "linear-gradient(to left, rgba(178, 253, 165, 1), rgba(95, 209, 171, 1))",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              position: "fixed",
              top: "0",
              right: "0",
              zIndex: "1000"
            }}
      >
        <Navbar />
      </div>

       {/* FILTERS FOR MOBILE */}          
       <FiltersModal
        isMobile={isMobile} 
        showModal={showFiltersModal} 
        setShowModal={handleFiltersModal} 
        module="partner"
        filters={filters}
        setFilters={handleFilters} 
        setLoading={handleLoading} 
        setFiltersCleared={handleFiltersCleared}
      />

      <PetProfile showInfoPopUp={showInfoPopUp} setShowInfoPopUp={setShowInfoPopUp} pet={selectedPet} setFavourites={handleFavourites} module="adoption"/>

      <div className="hump-content">
        <div style={{ marginTop: "12rem", textAlign: "center" }}>
            <div className="" style={{ marginBottom: "2rem", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
              <p className="hump-welcome_message-name" style={{ color: themes.main.title }}>Осинови</p> 
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "-1rem"
              }}>
                <IoPaw 
                  className="module-icon" 
                  style={{
                    color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.7)", 
                    fontSize: "2.5rem", 
                    marginBottom: "-1.9rem",
                    // filter: "drop-shadow(0 0 3px rgb(95, 209, 171))"
                    animation: "glow 2s linear infinite"
                  }}
                />
                <FaHandHolding 
                  className="module-icon" 
                  style={{
                    color: mode === "dark" ? "rgba(255, 255, 255, 0.6)" : "rgba(0,0,0,0.7)", 
                    fontSize: "3.5rem",
                    // filter: "drop-shadow(0 0 3px rgb(95, 209, 171))"
                    animation: "glow 2s linear infinite"
                  }}
                />
              </div>
              <p className="hump-welcome_message-name-reversed" style={{ color: themes.main.title }}>приятел</p> 
           
            </div>           
        </div>
   
        {/* FILTERS */}
        <div className="hump-showFromLeft">
          <FiltersLayout 
            module={"adoption"}
            isMobile={isMobile}
            filters={filters} 
            openFiltersModal={handleFiltersModal}
            setFilters={handleFilters} 
            setLoading={handleLoading} 
            setFiltersCleared={handleFiltersCleared}
            favouritesCount={favouritesCount}
            showFavourites={showFavourites}
            setShowFavourites={setShowFavourites}/>         
        </div>

        {/* RESULTS */}
        <div id="results">
          <ResultsSection
            module="adoption" 
            isLoading={isLoading} 
            filters={filters} 
            filtersCleared={filtersCleared} 
            setFiltersCleared={handleFiltersCleared} 
            openPetProfile={handleInfoPopUp} 
            setSelectedPet={handleSelectedPet}
            setFavourites={handleFavourites}
            favourites={favourites}
            showFavourites={showFavourites}
            />
        </div>
        
        
        {isLoading && (
          <div className="loader-cont">
            <svg id="loader" className="">
              <circle cx="50" cy="50" r="50">
  
              </circle>
            </svg>
          </div>
        )}

        <div style={{ marginTop: isMobile ? "6rem" : "4rem"}}>
          <Footer theme={mode}/>
        </div>
        
      </div>
    </>
  );
};
