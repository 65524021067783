import { UserManager, User } from "oidc-client-ts";
import oidcConfig from "../config/oidcConfig";

class OidcService {
  private userManager: UserManager;

  constructor() {
    this.userManager = new UserManager(oidcConfig);
  }

  getUser(): Promise<User | null> {
    return this.userManager.getUser();
  }

  login(redirectPath: string): Promise<void> {
    return this.userManager.signinRedirect({ state: {redirectPath} });
  }

  async handleCallback(): Promise<User> {
    const user = await this.userManager.signinRedirectCallback();

    return user;
  }

  logout(): Promise<void> {
    return this.userManager.signoutRedirect();
  }
}

export default new OidcService();