import React, { useEffect } from "react";
import { FaDog, FaPhotoVideo, FaSearchLocation  } from "react-icons/fa"
import { BiCommentDetail } from "react-icons/bi";
import "./landingSeparator.css";

export const LandingSeparator: React.FC = () => {

  return (
    <section className="section-separator">
      
      <div className="features-heading">
        <p className="">Защото ни е грижа за Вашите домашни любимци</p>
      </div>
    
    </section>
  );
};
