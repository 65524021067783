const colors = [
    {
        title: "Черен",
        hex: "#000"
    },
    {
        title: "Бял",
        hex: "#fff"
    },
    {
        title: "Сив",
        hex: "#AFB2BA"
    },
    {
        title: "Рус",
        hex: "#E5A657"
    },
    {
        title: "Кафяв",
        hex: "#834545"
    },
];

export default colors;