export const getAutocompleteStyles = (primaryColor: string) => ({
  width: "100%",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },

  "& .MuiFormControl-root .MuiInputLabel-animated": {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Adys Regular",
    fontWeight: "400",
    fontSize: "1.7rem"
  },
  "& .MuiFormControl-root .MuiInputLabel-shrink": {
    color: "rgb(95, 209, 171)",
    fontFamily: "Adys Regular",
    fontWeight: "600",
    fontSize: "1.8rem",
    
  },
  "& .MuiAutocomplete-root:focus": {
    borderColor: primaryColor,
  },
  "& .MuiAutocomplete-root:hover": { borderColor: primaryColor },
  "& .MuiSvgIcon-root:hover": {
    color: primaryColor,
  },
  "& .MuiSvgIcon-root:focus": {
    color: primaryColor,
  },
  "& .MuiAutocomplete-root": {
    color: primaryColor,
  },
});

export const getSingleAutocompleteStyles = (primaryColor: string) => ({
  width: "100%",
  marginBottom: "2rem",
  // backgroundColor: "white",
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },

  "& .MuiFormControl-root .MuiOutlinedInput-root": {
    fontSize: "1.3vw",
    borderColor: primaryColor,
  },

  "& .MuiFormControl-root .MuiInputLabel-animated": {
    fontFamily: "Adys Regular",
    fontWeight: "400",
    fontSize: "1.3vw"
  },
  "& .MuiFormControl-root .MuiInputLabel-shrink": {
    fontFamily: "Adys Bold",
    fontSize: "1.3vw",
    color: primaryColor
  },
  "& .MuiAutocomplete-root:focus": {
    borderColor: primaryColor,
  },
  "& .MuiAutocomplete-root:hover": { borderColor: primaryColor },
  "& .MuiSvgIcon-root:hover": {
    color: primaryColor,
  },
  "& .MuiSvgIcon-root:focus": {
    color: primaryColor,
  },
  "& .MuiAutocomplete-root": {
    color: primaryColor,
  },

  '& .MuiAutocomplete-root .MuiAutocomplete-listbox': { overflowY: "hidden" }

});

export const getTextFieldStyles = (primaryColor: string) => ({
  width: "100%",
  marginBottom: "2rem",
  // backgroundColor: "white",
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },

  "& .MuiFormControl-root .MuiOutlinedInput-root": {
    fontSize: "1.3vw",
    borderColor: primaryColor,
  },

  "& .MuiOutlinedInput-root": {
    fontSize: "1.3vw",
    borderColor: primaryColor,
  },

  "& .MuiInputLabel-animated": {
    fontFamily: "Adys Regular",
    fontWeight: "400",
    fontSize: "1.3vw",
  },
  "& .MuiInputLabel-shrink": {
    fontFamily: "Adys Bold",
    fontSize: "1.3vw",
    color: primaryColor
  },
  "& .MuiOutlinedInput-root:focus": {
    borderColor: primaryColor,
  },
  "& .MuiOutlinedInput-root:hover": { borderColor: primaryColor },
  
});

export const getAutocompleteStylesMobile = (primaryColor: string) => ({
  width: "100%",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: primaryColor,
  },

  "& .MuiFormControl-root .MuiInputLabel-animated": {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Adys Regular",
    fontWeight: "400",
    fontSize: "1.3rem"
  },
  "& .MuiFormControl-root .MuiInputLabel-shrink": {
    color: "rgb(82, 175, 119)",
    fontFamily: "Adys Bold",
    fontWeight: "600",
    fontSize: "1.4rem",
    textShadow: "0px 1px 1px rgba(0,0,0,0.8)"
  },
  "& .MuiAutocomplete-root:focus": {
    borderColor: primaryColor,
  },
  "& .MuiAutocomplete-root:hover": { borderColor: primaryColor },
  "& .MuiSvgIcon-root:hover": {
    color: primaryColor,
  },
  "& .MuiSvgIcon-root:focus": {
    color: primaryColor,
  },
  "& .MuiAutocomplete-root": {
    color: primaryColor,
  },
});

export const autocompleteOptions = {
  noOptionsText: "Няма резултати",
  openText: "Отваряне",
  closeText: "Затваряне",
  clearText: "Премахване",
};
