import "./carousel.css";
import React from 'react';
import { useState}  from 'react';
import { Link } from 'react-router-dom';

import { motion, AnimatePresence } from 'framer-motion';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import LensOutlinedIcon from '@mui/icons-material/LensOutlined';

const backdrop = {
    visible: { opacity: 1},
    hidden: { opacity: 0},
}

const modal = {
    hidden: {
        transform: "scale(0)",
        opacity: 0
    },
    visible: {
        transform: "scale(1)",
        opacity: 1,
        transition: {
            
        }
    }
}

const  Carousel = ({ showModal, setShowModal, images }: any) => {
    const [currentImage, setCurrentImage] = useState(0);

    let background = "";

    if(images != undefined) {
        background = require("../../../assets/" + images[currentImage]);
    }

    let backgroundStyle = {
        width: "100%",
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: "200",
        borderRadius: "8px"
    };

    const handleCloseCarousel = function() {
        setShowModal(false);
    }

    const handlePrevious = function() {
        let newIndex = currentImage - 1;

        if(newIndex < 0)
            newIndex = 0;

        setCurrentImage(newIndex);
    }

    const handleNext = function() {
        let newIndex = currentImage + 1;
        setCurrentImage(newIndex);
    }

    return (
        <AnimatePresence mode='wait'>
            { showModal && (
               <motion.div className="modal_backdrop" style={{ left: "-17%" }} variants={ backdrop } initial="hidden" animate="visible" exit="hidden">

                    <motion.div className="modal_main"  variants={ modal }>
                        <div style={ backgroundStyle }>
                            <div className="pet-close-icon-container" style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                <CloseOutlinedIcon 
                                    sx={{ 
                                        fontSize: "3rem",
                                        color: "rgb(82, 175, 119)",
                                        backgroundColor: "rgba(255,255,255, 0.5)", 
                                        cursor: "pointer", 
                                        borderRadius: "50%", 
                                        padding: "3px",
                                        margin: "0.5rem", 
                                        transition: "0.3s all", 
                                        "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                    }}
                                    onClick={handleCloseCarousel} 
                                />
                            </div>

                            <div className="pet-close-icon-container" style={{ position: "absolute", bottom: "50%", width: "100%", transition: "all 1s"}}>
                                {currentImage > 0 && (
                                    <KeyboardArrowLeftIcon 
                                        sx={{ 
                                            fontSize: "5rem",
                                            color: "rgb(82, 175, 119)",
                                            backgroundColor: "rgba(255,255,255, 0.5)", 
                                            cursor: "pointer", 
                                            borderRadius: "50%", 
                                            padding: "3px", 
                                            position: "fixed",
                                            left: "0%", 
                                            transition: "0.3s all", 
                                            "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                        }}
                                        onClick={() => {handlePrevious()}} 
                                    />
                                )}
                                {currentImage < images.length-1 && (
                                     <KeyboardArrowRightIcon 
                                        sx={{ 
                                            fontSize: "5rem",
                                            color: "rgb(82, 175, 119)",
                                            backgroundColor: "rgba(255,255,255, 0.5)", 
                                            cursor: "pointer", 
                                            borderRadius: "50%", 
                                            padding: "3px",
                                            position: "fixed",
                                            right: "0%", 
                                            transition: "0.3s all", 
                                            "&:hover": {backgroundColor: "rgba(0,0,0,0.1)"}
                                        }}
                                        onClick={() => {handleNext()}} 
                                    />
                                )}
                               
                            </div>

                            <div id="carousel_indicator" style={{ position: "absolute", bottom: "0%", width: "100%"}}>
                                {images.map( (image: any) => {
                                    return   <>
                                        {images[currentImage] == image ? (
                                            <RadioButtonCheckedOutlinedIcon sx={{ 
                                                color: "rgba(255,255,255, 0.5)", 
                                                fontSize: "2.2rem", 
                                                cursor: "pointer", 
                                                marginLeft: "0.2rem"}}/>
                                        ): (
                                            <LensOutlinedIcon sx={{ 
                                                color: "rgba(255,255,255, 0.5)", 
                                                fontSize: "2.2rem",
                                                cursor: "pointer", 
                                                marginLeft: "0.2rem"}}/>
                                        )}
                                    </>     
                                })}
                            </div>
                        </div>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default Carousel;
 

