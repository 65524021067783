import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import styles from "./landing.module.css";
import "./landing.css";
import { Header } from "./header/Header";
import { About } from "./about/About";
import { Feature } from "./features/Feature";
import FAQ from "./FAQ/FAQ";
import { GDPR } from "./GDPR/GDPR";
import { Subscribe } from "./subscribe/Subscribe";
import { Stories } from "../../components/landing/stories/Stories";

import { Test } from "./subscribe/Test";
import { Footer } from "../landing/footer/Footer";
import { LandingSeparator } from "./Separators/LandingSeparator";
import { LandingSeparatorHorizontal } from "./Separators/LandingSeparatorHorizontal";
import { Reveal }from "../common/Reveal";
import InfoPopUp from "../common/home/InfoPopUp";

interface LandingProps {
}

export const LandingV2: React.FC<LandingProps> = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Landing"
  });

  const [showInfoPopUp, setShowInfoPopUp] = useState(false);
  let [moduleTitle, setModuleTitle] = useState('');

  const handleInfoPopUp = function(pageName: string) {
    setModuleTitle(pageName);
    setShowInfoPopUp(true);
  }

  return (
    <div style={{ scrollBehavior: "smooth"}} className={styles['landing-container']}>
      <InfoPopUp showInfoPopUp={showInfoPopUp} setShowInfoPopUp={setShowInfoPopUp} module={moduleTitle}/>

      <div >
        <Header />
      </div>
      <main>

        <Reveal once={false} positionOffset={530}>
          <Feature setSelectedModule={handleInfoPopUp}/>
        </Reveal>
          
        <div className="separator-container">
          <LandingSeparator />
        </div>

        <Reveal height={"max-content"} once={false} positionOffset={550}>
          <div className="stories_cont">
            <Stories />
          </div>
          
        </Reveal> 

        <div className="separator-container-horizontal">
          <LandingSeparatorHorizontal />
        </div>

        <Reveal height="max-content" once={true} positionOffset={530} >
          <div style={{ overflow: "hiddent" }}>
            <div style={{ marginTop: "8vw" }}>
              <Subscribe />
            </div>
          
            <div style={{ marginTop: "8vw" }}>
              <FAQ />
            </div>
          </div>
        </Reveal>
        
          
        <div style={{ marginTop: "6vw" }}>
          <Footer />
        </div>
        
      </main>
    </div>
  );
};
