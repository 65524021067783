const themes = {
    main: {
        title: "#55c57a",
        button_main: "#55c57a",
        button_secondary: "#55c57a",
        filter_label: "rgb(82, 175, 119)",
        separator: {
            start: "rgba(126, 213, 111, 0.2)",
            end: "rgba(40, 180, 133, 0.7)"
        },
        paggination: "#7ed56f"
    },

    partner: {
        title: "#F1756D",
        button_main: "#F1756D",
        button_secondary: "#EF5E54",
        filter_label: "#ED473C",
        separator: {
            start: "#F1756D",
            end: "#FAD1CE"
        },
        paggination: "#F6A39D"
    },
    adoption: {
        title: "",
        button_main: "",
        button_secondary: "",
        filter_label: "",
        separator: {
            start: "",
            end: ""
        },
        paggination: ""
    }
}

export default themes;